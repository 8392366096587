import { useTranslation } from 'react-i18next'
import { NFTResumeCard } from '../../components'

import { ReactComponent as WalletIcon } from '../../assets/icons/wallet-3.svg'
import { ReactComponent as SignIcon } from '../../assets/icons/money-sign.svg'
import { CurrencyProps } from '../../store/useCart'

type CheckoutResumeProps = {
  nftTotal: number
  currency: CurrencyProps
  totalValue: number
}

export function CheckoutResume({
  nftTotal = 0,
  currency,
  totalValue = 0
}: CheckoutResumeProps) {
  const { t } = useTranslation()

  return (
    <>
      <NFTResumeCard
        className="bg-[#262726]"
        contentProps={{
          className: 'flex items-center justify-between w-full'
        }}
        icon={<WalletIcon />}
        title={t('catalog.nft-card-total-quantity')}
        description={`${nftTotal}`}
      />
      <NFTResumeCard
        className="bg-[#262726]"
        contentProps={{
          className: 'flex items-center justify-between w-full'
        }}
        icon={<SignIcon fill="#09FF3B" />}
        title={t('catalog.nft-card-total-value')}
        description={
          currency
            ? `${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency
              }).format(totalValue)}`
            : `${totalValue}`
        }
      />
    </>
  )
}
