import { User as UserIcon } from 'iconsax-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import {  toast } from 'react-toastify'
// import { ToastContainer, toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { Form, FormSubmit } from '../../components'
import { ROUTES_PATH } from '../../routes'
import { VerifyPhoneFormProps, verifyPhoneFormSchema } from './types'
import 'react-toastify/dist/ReactToastify.css'
import { api } from '../../services/api'
import { useAuth } from '../../store'
import { twMerge } from 'tailwind-merge'
import VerificationCodeInput from './verification-code-input'
import { SignInDiv } from '../../components/SignInDiv'

export function VerifyPhone() {
  const { t } = useTranslation()
  const { setToken } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [SMSTemporizer, SetSMSTemporizer] = useState(0)
  const navigate = useNavigate()
  const verifyPhoneForm = useForm<VerifyPhoneFormProps>({
    resolver: zodResolver(verifyPhoneFormSchema),
    defaultValues: {
      code: ''
    }
  })
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = verifyPhoneForm

  useEffect(() => {
    const token = searchParams?.get('token') as string
    if (token) {
      setToken(token)
      searchParams.delete('token')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, setToken])

  useEffect(() => {
    if (SMSTemporizer > 0) {
      const intervalId = setInterval(() => {
        SetSMSTemporizer(prevState => (prevState <= 1 ? 0 : prevState - 1))
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [SMSTemporizer])

  const onSubmit = useCallback(
    async (data: VerifyPhoneFormProps) => {
      const { code } = data

      try {
        const response = await api.post<{ 'access-token': string }>(
          'accounts/validate-phone',
          {
            phoneKeyValidator: code
          }
        )
        SetSMSTemporizer(2 * 60) // 2:00 minutes
        if (response.data) {
          setToken(response.data['access-token'])
          navigate(ROUTES_PATH.HOME.path)
          toast.success(t('verify-phone.success-message'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      } catch (error) {
        SetSMSTemporizer(0)
        toast.error(t('verify-phone.error-message'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: 'dark'
        })
        // console.log({ error })
      }
    },
    [navigate, setToken, t]
  )

  const onResendCode = useCallback(async () => {
    if (!!SMSTemporizer) return
    try {
      const response = await api.post('accounts/resend-phone-code')
      SetSMSTemporizer(2 * 60) // 2:00 minutes
      if (response.data) {
        toast.success(t('verify-phone.resend-code-success-message'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: 'dark'
        })
      }
    } catch (error) {
      SetSMSTemporizer(0)
      toast.error(t('verify-phone.resend-code-error-message'), {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: 'dark'
      })
      // console.log({ error })
    }
  }, [SMSTemporizer, t])

  return (
    <>
      <SignInDiv id={ROUTES_PATH.VERIFY_PHONE.path}>
        <h1 className="text-3xl">{t('verify-phone.title')}</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...verifyPhoneForm}>
            <VerificationCodeInput
              onChange={code =>
                setValue('code', code, { shouldValidate: true })
              }
            />

            <FormSubmit isSubmitting={isSubmitting} className="h-12 text-base">
              <span className="font-medium text-base">
                {t('verify-phone.form.submit-btn')}
              </span>
              <UserIcon />
            </FormSubmit>
          </FormProvider>
        </Form>
        <button
          disabled={!!SMSTemporizer}
          type="button"
          className={twMerge(
            'border border-amz3green-150 text-amz3green-150 bg-amz3black-500 mt-4 rounded-2lg w-full h-12 flex justify-center items-center gap-4 font-medium text-base hover:bg-amz3black-200 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in',
            !!SMSTemporizer && 'border-gray-400 text-gray-400 hover:bg-gray-40'
          )}
          onClick={onResendCode}
        >
          {SMSTemporizer
            ? `${Math.floor(SMSTemporizer / 60)}:${(
                SMSTemporizer -
                Math.floor(SMSTemporizer / 60) * 60
              )
                .toString()
                .padStart(2, '0')}`
            : t('verify-phone.try-again-btn')}
        </button>
      </SignInDiv>
      {/* <ToastContainer /> */}
    </>
  )
}
