import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormAutocomplete,
  FormInput,
  FormSubmit
} from '../../../../components'
import {
  ChangeProfileFormProps,
  changeProfileFormSchema,
  ChangeProfileErrorResponse,
  MessageTypeProps,
  TChangeUserBody
} from './types'
import { CountryDetailProps } from '../../../CompleteSignUp/SecondStep/second-step'
import { api } from '../../../../services/api'
import { searchZIPCode } from '../../../../utils/searchZIPCode'
import { maskCEP, maskCPF } from '../../../../utils/masks'
import { useAvatar, useProfile } from '../../../../services/hooks/useProfile'
import { AvatarProfile } from './avatar-profile'
import { twMerge } from 'tailwind-merge'

export function ChangeProfile() {
  const { i18n, t } = useTranslation()
  const { data: profile } = useProfile()
  const { data: avatar } = useAvatar()
  const [selectedCountry, setSelectedCountry] = useState<CountryDetailProps>(
    {} as CountryDetailProps
  )
  const signInForm = useForm<ChangeProfileFormProps>({
    resolver: zodResolver(changeProfileFormSchema),
    defaultValues: {
      name: profile?.name || '',
      email: profile?.email || '',
      country: profile?.address?.countryCode || '',
      zipCode: profile?.address?.zipCode || '',
      'first-address': profile?.address?.street || '',
      number: profile?.address?.number || '',
      'second-address': '',
      complement: profile?.address?.complement || '',
      city: profile?.address?.city || '',
      state: profile?.address?.state || '',
      cpf: profile?.cpf || '',
      phone: profile?.phoneNumber || '',
      instagram: '',
      facebook: '',
      discord: '',
      linkedIn: ''
    }
  })
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting }
  } = signInForm

  const handleSelectCountry = useCallback(
    async (countryCode: string) => {
      if (!selectedCountry?.cca3) {
        const { data } = await api.get<CountryDetailProps>(
          `accounts/countries-details/${countryCode}`
        )

        if (data?.cca3) {
          setSelectedCountry(data)
          setValue('country', data[i18n.language as keyof MessageTypeProps])
        }
      }
    },
    [i18n.language, selectedCountry?.cca3, setValue]
  )

  useEffect(() => {
    // console.log(profile?.address)
    if (profile?.address?.countryCode) {
      handleSelectCountry(profile?.address?.countryCode)
    }
  }, [handleSelectCountry, profile?.address])

  async function handleZipCode(zipCode: string) {
    const response = await searchZIPCode(zipCode)
    if (response) {
      // console.log(response)
      setValue(
        'first-address',
        response?.address_components?.find(el => el.types.includes('route'))
          ?.long_name || ''
      )
      setValue(
        'second-address',
        response?.address_components?.find(el =>
          el.types.includes('sublocality')
        )?.long_name || ''
      )
      setValue(
        'city',
        response?.address_components?.find(
          el =>
            el.types.includes('administrative_area_level_2') ||
            el.types.includes('locality')
        )?.long_name || ''
      )
      setValue(
        'state',
        response?.address_components?.find(el =>
          el.types.includes('administrative_area_level_1')
        )?.long_name || ''
      )
    } else {
      setValue('first-address', '')
      setValue('second-address', '')
      setValue('city', '')
      setValue('state', '')
    }
  }

  function handleCPFMask(cpf: string) {
    const maskedCPF = maskCPF(cpf)
    setValue('cpf', maskedCPF)
  }

  function handleCEPMask(cep: string) {
    if (selectedCountry.cca3 === 'BRA') {
      setValue('zipCode', maskCEP(cep))
    } else {
      setValue('zipCode', cep)
    }
  }

  const onSubmit = useCallback(
    async (data: ChangeProfileFormProps) => {
      try {
        const {
          city,
          email,
          name,
          number,
          phone,
          state,
          zipCode,
          complement,
          cpf
        } = data
        const payload: TChangeUserBody = {
          id: profile?.id || '',
          email,
          name,
          phoneDdi: selectedCountry.ddi,
          phoneNumber: phone,
          cpf,
          address: {
            street: data['first-address'],
            city,
            countryCode: selectedCountry.cca3,
            number,
            state,
            zipCode,
            complement,
            neighborhood: data['second-address']
          }
        }
        console.log(payload)
        const response = await api.put(
          '/accounts/update-user',
          payload
        )


        // console.log(response.data)
        if (response.status<400) {
          toast.success(t('dashboard.change-profile.form.request-success'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      } catch (error) {
        if (isAxiosError<ChangeProfileErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.messages

          if (errorMessage) {
            errorMessage.forEach(message => {
              // console.log(message)
              toast.error(
                message?.Message[i18n.language as keyof MessageTypeProps] ||
                  t('dashboard.change-profile.form.request-error'),
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
            })
          }
        }
        // console.log({ error })
      }
    },
    [i18n.language, profile?.id, selectedCountry.cca3, selectedCountry.ddi, t]
  )

  return (
    <>
      <ToastContainer />
      <div className="dashboard-section flex flex-col-reverse md:flex-row gap-4 items-start justify-between text-zinc-50">
        <h1 className="text-3xl font-semibold self-center md:self-start">
          {t('dashboard.change-profile.title')}
        </h1>
        <div className='self-center'> 
          <AvatarProfile
            initialImage={avatar || ''}
            imageName={profile?.name || ''}
          />
        </div>
       
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} className='dashboard-section'>
        <FormProvider {...signInForm}>
          <div className={twMerge(
              "grid grid-cols-1 lg:grid-cols-2 gap-4 ",
              "[&_label]:text-amz3green-50 [&_label]:font-medium [&_label]:text-xs",
            )}>
            <div className="flex flex-col gap-2">
              <label htmlFor="name">{t('dashboard.change-profile.form.name.placeholder')}</label>
              <FormInput
                inputName="name"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('dashboard.change-profile.form.name.placeholder')}
                errorMessage={errors.name?.message}
                {...register('name')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="email">{t('dashboard.change-profile.form.email.placeholder')}</label>
              <FormInput
                inputName="email"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('dashboard.change-profile.form.email.placeholder')}
                errorMessage={errors.email?.message}
                {...register('email')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="country">{t('sign-up.form.country')}</label>
              <FormAutocomplete
                inputName="country"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('sign-up.form.country')}
                errorMessage={errors.country?.message}
                onSelectCountry={handleSelectCountry}
                disabled={!!selectedCountry?.cca3}
                {...register('country')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="zipCode">{t('dashboard.change-profile.form.zipCode.placeholder')}</label> 
              <FormInput
                inputName="zipCode"
                className="h-12 text-base placeholder:text-base"
                placeholder={t(
                  'dashboard.change-profile.form.zipCode.placeholder'
                )}
                errorMessage={errors.zipCode?.message}
                {...register('zipCode')}
                onBlur={event => handleZipCode(event.target.value)}
                onChange={event => handleCEPMask(event.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="first-address">{t('dashboard.change-profile.form.first-address.placeholder')}</label> 
              <FormInput
                inputName="first-address"
                className="h-12 text-base placeholder:text-base"
                placeholder={t(
                  'dashboard.change-profile.form.first-address.placeholder'
                )}
                errorMessage={errors['first-address']?.message}
                {...register('first-address')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="number">{t('dashboard.change-profile.form.number.placeholder')}</label> 
              <FormInput
                inputName="number"
                className="h-12 text-base placeholder:text-base"
                placeholder={t(
                  'dashboard.change-profile.form.number.placeholder'
                )}
                errorMessage={errors.number?.message}
                {...register('number')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="second-address">{t('dashboard.change-profile.form.second-address.placeholder')}</label> 
              <FormInput
                inputName="second-address"
                className="h-12 text-base placeholder:text-base"
                placeholder={t(
                  'dashboard.change-profile.form.second-address.placeholder'
                )}
                errorMessage={errors['second-address']?.message}
                {...register('second-address')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="complement">{t('dashboard.change-profile.form.complement.placeholder')}</label> 
              <FormInput
                inputName="complement"
                className="h-12 text-base placeholder:text-base"
                placeholder={t(
                  'dashboard.change-profile.form.complement.placeholder'
                )}
                errorMessage={errors.complement?.message}
                {...register('complement')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="city">{t('dashboard.change-profile.form.city.placeholder')}</label> 
              <FormInput
                inputName="city"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('dashboard.change-profile.form.city.placeholder')}
                errorMessage={errors.city?.message}
                {...register('city')}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="state">{t('dashboard.change-profile.form.state.placeholder')}</label> 
              <FormInput
                inputName="state"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('dashboard.change-profile.form.state.placeholder')}
                errorMessage={errors.state?.message}
                {...register('state')}
              />
            </div>
            {selectedCountry?.cca3 === 'BRA' && (
              <div className="flex flex-col gap-2">
                <label htmlFor="cpf">{t('dashboard.change-profile.form.cpf.placeholder')}</label> 
                <FormInput
                  inputName="cpf"
                  className="h-12 text-base placeholder:text-base"
                  placeholder={t('dashboard.change-profile.form.cpf.placeholder')}
                  errorMessage={errors.cpf?.message}
                  {...register('cpf')}
                  onChange={event => handleCPFMask(event.target.value)}
                />
              </div>
            )}
            <div className="flex flex-col gap-2">
              <label htmlFor="phone">{t('dashboard.change-profile.form.phone.placeholder')}</label> 
              <FormInput
                inputName="phone"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('dashboard.change-profile.form.phone.placeholder')}
                errorMessage={errors.phone?.message}
                {...register('phone')}
              />
            </div>
            {false && (
              <>
                <FormInput
                  inputName="instagram"
                  className="h-12 text-base placeholder:text-base"
                  placeholder={t(
                    'dashboard.change-profile.form.instagram.placeholder'
                  )}
                  errorMessage={errors.instagram?.message}
                  {...register('instagram')}
                />
                <FormInput
                  inputName="facebook"
                  className="h-12 text-base placeholder:text-base"
                  placeholder={t(
                    'dashboard.change-profile.form.facebook.placeholder'
                  )}
                  errorMessage={errors.facebook?.message}
                  {...register('facebook')}
                />
                <FormInput
                  inputName="discord"
                  className="h-12 text-base placeholder:text-base"
                  placeholder={t(
                    'dashboard.change-profile.form.discord.placeholder'
                  )}
                  errorMessage={errors.discord?.message}
                  {...register('discord')}
                />
                <FormInput
                  inputName="linkedIn"
                  className="h-12 text-base placeholder:text-base"
                  placeholder={t(
                    'dashboard.change-profile.form.linkedIn.placeholder'
                  )}
                  errorMessage={errors.linkedIn?.message}
                  {...register('linkedIn')}
                />
              </>
            )}
          </div>
          <FormSubmit
            isSubmitting={isSubmitting}
            className="h-12 text-base lg:ml-auto lg:w-32"
          >
            <span className="text-base font-medium">
              {t('dashboard.change-profile.form.submit-btn')}
            </span>
          </FormSubmit>
        </FormProvider>
      </Form>
    </>
  )
}
