import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { twMerge } from 'tailwind-merge'

type ProfileMenuItemProps = {} & DropdownMenu.MenuItemProps

export function ProfileMenuItem({
  children,
  className,
  ...props
}: ProfileMenuItemProps) {
  return (
    <DropdownMenu.Item
      className={twMerge(
        'text-sm font-semibold text-neutral-200 rounded-md flex items-center h-7 py-0 px-2 select-none outline-none cursor-pointer data-[disabled]:text-neutral-400 data-[disabled]:cursor-not-allowed data-[highlighted]:text-neutral-50 data-[highlighted]:bg-zinc-500',
        className
      )}
      {...props}
    >
      {children}
    </DropdownMenu.Item>
  )
}
