import {
  Calendar2 as Calendar2Icon,
  Ruler as RulerIcon,
} from 'iconsax-react'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CertificateNFTLoading } from './certificate-nft-loading'
import { GetNFTOwnerResponseProps } from '../../../../services/hooks/useNFTOwner'
import { CurrencyProps } from '../../../../store/useCart'
import { useEffect, useState } from 'react'
import { api } from '../../../../services/api'
import { RescueButton } from '../../components/RescueButton'
import { MapNFT } from '../../../../models/map-nft'
import { DateConverter } from '../../../../utils/date-converter';

type CertificateNFTProps = {
  nft: MapNFT
  currency: CurrencyProps
  price: number
  className?: string
  isLoading?: boolean
  onClick?: () => void
}

export function CertificateNFT({
  nft,
  className,
  isLoading,
  onClick
}: CertificateNFTProps) {
  const { t } = useTranslation()
  const [nftOwner, setNftOwner] = useState<GetNFTOwnerResponseProps | null>(
    null
  )
  const navigate = useNavigate()

  useEffect(() => {
    let ignore = false

    if (!ignore) {
      const getNFTOwner = async () => {
        if (nft?.projectAreaId) {
          try {
            const { data: nftOwner } = await api.get<GetNFTOwnerResponseProps>(
              `/nfts/nft-owner/${nft?.projectAreaId}`
            )
            setNftOwner(nftOwner)
          } catch (error) {
            setNftOwner(null)
          }
        }
      }

      getNFTOwner()
    }

    return () => {
      ignore = true
    }
  }, [nft?.projectAreaId])

  if (isLoading) {
    return <CertificateNFTLoading />
  }

  return (
    <div
      className={twMerge(
        'rounded-2xl text-white p-4 relative cursor-pointer',
        'border border-amz3green-300 bg-amz3green-200 bg-opacity-[.05]',
        'hover:scale-[102%] hover:bg-opacity-10 hover:border-amz3green-200 transition-all',
        className
      )}
      onClick={() => (onClick ? onClick() : navigate(nft.id))}
    >
      <div className="rounded-2xl block overflow-hidden relative">
        <img
          src={nft?.artisticImage || nft?.image}
          className="rounded-2xl"
          alt="nft-logo"
        />
      </div>
      <div className="flex flex-col gap-6 mt-4">
        
        <div className="flex flex-wrap gap-2 justify-between">
          
          {nft.soldDate && (

            <div className="flex items-center gap-1 bg-zinc-700 rounded-full py-1 px-2 text-xs font-medium">
              <Calendar2Icon size={14} />
              <span>{t('dashboard.my-certificates.nft.minted')}: {DateConverter.ConvertSystemDateFormatToBRLDate(nft.soldDate)}</span>
            </div>
          )}
          {nft.expireDate && (
            <div className="flex items-center gap-1 bg-zinc-700 rounded-full py-1 px-2 text-xs font-medium">
              <Calendar2Icon size={14} />
              <span>{t('dashboard.my-certificates.nft.expiration')}: {DateConverter.ConvertSystemDateFormatToBRLDate(nft.expireDate)}</span>
            </div>
          )}

          <div className='w-full flex justify-between'>
            <div className="flex items-center gap-1 bg-[rgba(0,0,0,.2)] rounded-full py-1 px-2 text-xs font-medium">
              <Calendar2Icon size={14} />
              <span>{t('dashboard.my-certificates.nft.edition')}: {nft?.edition}</span>
            </div>
            <div className="flex items-center gap-1 bg-[rgba(0,0,0,.2)] rounded-full py-1 px-2 text-xs font-medium">
              <RulerIcon size={14} />
              <span>{`${nft?.areaInSquareMeters} m²`}</span>
            </div>
          </div>
        </div>
        {!nft.transferredToUserWallet && (
          <div className="w-full" onClick={e => e.stopPropagation()}>
             <RescueButton transferredToUserWallet={nft.transferredToUserWallet ?? false} nftToken={nftOwner?.nftToken || ''} />
          </div>
        )}
      </div>
    </div>
  )
}
