import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { enTranslations, ptTranslations } from '../locale'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      ...enTranslations
    },
    pt: {
      ...ptTranslations
    }
  },
  lng: 'pt',
  fallbackLng: 'pt'
})

export default i18n
