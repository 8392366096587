import * as zod from 'zod'
import i18n from '../../i18n'

export let contactFormSchema: zod.ZodObject<
  {
    name: zod.ZodString
    email: zod.ZodString
    subject: zod.ZodString
    message: zod.ZodString
  },
  'strip',
  zod.ZodTypeAny,
  { name: string; email: string; subject: string; message: string },
  { name: string; email: string; subject: string; message: string }
>

i18n.on('languageChanged', language => {
  contactFormSchema = zod.object({
    name: zod
      .string()
      .min(
        3,
        i18n?.t('contact-us.form.error-messages.name', { lng: language })
      ),
    email: zod
      .string()
      .email(
        i18n?.t('contact-us.form.error-messages.email', { lng: language })
      ),
    subject: zod
      .string()
      .min(
        3,
        i18n?.t('contact-us.form.error-messages.subject', { lng: language })
      ),
    message: zod
      .string()
      .min(
        5,
        i18n?.t('contact-us.form.error-messages.message', { lng: language })
      )
  })
})

export type ContactFormProps = zod.infer<typeof contactFormSchema>
