export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

export const maskCEP = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

export const maskDate = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

export const maskOnlyLetters = (value: string) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '')
}

export const maskOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '')
}

export function regexToInputMask(regex: string) {
  let mask = ''

  const replacements = [
    {
      pattern: /\\d\{(\d+)\}/g,
      replace: (match: any, p1: number) => '9'.repeat(p1)
    },
    { pattern: /\\d/g, replace: '9' },
    { pattern: /\(/g, replace: '' },
    { pattern: /\)/g, replace: '' },
    { pattern: /\$/g, replace: '' },
    { pattern: /\^/g, replace: '' }
  ]

  replacements.forEach(rep => {
    regex = regex.replace(rep.pattern, rep.replace as any)
  })

  mask = regex

  return mask
}
