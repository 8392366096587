import { Link } from 'react-router-dom'
import { ArrowRotateRight } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { ROUTES_PATH } from '../../routes'

import { ReactComponent as FailClose } from '../../assets/icons/fail-close.svg'

export function Fail() {
  const { t } = useTranslation()

  return (
    <div
      id={ROUTES_PATH.CHECKOUT_FAIL.id}
      className="min-h-[68.31vh] px-8 max-w-[540px] mx-auto pt-12 pb-28 flex flex-col items-center"
    >
      <FailClose />
      <h1 className="mt-5 text-[#DC242F] font-medium text-3xl text-center">
        {t('fail.title')}
      </h1>
      <p className="mt-8 text-amz3white-50 text-xl text-center">
        {t('fail.description')}
      </p>
      <Link
        to={ROUTES_PATH.CART.path}
        className="mt-24  bg-amz3green-150 flex items-center justify-center gap-4 h-[72px] w-[280px] rounded-2lg text-[#1B1B1B] font-semibold text-xl border border-amz3green-150  hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
      >
        <span>{t('fail.retry-btn')}</span>
        <ArrowRotateRight />
      </Link>
    </div>
  )
}
