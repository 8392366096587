import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import Figure from '../../../assets/v2/figure-amazon-1.jpg'
import { revealSlideUp, revealFade } from '../../../motion'
import { useTranslation } from 'react-i18next';

export function SectionFuture () {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const isInView1 = useInView(ref1, { once: true, amount: .3 });
  const isInView2 = useInView(ref2, { once: true, amount: .3 });
  const { t } = useTranslation()

  return (
    <section
      className={cx(
        'lg:min-h-[80dvh] grid grid-cols-1 md:grid-cols-2',
        'bg-black text-amz3earth-light'
      )}
      style={revealFade(isInView1,3)}
    >
        <div ref={ref1} className='opacity-50'
            style={{
              background: `url(${Figure}) center center no-repeat fixed`,
              backgroundSize: "cover",
            }}
          >
            <div className='w-full h-full bg-black/30'></div>
          </div>
        <div ref={ref2} className='p-10 py-20 md:p-20 bg-amz3earth-medium text-amz3black-300'>
          <h3 className='text-4xl text-white mb-8 font-semibold [&>span]:block'>
            <span style={revealSlideUp(isInView2, 1, 0.5)}>{t('home.future.title1')}</span>
            <span style={revealSlideUp(isInView2, 1, 1)}>{t('home.future.title2')}</span>
          </h3>
          <div style={revealFade(isInView2, 4, 2.0)} className='[&>p]:mb-4'>
            <p>{t('home.future.first-paragraph')}</p>
            <p>{t('home.future.second-paragraph')}</p>
            <p>{t('home.future.third-paragraph')}</p>
            <p>{t('home.future.fourth-paragraph')}</p>
          </div>
        </div>
      </section>
  )
}