import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import {
  Cart,
  Catalog,
  Marketplace,
  Success,
  Fail,
  SignIn,
  SignUp,
  Notification,
  ChangePassword,
  ResetPassword,
  VerifyPhone,
  Dashboard,
  AboutToken,
  AboutCertificate,
  About
} from '../pages'
import { ROUTES_PATH } from './utils'
import { scrollTo } from '../utils'
import MainLayout from '../MainLayout'
import { CompleteSignUp } from '../pages/CompleteSignUp'


export function Router() {
  const location = useLocation()

  useEffect(() => {

    const keys = Object.keys(ROUTES_PATH) as (keyof typeof ROUTES_PATH)[]
    const key = keys.find(key => location.hash && ROUTES_PATH[key].path.includes(location.hash))
    if (key) {
      scrollTo(ROUTES_PATH[key].id)
    } else {
      scrollTo(0, "instant")
    }

    
    const routeClassMap = {
      [ROUTES_PATH.DASHBOARD.path]: 'dashboard-route',
    };

    const currentPath = location.pathname;
    const newClass = Object.entries(routeClassMap).find(([pathPrefix]) =>
      currentPath.startsWith(pathPrefix)
    )?.[1] || '';

    // Remove existing route classes
    Object.values(routeClassMap).forEach((cls) => {
      document.body.classList.remove(cls);
    });

    // Add the new class
    if (newClass) {
      document.body.classList.add(newClass);
    }

    return () => {
      // Clean up by removing the class on component unmount or route change
      if (newClass) {
        document.body.classList.remove(newClass);
      }
    };

  }, [location])

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path={ROUTES_PATH.HOME.path}
          element={<About />}
        />
        <Route
          path={ROUTES_PATH.TOKEN.path}
          element={<AboutToken />}
        />
        <Route
          path={ROUTES_PATH.CERTIFICATE.path}
          element={<AboutCertificate />}
        />
        <Route
          path={ROUTES_PATH.CART.path}
          element={<Cart />}
        />
        <Route
          path={ROUTES_PATH.CATALOG.path}
          element={<Catalog />}
        />
        <Route
          path={ROUTES_PATH.MARKETPLACE.path}
          element={<Marketplace />}
        />
        <Route
          path={ROUTES_PATH.CHECKOUT_SUCCESS.path}
          element={<Success />}
        />
        <Route
          path={ROUTES_PATH.CHECKOUT_FAIL.path}
          element={<Fail />}
        />
        <Route
          path={ROUTES_PATH.SIGN_IN.path}
          element={<SignIn />}
        />
        <Route
          path={ROUTES_PATH.SIGN_UP.path}
          element={<SignUp />}
        />
        <Route
          path={ROUTES_PATH.COMPLETE_SIGN_UP.path}
          element={<CompleteSignUp />}
        />
        <Route
          path={ROUTES_PATH.NOTIFICATION.path}
          element={<Notification />}
        />
        <Route
          path={ROUTES_PATH.CHANGE_PASSWORD.path}
          element={<ChangePassword />}
        />
        <Route
          path={ROUTES_PATH.RESET_PASSWORD.path}
          element={<ResetPassword />}
        />
        <Route
          path={ROUTES_PATH.VERIFY_PHONE.path}
          element={<VerifyPhone />}
        />
        <Route path={ROUTES_PATH.DASHBOARD.path} element={<Dashboard />}>
          <Route path={ROUTES_PATH.PROFILE.path} />
          <Route path={ROUTES_PATH.ORDERS.path} />
          <Route path={ROUTES_PATH.MY_CERTIFICATES.path}>
            <Route path=":id" />
          </Route>
          <Route path={ROUTES_PATH.CONSERVATION_DATA.path}>
            <Route path=":id" />
          </Route>
          <Route path={ROUTES_PATH.HELP.path} />
        </Route>
      </Route>
    </Routes>
  )
}
