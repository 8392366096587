import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useTranslation } from 'react-i18next'
import { CircleHelp, CircleX } from 'lucide-react'

export type HelpProps = {
  title: string;
  text: string;
}

export type HelpDialogProps = {
  help: HelpProps;
}

export function HelpDialog({ help }: HelpDialogProps) {
  const { t } = useTranslation()
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className="[&>svg]:stroke-[#555] [&:hover>svg]:stroke-[#777]">
          <CircleHelp size={16}/>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal className="z-[53]">
        <Dialog.Overlay className="bg-[#00000099] fixed inset-0 z-[53]" />
        <Dialog.Content className="bg-amz3green-300 fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[23px]  w-[480px] max-w-[90%] z-[53] px-8 py-6">
          <Dialog.Title className="text-amz3green-100 font-bold mb-4">
            {t(help.title)}
          </Dialog.Title>
          <div className='text-amz3green-50 text-sm'>{t(help.text)}</div>
          <Dialog.Close asChild>
            <button className='absolute top-4 right-4 [&>svg]:stroke-amz3green-100 [&:hover>svg]:stroke-amz3green-200'>
              <CircleX/>
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
