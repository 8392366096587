import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { MapNFT } from '../../../models/map-nft'
import { DateConverter } from '../../../utils/date-converter';

type NFTOwnedCardProps = {
  className?: string
  nft: MapNFT
  icon?: ReactNode
  onClick: (nft: MapNFT) => void
}

export function NFTOwnedCard({ className, nft, icon, onClick }: NFTOwnedCardProps) {
  const { t } = useTranslation()

  return (
    <button
    key={nft.id}
    className={
      twMerge(
        'relative grid grid-cols-4 lg:grid-cols-5 bg-amz3black-300 rounded-r-2xl hover:scale-105 transition-all duration-200',
        className
      )
    }
    onClick={() => onClick(nft)}
  >
    <img src={nft?.artisticImage || nft?.image} alt="nft-logo" />
    <div className="col-span-3 lg:col-span-4 flex flex-col justify-center p-6 lg:p-8">
      <p className="text-amz3green-100 text-lg lg:text-xl mb-6 lg:mb-8">
        {nft.name.split('#')[0]}
        <br />
        <span className="text-amz3earth-light">#{nft.name.split('#')[1]}</span>
      </p>
      <div className="grid grid-cols-2 text-xs lg:text-sm text-amz3white-100 font-light">
        <p>
          {t('dashboard.my-certificates.nft.minted')}
          {': '}
          {DateConverter.ConvertSystemDateFormatToBRLDate(nft.soldDate)}
        </p>
        {!!nft?.expireDate && (
          <p>
            {t('dashboard.my-certificates.nft.expiration')}
            {': '}
            {DateConverter.ConvertSystemDateFormatToBRLDate(nft.expireDate)}
          </p>
        )}
      </div>
    </div>
    {!!icon && (
      <span className="absolute top-6 right-6 text-amz3green-100">{icon}</span>
    )}
  </button>
  )
}
