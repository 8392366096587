import { DetailedHTMLProps, HTMLAttributes, useState } from 'react'
import { Animation } from './animation';
import { motion, AnimatePresence, wrap } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const arrowStyle = `
  p-2 next absolute top-[150px] bg-amz3green-50 rounded-full z-10
  scale-95 hover:scale-100 opacity-80 hover:opacity-100
  transition-all duration-300
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 200 : -200,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 200 : -200,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 1000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

type AnimatedGuideProps = {
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function AnimatedGuide({ className, ...props }: AnimatedGuideProps) {

  const { t } = useTranslation();
  
  const files = [
    "/lottie/guide/STEP01.lottie",
    "/lottie/guide/STEP02.lottie",
    "/lottie/guide/STEP03.lottie",
    "/lottie/guide/STEP04.lottie",
  ]

  const steps = ["one","two","three","four"]

  const [[page, direction], setPage] = useState([0, 0]);

  const fileIndex = wrap(0, files.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='w-full overflow-hidden relative h-[300px]'>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className='absolute w-full h-full flex justify-center items-center'
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            <Animation src={files[fileIndex]} />
          </motion.div>
        </AnimatePresence>
        <button
          className={twMerge(arrowStyle, "right-0")}
          onClick={() => paginate(1)}>
          <ChevronRight/>
        </button>
        <button
          className={twMerge(arrowStyle, "left-0")}
          onClick={() => paginate(-1)}>
          <ChevronLeft/>
        </button>
      </div>
      <div className='flex gap-1 w-full justify-center my-2 lg:my-4'>
        {
          [0,1,2,3].map((key) => (
            <button
              key={key}  
              onClick={()=>setPage([key, key-fileIndex])}
              className='p-2 group'
              >
                <span className={twMerge(
                  'w-2 h-2 rounded-full bg-white block',
                  key === fileIndex ? 'opacity-100' : 'opacity-30 group-hover:opacity-60'
                  )}/>
              </button>
          ))
        }
      </div>
      <div className='text-amz3green-50 w-full flex flex-col gap-2 justify-center items-center pb-16'>
        <div className='max-w-[300px] text-center'>
          <strong>{fileIndex+1}. </strong>
          {t(`catalog-tutorial.steps.${steps[fileIndex]}`)}
        </div>
      </div>
    </div>
  )
}
