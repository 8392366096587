import { z } from 'zod'
import i18n from '../../i18n'

export let verifyPhoneFormSchema: z.ZodObject<
  { code: z.ZodString },
  'strip',
  z.ZodTypeAny,
  { code: string },
  { code: string }
>

i18n.on('languageChanged', language => {
  verifyPhoneFormSchema = z.object({
    code: z
      .string()
      .min(1, {
        message: i18n?.t('verify-phone.form.error-messages.code', {
          lng: language
        })
      })
      .max(6, {
        message: i18n?.t('verify-phone.form.error-messages.code', {
          lng: language
        })
      })
  })
})

export type VerifyPhoneFormProps = z.infer<typeof verifyPhoneFormSchema>

export interface VerifyPhoneErrorResponse {
  message: Message[]
}

export interface Message {
  Item: string
  Message: string
}
