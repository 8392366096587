import { ContactUs } from '../ContactUs'
import { FooterPage } from '../../components'
import { SectionWelcome } from './section/welcome'
import { SectionGreentech } from './section/greentech'
import { SectionFuture } from './section/future'
import { SectionProsperity } from './section/prosperity'
import { SectionToken } from './section/token'
import { SectionMetaverse } from './section/metaverse'

export function About() {
  return (
    <>
      <SectionWelcome/>
      <SectionGreentech/>
      <SectionFuture/>
      <SectionProsperity/>
      <SectionToken/>
      <SectionMetaverse/>
      <ContactUs />
      <FooterPage />
    </>
  )
}
