import { BitcoinCard /*, Link1 */} from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import {
  NFTInfoText,
  NFTStepGuide,
  NFTCardGroup,
  AM3Logo
} from '../../components'
import { NFTInfoCard } from './components/NFTInfoCard'

import TripleTreeCard from '../../assets/images/triple-tree-card.png'
import MktPlaceBackground from '../../assets/images/mktplace-background.png'
import NFTMktPlace from '../../assets/images/nft-mktpalce.png'
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes/utils'
import { ContactUs } from '../ContactUs'

export function Marketplace() {
  const { t } = useTranslation()

  return (
    <>
      <>
        <section
          className="bg-[#1a1a19] py-[52px]"
          id={ROUTES_PATH.MARKETPLACE.id}
        >
          <div
            className="px-12 md:px-64 md:flex"
            style={{
              backgroundImage: `url(${MktPlaceBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="flex flex-col items-center md:w-1/2 md:items-start">
              <h1 className="text-4xl font-semibold text-amz3green-150 uppercase text-center">
                {t('marketplace.title')}
              </h1>
              <h2 className="text-amz3green-50 font-semibold text-center uppercase mt-3 text-7xl md:max-w-2xl md:text-start">
                {t('marketplace.subtitle')}
              </h2>
              <p className="text-amz3white-50 mt-9 text-lg md:max-w-2xl md:text-start">
                {t('marketplace.text')}
              </p>
            </div>
            <div className="flex items-center justify-center md:w-1/2">
              <img src={TripleTreeCard} alt="" className="-scale-x-100 mt-16" />
            </div>
          </div>
          <div className="mt-14 px-6 flex flex-col items-center md:flex-row md:px-64 md:gap-12 md:items-start">
            <div className="bg-[#141514] rounded-2xl px-7 py-8">
              <img
                src={NFTMktPlace}
                alt=""
                className="h-80 w-80 md:h-96 md:w-96"
              />
              <h1 className="mt-8 text-center font-bold text-3xl text-amz3green-50">
                NFT AMZ3 UCF
              </h1>
              <div className="flex items-center justify-center gap-4 mt-3">
                <AM3Logo className="h-10 w-10" />
                <span className="text-amz3green-100 font-medium text-2xl">
                  PROJETO SUMAÚMA GENESIS
                </span>
              </div>
              <div className="mt-6 text-amz3green-50 text-center">
                Proprietária: Amazon Tree
              </div>
            </div>
            <div className="flex flex-col items-center md:w-1/2 md:items-start">
              <div className="mt-6 flex flex-col items-center w-full gap-4 md:items-start md:mt-0">
                <NFTInfoCard className="font-bold">
                  {t('marketplace.first-info-card')}
                </NFTInfoCard>
                <NFTInfoCard>{t('marketplace.second-info-card')}</NFTInfoCard>
                <NFTInfoCard>{t('marketplace.third-info-card')}</NFTInfoCard>
                <NFTInfoCard>{t('marketplace.fourth-info-card')}</NFTInfoCard>
              </div>
              <p className="text-amz3white-50 mt-11 px-4 md:max-w-3xl md:px-0">
                {t('marketplace.nft-info-text')}
              </p>
              <div className="mt-11 flex gap-4">
                <Link
                  to={ROUTES_PATH.HOW_TO_BUY.path}
                  className="bg-amz3green-150 h-14 w-80 flex items-center justify-center rounded-lg text-[#1B1B1B] font-medium gap-4 hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
                >
                  {t('marketplace.btn-how-buy')}
                  <BitcoinCard />
                </Link>
                {/* <Link
                  to="https://amazon-tree-r.gitbook.io/white-paper-amz3/"
                  target="_blank"
                  className="text-amz3green-150 border border-amz3green-150 bg-[##1A1A19] h-14 w-80 flex items-center justify-center rounded-lg font-medium gap-4 hover:opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
                >
                  {t('marketplace.btn-more-info')}
                  <Link1 />
                </Link> */}
              </div>
            </div>
          </div>
          <NFTCardGroup />
          <div className="mt-14 flex flex-col gap-12 md:flex-row md:items-center md:justify-center">
            <NFTInfoText
              text="2024"
              subtext={t('marketplace.first-info-text')}
            />
            <NFTInfoText
              text="300+"
              subtext={t('marketplace.second-info-text')}
            />
            <NFTInfoText
              text="90 mi+"
              subtext={t('marketplace.third-info-text')}
            />
            <NFTInfoText
              text="30 mi+"
              subtext={t('marketplace.fourth-info-text')}
            />
          </div>
        </section>
        <section
          className="bg-amz3black-50 py-12"
          id={ROUTES_PATH.HOW_TO_BUY.id}
        >
          <div className="bg-amz3black-400 py-12 p-11 rounded-xl mx-8 mb-12 md:mx-64">
            <h1 className="font-semibold text-[#F0F0F0] text-xl uppercase text-center">
              {t('marketplace.payment-info-title')}
            </h1>
            <p className="mt-6 text-amz3green-50">
              {t('marketplace.payment-info-description')}
            </p>
          </div>
          <h1 className="text-amz3white-50 text-center font-semibold text-3xl uppercase mx-16">
            {t('marketplace.how-to-pay-title')}
          </h1>
          <div className="mt-20 ml-8 flex gap-16 overflow-x-auto md:justify-center">
            <NFTStepGuide
              title={t('marketplace.how-to-pay-first-step.title')}
              description={t('marketplace.how-to-pay-first-step.description')}
              hasNextStep
            />
            <NFTStepGuide
              title={t('marketplace.how-to-pay-second-step.title')}
              description={t('marketplace.how-to-pay-second-step.description')}
              subDescription={t(
                'marketplace.how-to-pay-second-step.subDescription'
              )}
              hasNextStep
            />
            <NFTStepGuide
              title={t('marketplace.how-to-pay-third-step.title')}
              description={t('marketplace.how-to-pay-third-step.description')}
              subDescription={t(
                'marketplace.how-to-pay-third-step.subDescription'
              )}
              hasNextStep
            />
            <NFTStepGuide
              title={t('marketplace.how-to-pay-fourth-step.title')}
              description={t('marketplace.how-to-pay-fourth-step.description')}
              subDescription={t(
                'marketplace.how-to-pay-fourth-step.subDescription'
              )}
            />
          </div>
        </section>
        <section className="bg-[#1a1a19] pt-20 pb-28 pl-8 md:pb-8">
          <NFTCardGroup />
          <NFTCardGroup />
        </section>
      </>
      <ContactUs />
    </>
  )
}
