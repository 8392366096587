import { z } from 'zod'
import i18n from '../../i18n'
import { NotificationProps } from '../Notification/types'

export let signInFormSchema: z.ZodObject<
  { email: z.ZodString; password: z.ZodString },
  'strip',
  z.ZodTypeAny,
  { email: string; password: string },
  { email: string; password: string }
>

i18n.on('languageChanged', language => {
  signInFormSchema = z.object({
    email: z.string().email({
      message: i18n?.t('sign-in.form.error-messages.email', { lng: language })
    }),
    password: z.string().min(2, {
      message: i18n?.t('sign-in.form.error-messages.password', {
        lng: language
      })
    })
  })
})

export type SignInFormProps = z.infer<typeof signInFormSchema>

export interface SignInErrorResponse {
  messages: MessageProps[]
}

export interface MessageProps {
  Item: string
  Message: MessageTypeProps
  Notification?: NotificationProps
  Complement?: string
}

export type MessageTypeProps = {
  pt: string
  en: string
}
