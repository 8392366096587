import { create } from 'zustand'

type LoadingState = {
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
}

export const loadingStore = create<LoadingState>(set => {
  return {
    isLoading: false,
    setLoading: (isLoading: boolean) =>
      set(() => ({
        isLoading
      }))
  }
})

export const useLoading = loadingStore
