import { ContactUs } from '../ContactUs'
import { FooterPage } from '../../components'
import { SectionGreenIs } from './section/green-is'
import { SectionTokenInfo } from './section/token-info'
import { SectionCollection } from './section/collection'
import { SectionPayment } from './section/payment'
import { SectionPaymentSolana } from './section/payment-solana'

export function AboutToken() {
  return (
    <>
      <SectionGreenIs/>
      <SectionTokenInfo/>
      <SectionCollection/>
      <SectionPayment/>
      <SectionPaymentSolana/>
      <ContactUs />
      <FooterPage />
    </>
  )
}
