import { useTranslation } from 'react-i18next'
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg'
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg'
import { ReactComponent as LinkedIn } from '../../assets/icons/linkedin.svg'
import { ReactComponent as Discord } from '../../assets/icons/discord.svg'
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg'
import { SocialMediaButton } from './social-media-button'
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes'
import { AM3Logo } from '../AM3Logo'

export function FooterPage() {
  const { t } = useTranslation()

  return (
    <footer
      className="bg-amz3black-600 text-amz3white-50 flex flex-col gap-10 items-center px-8 py-16 w-full"
      id="page-footer"
    >
      <div className="flex flex-col gap-5 lg:flex-row lg:justify-between w-full max-w-[1200px]">
        <Link to={ROUTES_PATH.HOME.path}>
          <AM3Logo full className="h-[55px]" />
        </Link>
        <div className="grid grid-rows-3 lg:grid-cols-3 lg:grid-rows-1 gap-5 items-start">
          <ul>
            {/*
            <li>Menu</li>
            <li>Menu</li>
            <li>Menu</li>
            */}
          </ul>
          <ul>
            {/*
            <li>Menu</li>
            <li>Menu</li>
            <li>Menu</li>
            */}
          </ul>
          <Link to={ROUTES_PATH.SIGN_IN.path}
            className='button-green-outline'
          >
              Login
          </Link>
        </div>
      </div>
      <div className='bg-amz3green-100 h-[1px] w-full max-w-[1200px]'></div>
      <div className="flex flex-col lg:flex-row lg:justify-between w-full max-w-[1200px]">
        <div className="flex flex-row gap-5">
          <SocialMediaButton to="https://facebook.com/amz3official">
            <Facebook />
          </SocialMediaButton>
          <SocialMediaButton to="https://instagram.com/amazontreebr">
            <Instagram />
          </SocialMediaButton>
          <SocialMediaButton to="https://twitter.com/amz3official">
            <Twitter />
          </SocialMediaButton>
          <SocialMediaButton to="https://linkedin.com/">
            <LinkedIn />
          </SocialMediaButton>
          <SocialMediaButton to="https://discord.com/invite/vfpHHjG2">
            <Discord className="fill-amz3white-50" />
          </SocialMediaButton>
          <SocialMediaButton to="https://t.me/AmazonTree">
            <Telegram className="fill-amz3white-50" />
          </SocialMediaButton>
        </div>
        <span className=" mt-5">{t('footer.rights')}</span>
      </div>
      
    </footer>
  )
}
