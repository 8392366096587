import { useTranslation } from 'react-i18next'
import { CheckoutForm } from './checkout-form'

export function CheckoutContent() {
  const { t } = useTranslation()
  return (
    <>
      <h1 className="uppercase text-center text-amz3green-50 font-semibold text-xl">
        {t('checkout.title')}
      </h1>
      <CheckoutForm />
    </>
  )
}
