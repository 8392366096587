import { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Map, { Source, MapLayerMouseEvent, MapRef, Popup, Layer } from 'react-map-gl'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
  useNFTsUnavailable,
  useShortFeedMaps,
  getFeedMapsByUser
} from '../../../services/hooks/useNFTs'
import { useAuth, useCartStore } from '../../../store'
import { api } from '../../../services/api'
import { MapNFT, PropertiesFeatureProps } from '../../../models/map-nft'
import { PopupMap } from '../popup-map'
import {
  CheckNFTAvailabilityErrorResponse,
  DEFAULT_LONG_LAT,
  MAP_INITIAL_STATE,
  MapboxMapsProps,
  MessageTypeProps,
  PROJECTS_AREAS_STATUS
} from './types'
import { MapboxLayers } from './mapbox-layers'
import { RasterLayer as RasterLayerProps } from 'mapbox-gl'
import { rotateImage } from '../../../utils/rotateImage'
import { fetchImageAsDataURL } from '../../../utils/fetchImageAsDataURL'
import { isAxiosError } from 'axios'
import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon, ViewfinderCircleIcon } from '@heroicons/react/20/solid'

import type { FillLayer } from 'react-map-gl';

export const dataLayer: FillLayer = {
  id: 'fill',
  type: 'fill',
  source: "fill",
  paint: {
    'fill-color': '#03ff00',
    'fill-opacity': 0.3,
  }
};

type FeatureLayer = {
  type: string;
  features: GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[]
};

export const MapboxMaps = ({
  userNFTs,
  selectedOwnedNFT,
  handleActiveTab,
  onSelectArea,
}: MapboxMapsProps) => {
  const { token } = useAuth()
  const [searchParams] = useSearchParams()

  const { data: mapUnavailableData } = useNFTsUnavailable()
  const mapsBySizeResult = useShortFeedMaps()
  const { addNFT, cartNFTs, deleteNFT, updateNFTStatus } =
    useCartStore()
  const mapRef = useRef<MapRef>(null)
  const [popupInfo, setPopupInfo] = useState<MapNFT | null>(null)
  const [userAreas, setUserAreas] = useState<(string | number)[]>([])
  const [autoselectedArea, setAutoselectedArea] = useState<boolean>(false)
  const [hoverLayer, setHoverLayer] = useState<FeatureLayer|null>(null)
  const [hoverInfo, setHoverInfo] = useState({
    latitude: DEFAULT_LONG_LAT.latitude as number,
    longitude: DEFAULT_LONG_LAT.longitude as number
  })
  const [mapFly, setMapFly] = useState<{
    latitude: number;
    longitude: number;
    zoom: number;
    duration: number;
  } | null>(null)
  const [isSponsorLoaded, setIsSponsorLoaded] = useState(false)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    const defaultMapFly = {
      latitude: DEFAULT_LONG_LAT.latitude as number,
      longitude: DEFAULT_LONG_LAT.longitude as number,
      zoom: 14,
      duration: 10000,
    }

    if (!token) {
      setMapFly(defaultMapFly)

      return
    }

    const fetchData = async () => {
      const userAreas = await getFeedMapsByUser()

      if (userAreas?.length) {
        const defaultArea = userAreas[Math.floor(Math.random() * userAreas.length)];
        const zooms = {
          TWO_EXTRA_LARGE: { duration: 11000, zoom: 16 },
          EXTRA_LARGE: { duration: 11000, zoom: 16 },
          LARGE: { duration: 11500, zoom: 17 },
          MEDIAN: { duration: 11500, zoom: 17 },
          SMALL: { duration: 12000, zoom: 18 },
          EXTRA_SMALL: { duration: 12000, zoom: 18 },
        }

        setUserAreas(userAreas?.map(({ id }) => id));
        setMapFly({
          latitude: defaultArea.area.pointCenter.latitude,
          longitude: defaultArea.area.pointCenter.longitude,
          duration: zooms[defaultArea?.nftSize].duration,
          zoom: zooms[defaultArea?.nftSize].zoom,
        });
      } else {
        setMapFly(defaultMapFly)
      }
    }

    fetchData()
  }, [token])

  const priceParam = useMemo(() => {
    const param = searchParams.get('price');

    if (!!param?.length && ['25', '100'].includes(param)) {
      return parseInt(param);
    }

    return null;
  }, [searchParams]);

  const mergedAreas = useMemo(() => {
    const areas: GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[] = []

    if (!!mapUnavailableData?.length) {
      areas.push(...mapUnavailableData)
    }

    mapsBySizeResult.forEach(sizeResult => {
      if (!!sizeResult.data?.length) {
        areas.push(...sizeResult.data)
      }
    })

    return {
      type: 'FeatureCollection',
      features: areas.map(area => {
        if (userAreas.includes(area.properties?.nft_id)) {
          return {
            ...area,
            properties: {
              ...area.properties,
              status: PROJECTS_AREAS_STATUS.OWNER
            }
          }
        }

        const selectedNFT = cartNFTs.find(
          nft => nft.id === area.properties?.nft_id
        )

        if (!!selectedNFT) {
          return {
            ...area,
            properties: {
              ...area.properties,
              status: PROJECTS_AREAS_STATUS[selectedNFT.status]
            }
          }
        }

        return area
      })
    }
  }, [cartNFTs, mapUnavailableData, mapsBySizeResult, userAreas])

  const sponsoredAreas: typeof mergedAreas = useMemo(() => {
    return {
      ...mergedAreas,
      features: mergedAreas.features.filter(
        area => area.properties.is_sponsored
      )
    }
  }, [mergedAreas])

  useEffect(() => {
    if (!priceParam || autoselectedArea) return

    const filteredAreas = mergedAreas?.features?.filter(
      (feature) => feature?.properties?.price === priceParam && feature?.properties?.is_available
    )

    if (!filteredAreas?.length) return

    setAutoselectedArea(true)

    const area = filteredAreas[Math.floor(Math.random() * filteredAreas.length)];

    const fetchData = async (nftId: number | string) => {
      try {
        const response = await api.get<MapNFT>(`nfts/check-nft-by-id/${nftId}`)

        if (!response?.data?.id) {
          toast.error('NFT não encontrado', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })

          return
        }

        if (!cartNFTs.some((cartNFT) => cartNFT?.id === response?.data?.id)) {
          addNFT(response?.data);
        }

      } catch (error) {
        if (isAxiosError<CheckNFTAvailabilityErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.messages
          if (errorMessage?.length) {
            errorMessage.forEach(message => {
              toast.error(
                message?.Message[i18n.language as keyof MessageTypeProps] ||
                  t('mapbox.check-area-error'),
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
            })
          } else {
            toast.error(t('mapbox.check-area-error'), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })

            return
          }
        } else {
          toast.error(t('mapbox.check-area-error'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })

          return
        }
      }
    }

    if (!!area?.properties?.nft_id) {
      fetchData(area.properties.nft_id)
    }
  }, [addNFT, autoselectedArea, cartNFTs, i18n.language, mergedAreas?.features, priceParam, t]);

  const onMapClick = useCallback(
    async (event: MapLayerMouseEvent) => {
      if (!event.features?.length) return

      const nftId = event.features[0].properties?.nft_id
      const areaId = event.features[0].properties?.area_id
      if (!nftId) return

      try {
        const { data: nftAvailabilityData } = await api.get<MapNFT>(
          `nfts/check-nft-availability/${areaId}`
        )
        const response = await api.get<MapNFT>(`nfts/check-nft-by-id/${nftId}`)
        if (!response?.data?.id) {
          toast.error('NFT não encontrado', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })

          return
        }

        if (
          response.data.status === 'SOLD' ||
          nftAvailabilityData?.status === 'SOLD'
        ) {
          setPopupInfo(response.data)
          updateNFTStatus(response.data.id, response.data.status, response.data)
        }

        if (
          response.data.status === 'ACTIVE' ||
          response.data.status === 'RESERVED' ||
          nftAvailabilityData?.status === 'ACTIVE' ||
          nftAvailabilityData?.status === 'RESERVED'
        ) {
          setPopupInfo(response.data)
          onSelectArea && onSelectArea()
          console.log(response.data)
        }
      } catch (error) {
        if (isAxiosError<CheckNFTAvailabilityErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.messages
          if (errorMessage?.length) {
            errorMessage.forEach(message => {
              toast.error(
                message?.Message[i18n.language as keyof MessageTypeProps] ||
                  t('mapbox.check-area-error'),
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
            })
          } else {
            toast.error(t('mapbox.check-area-error'), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })

            return
          }
        } else {
          toast.error(t('mapbox.check-area-error'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })

          return
        }
      }
    },
    [
      t,
      i18n.language,
      onSelectArea,
      updateNFTStatus,
    ]
  )
    
  async function onMapMove(event: MapLayerMouseEvent){
    setHoverInfo({
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng
    })

    if (!event.features?.length) {
      setHoverLayer(null)
      return false
    }
    const center = event.features[0].properties?.center
    const nftId = event.features[0].properties?.nft_id
    // const areaId = event.features[0].properties?.area_id
    const area = {
      type: 'FeatureCollection',
      features: mergedAreas.features.filter(a=>a.id === nftId)
    }
    setHoverLayer(area)
    // console.log(event.features[0].properties, area)
    if(center){
      const parsedCenter = JSON.parse(center)
      setHoverInfo({
        latitude: parsedCenter[1],
        longitude: parsedCenter[0]
      })
    }
    // const response = await api.get<MapNFT>(`nfts/check-nft-by-id/${nftId}`)
    // console.log(response)
  }

  useEffect(() => {
    if (sponsoredAreas?.features.length && !isSponsorLoaded) {
      setIsSponsorLoaded(true)
      const map = mapRef.current?.getMap()

      // console.log(sponsoredAreas)
      sponsoredAreas?.features.forEach(feature => {
        const coordinates = feature.geometry.coordinates[0].slice(0, -1)
        fetchImageAsDataURL(feature?.properties?.image)
          .then(dataURL => {
            const layerId = 'imageOverlay-' + feature.properties?.nft_id

            rotateImage(
              {
                imageURL: dataURL as string,
                rotationDegrees: -90,
                flipImage: true
              },
              rotatedDataURL => {
                const imageOverlay: RasterLayerProps = {
                  id: layerId,
                  type: 'raster',
                  source: {
                    type: 'image',
                    url: rotatedDataURL,
                    coordinates
                  }
                }

                if (map?.getLayer(layerId)) {
                  map.removeLayer(layerId)
                  map.removeSource(layerId)
                }

                map?.addLayer(imageOverlay)
              }
            )
          })
          .catch(error => {
            console.error('Failed to fetch or process the image:', error)
          })
      })
    }
  }, [isSponsorLoaded, sponsoredAreas])

  function flyTo(lng:number, lat:number, zoom = 14, duration = 2000){
    mapRef.current?.flyTo({
      center: [lng,lat],
      duration: duration,
      zoom: zoom
    })
  }

  function flyHome(lng: number, lat: number, zoom = 14, duration = 2000){
    flyTo(lng, lat, zoom, duration)
  }
  function zoomIn(){
    mapRef.current?.zoomIn({duration: 1000})
  }
  function zoomOut(){
    mapRef.current?.zoomOut({duration: 1000})
  }

  const handleDownloadCertificate = async (nftId: string, nftName: string) => {
    try {
      if (!nftId) {
        throw new Error('NFT ID is missing.');
      }
  
      const response = await api.get(`/nfts/generate-pdf-certificate/${nftId}`, {
        responseType: 'blob',
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const name = nftName?.split('#')[1] || 'Certificate';
      const anchor = document.createElement('a');
      
      anchor.href = url;
      anchor.download = `AmazonTree-Token-${name}.pdf`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading certificate:', error);
    }
  }

  useEffect(() => {
    if (!!selectedOwnedNFT) {
      mapRef.current?.flyTo({
        center: [
          selectedOwnedNFT.area.pointCenter.longitude,
          selectedOwnedNFT.area.pointCenter.latitude
        ],
      })
      setPopupInfo(selectedOwnedNFT)
    }
  }, [selectedOwnedNFT])

  return (
    <>
      {!!mapFly && (
        <Map
          initialViewState={MAP_INITIAL_STATE}
          attributionControl={false}
          ref={mapRef}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_KEY}
          interactiveLayerIds={['data']}
          onClick={onMapClick}
          pitch={50}
          onLoad={() => flyHome(mapFly.longitude, mapFly.latitude, mapFly.zoom, mapFly.duration)}
          style={{
            cursor: 'pointer'
          }}
          onMouseMove={onMapMove}
        >
          <Source id="data" type="geojson" data={mergedAreas} tolerance={0}>
            <MapboxLayers />
          </Source>

          { hoverLayer ?
            <Source type="geojson" data={hoverLayer} id="fill">
              <Layer {...dataLayer} />
            </Source>
          : null}

          {popupInfo && (
            <Popup
              onClose={() => {
                setPopupInfo(null)
              }}
              anchor="bottom"
              longitude={popupInfo.area.pointCenter.longitude}
              latitude={popupInfo.area.pointCenter.latitude}
              maxWidth="30rem"
              className="[&>div>button]:text-xl -[&>div>button]:top-1"
            >
              <PopupMap
                nft={popupInfo}
                artisticImage={userNFTs?.some((nft) => nft?.id === popupInfo.id)
                  ? userNFTs?.find((nft) => nft?.id === popupInfo.id)?.artisticImage
                  : null}
                onAdd={() => {
                  if (handleActiveTab) {
                    handleActiveTab()
                  }

                  setPopupInfo(null);
                  addNFT(popupInfo);
                }}
                onDelete={() => {
                  setPopupInfo(null);
                  deleteNFT(popupInfo);
                }}
                {...(userNFTs?.some(nft => nft?.id === popupInfo.id)
                  ? { onDownload: handleDownloadCertificate }
                  : {})}
              />
            </Popup>
          )}

          <div className="absolute bottom-5 left-1/2 -translate-x-1/2 text-amz3white-50 text-xs md:text-base font-semibold bg-amz3black-50 bg-opacity-40 rounded-l-sm rounded-r-sm">
            <span
              style={{
                textShadow: '2px 2px 2px #000'
              }}
            >
              Lat: {(hoverInfo?.latitude || 0).toFixed(6)} | Long:{' '}
              {(hoverInfo?.longitude || 0).toFixed(6)}
            </span>
          </div>

          <div id="mapbox-controls" className="absolute top-0 left-0 p-4 flex gap-2">
              <button
                className="catalog-mapbox-button"
                onClick={() => flyHome(DEFAULT_LONG_LAT.longitude, DEFAULT_LONG_LAT.latitude, 14, 2000)}
              >
                <ViewfinderCircleIcon width={24} color='inherit'/>
              </button>
              <button className="catalog-mapbox-button" onClick={() => zoomIn()}>
                <MagnifyingGlassPlusIcon width={24} color='inherit'/>
              </button>
              <button className="catalog-mapbox-button" onClick={() => zoomOut()}>
                <MagnifyingGlassMinusIcon width={24} color='inherit'/>
              </button>
          </div>
        </Map>
      )}
    </>
  )
}
