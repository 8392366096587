import { Link } from 'react-router-dom'
import { Footer } from '../Footer'
import { Navbar } from '../Navbar'
import { LanguageSelector } from '../LanguageSelector'
import { ROUTES_PATH } from '../../routes'
import { useLanguage, LanguageProps } from '../../store/useLanguage'
import { ProfileMenu } from '../ProfileMenu'
import { AM3Logo } from '../AM3Logo'
import { XMarkIcon } from '@heroicons/react/20/solid'

type MenuProps = {
  onClose: () => void
}

export function Menu({ onClose }: MenuProps) {
  const { setLanguage } = useLanguage()

  function handleCloseLangueSelector(language: LanguageProps) {
    setLanguage(language)
  }

  return (
    <header className="fixed bg-amz3black-300 w-full h-screen flex flex-col px-4 py-6 z-50">
      <div className="flex items-center w-full justify-between">
        <button onClick={onClose}>
          <XMarkIcon fill="#eee" width={48}/>
        </button>
        <div className="flex">
          <ProfileMenu onSelect={onClose} />
          <LanguageSelector onClose={handleCloseLangueSelector} />
        </div>
      </div>
      <Link
          to={ROUTES_PATH.HOME.path}
          onClick={onClose}
          className="text-amz3green-100 font-semibold text-2.5xl uppercase text-center mx-auto my-4"
        >
          <AM3Logo full className='px-4' />
        </Link>
      <Navbar onClickLink={onClose} className='px-16 !flex-col'/>
      <Footer />
    </header>
  )
}
