interface DateGeneratorOptions {
  backwards?: boolean;
  startDate?: Date;
}

/**
* Generates an array of dates in YYYY-MM-DD format
* @param {number} numDays - Total number of dates to generate
* @param {number} skipDays - Interval between dates
* @param {DateGeneratorOptions} options - Optional configuration
* @returns {string[]} Array of dates in YYYY-MM-DD format
*/
export function generateDateArray(
  numDays: number, 
  skipDays: number, 
  options: DateGeneratorOptions = {}
): string[] {
  // Input validation
  if (!Number.isInteger(numDays) || numDays <= 0) {
      throw new Error('numDays must be a positive integer');
  }
  if (!Number.isInteger(skipDays) || skipDays <= 0) {
      throw new Error('skipDays must be a positive integer');
  }

  const dates: string[] = [];
  const startDate = options.startDate || new Date();
  const direction = options.backwards ? -1 : 1;
  
  for (let i = 0; i < numDays; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + (i * skipDays * direction));
      
      // Format date as YYYY-MM-DD
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      
      dates.push(`${year}-${month}-${day}`);
  }
  
  return dates;
}

// Example usage:
// Generate 5 dates forward, every 3 days
// console.log(generateDateArray(5, 3));
// Output: ['2024-10-28', '2024-10-31', '2024-11-03', '2024-11-06', '2024-11-09']

// Generate 5 dates backward, every 3 days
// console.log(generateDateArray(5, 3, { backwards: true }));
// Output: ['2024-10-28', '2024-10-25', '2024-10-22', '2024-10-19', '2024-10-16']

// Generate 3 dates forward from a specific date
// console.log(generateDateArray(3, 1, { startDate: new Date('2024-12-25') }));
// Output: ['2024-12-25', '2024-12-26', '2024-12-27']