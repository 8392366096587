import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'

type LanguageButtonProps = {
  children: ReactNode
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export function LanguageButton({ children, ...props }: LanguageButtonProps) {
  return (
    <button
      className="text-amz3white-50  font-medium text-center h-[52px] w-[233px] rounded-2lg bg-[#2D2D2D]"
      {...props}
    >
      {children}
    </button>
  )
}
