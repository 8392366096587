import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { BitcoinCard } from 'iconsax-react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Form from '@radix-ui/react-form'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction
} from '@solana/web3.js'
import { useTranslation } from 'react-i18next'
import { CheckoutFormProps, checkoutFormSchema } from './type'
import { AddWallet, BuyContract, Spin } from '../../components'
import { ReactComponent as SignIcon } from '../../assets/icons/money-sign.svg'
import { ReactComponent as SolanaIcon } from '../../assets/icons/solana.svg'
import { CheckoutResume } from './checkout-resume'
import { useCartStore, useTerms } from '../../store'
import { api } from '../../services/api'
import { OrderDto } from '../../models/order-dto'
import { CurrencyProps } from '../../store/useCart'
import { useCurrencyExchange } from '../../services/hooks/useCurrencyExchange'
import { HandleCryptoPaymentDto } from '../../models/handle-crypto-payment.dto'
import { CurrencyEnum } from '../../models/enums/currency.enum'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { AREA_OPTIONS } from '../../components/NFTMap/types'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { ROUTES_PATH } from '../../routes'
import { NotificationResponse } from '../../models/notification-response';

type CryptoPreCheckoutResponseProps = {
  toPubkey: PublicKey
  lamports: number
  orderId: string
}

export type MessageTypeProps = {
  pt: string
  en: string
}

type TMessages = {
  projectAreaId?: string
  status?: keyof typeof AREA_OPTIONS
  nftId?: string
  Item: string
  Message: MessageTypeProps
}

type ErrorMessage = {
  messages: TMessages[]
}

export function CheckoutForm() {
  const { t, i18n } = useTranslation()
  const {
    validNFTs,
    currency,
    setCurrency,
    updateNFTStatus,
    clearNFTs,
    id: orderId
  } = useCartStore()
  const { isUseTermsAccepted, setIsUseTermsAccepted } = useTerms()
  const { wallet, publicKey, sendTransaction } = useWallet()
  const [sending, setSending] = useState(false)
  const { data: currencyValue, refetch: refetchCurrencyValue } =
    useCurrencyExchange(currency)
  const contactUsForm = useForm<CheckoutFormProps>({
    resolver: zodResolver(checkoutFormSchema),
    defaultValues: {
      wallet: '',
      currency
    }
  })
  const navigate = useNavigate()
  const [isContractOpen, setIsContractOpen] = useState(false)
  const [isEmptyWalletChecked, setIsEmptyWalletChecked] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors, isSubmitting },
    setError
  } = contactUsForm

  useEffect(() => {
    refetchCurrencyValue()
  }, [currency, refetchCurrencyValue])


  const amount = Number(
    validNFTs()
      .reduce((prev, curr) => {
        return prev + curr.price
      }, 0)
      .toFixed(2)
  )
  const totalNFT = amount * (currencyValue || 1)

  const fireWalletTransfer = useCallback(
    async ({
      toPubkey,
      fromPubkey,
      lamports
    }: {
      toPubkey: PublicKey
      fromPubkey: PublicKey
      lamports: number
    }) => {
      const connection = new Connection(
        process.env.REACT_APP_SOLSCAN_RPC_HOST!,
        'confirmed'
      )

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey,
          toPubkey,
          lamports: lamports
        })
      )
      try {

        const signature = await sendTransaction(transaction, connection)
        const result = await connection.confirmTransaction(signature, 'processed')

        return {
          signature,
          result
        }
      } catch {
        console.log('error')
        return {
          signature: undefined,
          result: undefined
        }
      }
    },
    [sendTransaction]
  )

  const cryptoPreCheckoutHandler = useCallback(
    async (orderDto: OrderDto): Promise<HandleCryptoPaymentDto | undefined> => {
      const locale = `${i18n.language}`
      try {
        const resultPreCheckout =
          await api.post<CryptoPreCheckoutResponseProps | NotificationResponse>(
            '/orders/crypto-pre-checkout',
            orderDto
          )

        if (resultPreCheckout.status === 401) {
          window.location.href = ROUTES_PATH.SIGN_IN.path
          setSending(false)
          return
        }

        if (resultPreCheckout.status >= 300) {
          const { messages } = resultPreCheckout.data as NotificationResponse
          const { Message } = messages[0]
          const toastErrorMessage = locale === "en" ? Message.en : Message.pt
          toast.error(toastErrorMessage ?? t('checkout.crypto-pre-checkout-exception'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
          setSending(false)
          return
        }
        const { toPubkey, lamports, orderId } = resultPreCheckout.data as CryptoPreCheckoutResponseProps

        const { signature, result } = await fireWalletTransfer({
          toPubkey,
          fromPubkey: publicKey!,
          lamports
        })

        if (!signature) {
          toast.error(t('checkout.signature-exception'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
          setSending(false)
          return
        }
        const handleCryptoPaymentDto: HandleCryptoPaymentDto = {
          orderId,
          signature,
          userPkAddress: `${publicKey}`
        }

        if (result?.value?.err) {
          return
        }

        return handleCryptoPaymentDto
      } catch (error: any) {
        const { messages } = error.response.data as NotificationResponse
        const { Message } = messages[0]
        const toastErrorMessage = locale === "en" ? Message.en : Message.pt
        toast.error(toastErrorMessage ?? t('checkout.crypto-pre-checkout-exception'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: 'dark'
        })
        setSending(false)
        return
      }
    },
    [fireWalletTransfer, i18n.language, publicKey, t]
  )

  const onSubmit = useCallback(
    async (data?: CheckoutFormProps) => {
      if (!data) return
      if (!validNFTs()?.length) {
        return
      }
      const purchaseValue = JSON.stringify({ value: amount, currency: 'USD' });
      <script>fbq('track', 'Purchase', '{purchaseValue}');</script>

      setSending(true)
      try {
        let orderDto = {
          nft: validNFTs().map(cartNFT => ({
            id: cartNFT.id,
            name: cartNFT.name,
            description: cartNFT.description,
            image: cartNFT.image,
            imageMap: cartNFT.imageMap,
            status: cartNFT.status,
            isAvailable: cartNFT.isAvailable,
            projectId: cartNFT.projectId,
            projectAreaId: cartNFT.projectAreaId,
            area: cartNFT.area,
            nftPosition: cartNFT.nftPosition,
            price: cartNFT.price,
            areaInSquareMeters: cartNFT.areaInSquareMeters,
            edition: cartNFT.edition,
            quantityOfYears: 1,
            total: cartNFT.price,
            h2oProduction: cartNFT.areaInSquareMeters * 5
          })),
          userPkAddress: data.wallet,
          currency: data.currency,
          amount: totalNFT,
          id: orderId
        } as OrderDto

        let handleCryptoPaymentDto: HandleCryptoPaymentDto | undefined
        let status: number = 0
        let checkoutUrl: string = ''

        const gTagPurchaseFinalized = JSON.stringify({
          'send_to': 'AW-16708129960/bQzmCJHmnNQZEKiph58-',
          'value': orderDto.amount,
          'currency': orderDto.currency,
          'transaction_id': orderDto.id
        });
        <script>  gtag('event', 'conversion', {gTagPurchaseFinalized});</script>

        const gTagBeginCheckout = JSON.stringify({
          transaction_id: orderDto.id,
          value: amount,
          currency: "USD",
          coupon: "Not implemented yet",
          items: [
            orderDto.nft.map((nft, index) => {
              return {
                item_id: nft.id,
                item_name: nft.name,
                affiliation: nft.description,
                coupon: "Not implemented yet",
                discount: 0,
                index,
                item_brand: `${nft.name} - ${nft.edition}`,
                item_category: nft.areaInSquareMeters,
                price: nft.price,
                quantity: 1
              }
            })
          ]
        });
  
        <script>gtag("event", "begin_checkout",{gTagBeginCheckout})</script>


        if (data.currency === CurrencyEnum.SOL) {
          handleCryptoPaymentDto = await cryptoPreCheckoutHandler(orderDto)

          if (handleCryptoPaymentDto) {
            const {
              status: cryptoStatus,
              data: { checkoutUrl: cryptoCheckoutUrl }
            } = await api.post<{ checkoutUrl: string }>(
              '/orders/handle-crypto-payment',
              handleCryptoPaymentDto
            )
            status = cryptoStatus
            checkoutUrl = cryptoCheckoutUrl
          }
        } else {
          const {
            status: fiduciaryStatus,
            data: { checkoutUrl: fiduciaryCheckoutUrl }
          } = await api.post<{ checkoutUrl: string }>(
            '/orders/create-checkout-session',
            orderDto
          )
          status = fiduciaryStatus
          checkoutUrl = fiduciaryCheckoutUrl
        }

        if (status >= 300) {
          toast.error(t('checkout.order-exception'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
          setSending(false)
          return
        }
        const completePurchase = JSON.stringify({
          transaction_id: orderDto.id,
          value: amount,
          currency: "USD",
          coupon: "Not implemented yet",
          items: [
            orderDto.nft.map((nft, index) => {
              return {
                item_id: nft.id,
                item_name: nft.name,
                affiliation: nft.description,
                coupon: "Not implemented yet",
                discount: 0,
                index,
                item_brand: `${nft.name} - ${nft.edition}`,
                item_category: nft.areaInSquareMeters,
                price: nft.price,
                quantity: 1
              }
            })
          ]
        });
        <script>gtag("event", "purchase", {completePurchase}); </script>

        if (!!checkoutUrl) window.location.href = checkoutUrl
        reset()
        setSending(false)
        handleCloseContract()
      } catch (error) {
        handleCloseContract()
        if (axios.isAxiosError<ErrorMessage>(error)) {
          navigate(ROUTES_PATH.CATALOG.path)
          if (!!error.response?.data.messages?.length) {
            error.response?.data.messages.forEach(errorMessageDto => {
              if (errorMessageDto.Item === 'nft-availability') {
                clearNFTs()
              }
              toast.error(
                errorMessageDto.Message[
                i18n.language as keyof MessageTypeProps
                ],
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )

              errorMessageDto.nftId &&
                errorMessageDto.status &&
                updateNFTStatus(errorMessageDto.nftId, errorMessageDto.status)
            })
          }
        }
        setSending(false)
      }
    },
    [amount, clearNFTs, cryptoPreCheckoutHandler, i18n.language, navigate, orderId, reset, t, totalNFT, updateNFTStatus, validNFTs]
  )

  const handleSubmitForm = useCallback(
    (data: CheckoutFormProps) => {
      let hasError = false;

      if (!isEmptyWalletChecked && !getValues('wallet')) {
        hasError = true;

        t('checkout.order-exception')
        setError('wallet', {
          message: t('checkout.form-error-messages.wallet')
        })
      }

      if (!isUseTermsAccepted) {
        setIsContractOpen(true)
        hasError = true;
      }

      if (!hasError) {
        <script>fbq('track', 'AddPaymentInfo');</script>
        onSubmit(data);
      }
    },
    [isEmptyWalletChecked, getValues, isUseTermsAccepted, onSubmit, t, setError]
  )

  const handleCurrencyChange = useCallback(
    (value: CurrencyProps) => {
      setCurrency(value)
      setValue('currency', value)
    },
    [setCurrency, setValue]
  )

  useEffect(() => {
    if (wallet && publicKey) {
      const base58 = publicKey?.toBase58() || ''
      setValue('wallet', base58)
    } else {
      handleCurrencyChange('USD')
    }
  }, [handleCurrencyChange, publicKey, setValue, wallet])

  useEffect(() => {
    setValue('currency', currency)
  }, [currency, setValue])

  const handleCloseContract = () => {
    setIsContractOpen(false)
  }

  const handleConfirmContract = () => {
    setIsUseTermsAccepted(true)
    setIsContractOpen(false)
  }

  return (
    <>
      {isContractOpen && (
        <BuyContract
          isOpen={isContractOpen}
          onClose={handleCloseContract}
          onConfirm={handleConfirmContract}
        />
      )}
      <Form.Root
        className="flex flex-col gap-3.5 mt-6 max-h-[82%] overflow-auto"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <FormProvider {...contactUsForm}>
          {!publicKey && (
            <>
              <Form.Field className="" name="wallet">
                <Form.Control asChild>
                  <>
                    <input
                      className="rounded-2lg pl-7 border border-amz3green-50 h-[4.5rem] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none"
                      placeholder={t('checkout.form-wallet')}
                      type="text"
                      {...register('wallet')}
                    />
                    {!watch('wallet') && (
                      <label className="flex items-center gap-2 mt-2 text-[#BCBCBC]">
                        <input
                          className="h-4 w-4"
                          type="checkbox"
                          onClick={() => setIsEmptyWalletChecked(prev => !prev)}
                        />
                        <span className="font-light">
                          {t('checkout.form-wallet-empty-term')}
                        </span>
                      </label>
                    )}
                    {errors.wallet && (
                      <span className="text-red-500">
                        {errors.wallet?.message}
                      </span>
                    )}
                  </>
                </Form.Control>
              </Form.Field>
              <span className="mt-6 mb-2 text-amz3green-50 text-center font-medium">
                {t('checkout.form-payment-label')}
              </span>
            </>
          )}
          <Form.Field className="" name="currency">
            <Form.Control asChild>
              <>
                <ToggleGroup.Root
                  className="flex flex-col gap-2.5 md:flex-row"
                  type="single"
                  aria-label={t('checkout.form-payment-label')}
                  onValueChange={handleCurrencyChange}
                  value={getValues('currency')}
                >
                  <ToggleGroup.Item
                    className="h-14 w-full rounded-2lg border border-[#70CD5C] bg-transparent text-[#70CD5C] fill-[#70CD5C] font-medium flex items-center justify-center gap-4 data-[state=on]:bg-[#70CD5C] hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in data-[state=on]:text-amz3black-50 data-[state=on]:fill-amz3black-50 data-[state=on]:hover:bg-opacity-80"
                    value="BRL"
                    aria-label="Real"
                  >
                    <SignIcon />
                    <span>Real (BRL)</span>
                  </ToggleGroup.Item>
                  <ToggleGroup.Item
                    className="h-14 w-full rounded-2lg border border-[#70CD5C] bg-transparent text-[#70CD5C] fill-[#70CD5C] font-medium flex items-center justify-center gap-4 data-[state=on]:bg-[#70CD5C] hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in data-[state=on]:text-amz3black-50 data-[state=on]:fill-amz3black-50 data-[state=on]:hover:bg-opacity-80"
                    value="USD"
                    aria-label="Dólar"
                  >
                    <SignIcon />
                    <span>Dólar (USD)</span>
                  </ToggleGroup.Item>
                  {publicKey ? (
                    <ToggleGroup.Item
                      className={twMerge(
                        'h-14 w-full rounded-2lg border border-[#70CD5C] bg-transparent text-[#70CD5C] fill-[#70CD5C] font-medium flex items-center justify-center gap-4 data-[state=on]:bg-[#70CD5C] hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in data-[state=on]:text-amz3black-50 data-[state=on]:fill-amz3black-50 data-[state=on]:hover:bg-opacity-80',
                        !publicKey &&
                        'border-gray-400 text-gray-400 fill-gray-400 hover:bg-gray-400'
                      )}
                      value="SOL"
                      aria-label="Solana"
                      disabled={!publicKey}
                    >
                      <SolanaIcon />
                      <span>Solana (SOL)</span>
                    </ToggleGroup.Item>
                  ) : (
                    <ToggleGroup.Item
                      className={twMerge(
                        'h-14 w-full rounded-2lg border border-[#70CD5C] bg-transparent text-[#70CD5C] fill-[#70CD5C] font-medium flex items-center justify-center gap-4 data-[state=on]:bg-[#70CD5C] hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in data-[state=on]:text-amz3black-50 data-[state=on]:fill-amz3black-50 data-[state=on]:hover:bg-opacity-80',
                        !publicKey &&
                        'border-gray-400 text-gray-400 fill-gray-400 hover:bg-gray-400'
                      )}
                      aria-label="Solana"
                      value="SOL"
                    >
                      <AddWallet className="!h-14 !w-full rounded-2lg !border !border-gray-400 !bg-transparent !text-gray-400 !fill-gray-400 font-medium flex items-center justify-center gap-4 hover:!bg-gray-400 hover:!bg-opacity-10 !transition-all !duration-200 ease-in !leave:duration-200 !leave:ease-in">
                        <SolanaIcon />
                        <span>Solana (SOL)</span>
                      </AddWallet>
                    </ToggleGroup.Item>
                  )}
                </ToggleGroup.Root>
                {errors.currency && (
                  <span className="text-red-500">
                    {errors.currency?.message}
                  </span>
                )}
              </>
            </Form.Control>
          </Form.Field>
          <span className="mx-8 mt-4 text-center text-sm text-[#BCBCBC]">
            {t('checkout.form-payment-info')}
          </span>
          <div className="hidden md:w-full md:flex md:flex-col md:gap-4">
            <CheckoutResume
              nftTotal={validNFTs()?.length}
              currency={currency}
              totalValue={totalNFT}
            />
          </div>
          <Form.Submit asChild>
            <button
              disabled={isSubmitting || !validNFTs()?.length}
              className="bg-amz3green-150 mt-4 rounded-2lg w-full min-h-[4.25rem] h-[4.25rem] flex justify-center items-center gap-4 text-[#1B1B1B] hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
            >
              {sending ? (
                <Spin />
              ) : (
                <>
                  <span className="font-semibold text-2xl">
                    {t('checkout.form-submit-btn')}
                  </span>
                  <BitcoinCard />
                </>
              )}
            </button>
          </Form.Submit>
        </FormProvider>
      </Form.Root>
    </>
  )
}
