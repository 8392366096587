import { Layer } from 'react-map-gl'
import { LAYER_STYLE } from './types'

export function MapboxLayers() {
  return (
    <>
      <Layer {...LAYER_STYLE} />
      <Layer
        type="line"
        source="data"
        paint={{
          'line-color': 'rgb(255, 255, 255)'
        }}
      />
    </>
  )
}
