import React from 'react';
import { Badge } from '@mantine/core';
import { OrderStatus } from '../../../../models/enums/order-status.enum';
import { t } from 'i18next';

type CustomBadgeProps={
    orderStatus:OrderStatus

}


export function CustomBadge({orderStatus}:CustomBadgeProps) {
    let badge: any;
    switch (orderStatus) {
        case OrderStatus.TOKEN_MINTED:
        case OrderStatus.PAYMENT_APPROVED:
        case OrderStatus.TOKEN_MOVED_TO_USER:
            badge = (
                <Badge color="green.8" size="xs" radius="sm">
                    {t(`orderStatus.${orderStatus}`)}
                </Badge>
            );
            break;
        case OrderStatus.CREATED:
        case OrderStatus.VALIDATING_PAYMENT:
        case OrderStatus.CREATING_TOKEN:
            badge = (
                <Badge color="orange.8" size="xs" radius="sm">
                    {t(`orderStatus.${orderStatus}`)}
                </Badge>
            );
            break;
        case OrderStatus.PAYMENT_REFUSED:
        case OrderStatus.DISCARDED:
            badge = (
                <Badge color="red.8" size="xs" radius="sm">
                    {t(`orderStatus.${orderStatus}`)}
                </Badge>
            );
            break;
        default: (<> </>);
    }
    return (badge)
}