import FlipNumbers from "react-flip-numbers";
import { useEffect, useState } from "react";
import { useFeedMapsByUser } from "../../../../../services/hooks/useNFTs";
import { calculateInitialFlip, flipNumberFormat, SECONDS_IN_YEAR } from "./flip-util";
import { HelpDialog, HelpProps } from "./help-dialog";

interface Props {
  ratePerMeter: number,
  label: string,
  suffix: string,
  help: HelpProps
}

export const FlipCard = ({ratePerMeter, suffix, label, help}:Props) => {

  const { data:nfts } = useFeedMapsByUser()
  const [carbonCaptured, setCarbonCaptured] = useState<number>(0);

  useEffect(() => {
    const calculateInitialCarbonCapture = () => {
      return nfts?.reduce((total, nft) => {
        return total + calculateInitialFlip(nft, ratePerMeter);
      }, 0);
    };

    const calculateCarbonCaptureRate = () => {
      return nfts?.reduce((total, nft) => {
        return total + (nft.areaInSquareMeters * ratePerMeter / SECONDS_IN_YEAR);
      }, 0);
    };

    const initialCarbonCapture = calculateInitialCarbonCapture();
    const carbonCaptureRate = calculateCarbonCaptureRate();

    setCarbonCaptured(initialCarbonCapture ?? 0);

    const interval = setInterval(() => {
      setCarbonCaptured(prevCapture => prevCapture + (carbonCaptureRate ?? 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [nfts, ratePerMeter]);

  return (
    <div className="lg:min-w-[180px] p-4 bg-amz3black-50 rounded-md relative grow lg:grow-0">
      <div className="text-amz3green-100 text-lg font-bold [&>section]:!justify-start">
        <FlipNumbers
          height={20}
          width={16}
          color="#03ff00"
          // background="black"
          play
          perspective={400}
          numbers={`${flipNumberFormat(carbonCaptured)} ${suffix}`}
        />
      </div>
      <div className="text-amz3green-50 max-w-[70%] text-sm">{label}</div>
      <div
        className={"absolute bottom-4 right-4 leading-none"}
      >
        <HelpDialog help={help}/>
      </div>
    </div>
)}