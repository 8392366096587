import { useLoading } from '../../store/useLoading'
import { useTranslation } from 'react-i18next'
import { AM3Logo } from '../AM3Logo'
import { motion, AnimatePresence } from "framer-motion"

export function Spinner() {
  const { t } = useTranslation()
  const { isLoading, setLoading } = useLoading()

  return (
    <AnimatePresence>
     { isLoading &&
      <motion.div
        initial={{ opacity: 0, scale: 1.2 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.2 }}
        transition={{ duration: 0.5 }}
        tabIndex={-1}
        className="h-full w-full z-[9999] fixed bg-amz3black-200 bg-opacity-95 flex flex-col items-center pt-[20%]"
        onKeyDown={event => event.key === 'Escape' && setLoading(false)}
      >
        <AM3Logo className="animate-endless-flip h-[64px]" />
        <span className="text-amz3green-150 mt-2 text-lg font-medium animate-pulse">
          {t('loading')}
        </span>
      </motion.div>
    }
    </AnimatePresence>
  )
}
