import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

type NFTInfoCardProps = {} & DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export function NFTInfoCard({
  children,
  className,
  ...props
}: NFTInfoCardProps) {
  return (
    <div
      className={twMerge(
        'h-20 bg-[#1B4524] w-full max-w-sm flex items-center pl-7 rounded-lg text-amz3green-50',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
