import { ContactUs } from '../ContactUs'
import { FooterPage } from '../../components'

export function AboutCertificate() {
  return (
    <>
      <section className='p-16 lg:min-h-[80dvh] flex flex-col justify-center items-center bg-amz3green-200 text-amz3green-100'>
        <h3 className='text-4xl'>Data for a Greener Future</h3>
      </section>

      <ContactUs />
      <FooterPage />
    </>
  )
}
