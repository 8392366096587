import { useQuery } from 'react-query'
import { api } from '../../../../services/api'
import { TOrders } from './types'

export async function getOrders(): Promise<TOrders | null> {
  try {
    const { data: orders } = await api.get<TOrders>(
      '/orders/get-orders-by-user-id'
    )
    return orders
  } catch (error) {
    // console.log(error)
    return null
  }
}

export function useOrders() {
  return useQuery('get-orders-by-user-id', () => getOrders(), {
    staleTime: 1000 * 60 * 60, //1 hra
    retry: 0
  })
}
