import { useState, useRef, useEffect, useCallback } from 'react'
import { FormSubmit } from '../Form'
import { CloseCircle } from 'iconsax-react'
import { twMerge } from 'tailwind-merge'
import { api } from '../../services/api'
import {useTranslation} from 'react-i18next';

type BuyContractProps = {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

export function BuyContract({ isOpen, onConfirm, onClose }: BuyContractProps) {
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [contractData, setContractData] = useState<string | null>(null)
  const termsRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const handleScroll = useCallback(() => {
    if (!termsRef.current) return
    const { scrollTop, scrollHeight, clientHeight } = termsRef.current
    const isBottom = scrollTop + clientHeight + 1 >= scrollHeight
    !isChecked && setIsCheckboxEnabled(isBottom)
  }, [isChecked])

  useEffect(() => {
    const termsElement = termsRef.current

    if (termsElement?.scrollHeight === termsElement?.clientHeight) {
      setIsCheckboxEnabled(true)
    }

    termsElement?.addEventListener('scroll', handleScroll)
    return () => {
      termsElement?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    api
      .get('/contracts/get-latest-contract')
      .then(response => {
        setContractData(response.data)
      })
      .catch(error => {
        console.error('Error fetching contract data:', error)
      })
  }, [])

  if (!isOpen) {
    return null
  }

  return (
    <div
      tabIndex={0}
      className="fixed w-screen h-screen overflow-auto top-0 left-0 z-[60] bg-amz3black-200 text-amz3white-100 pt-6 pb-24 px-12"
      onKeyDown={event => event.key === 'Escape' && onClose()}
    >
      <div className="h-full py-4">
        <div className="flex items-center mb-8 w-full">
          <div className="flex-grow text-center">
            <h1 className="font-semibold text-3xl uppercase">
            {t('buyContract.agreementTerm') }
            </h1>
          </div>
          <button onClick={onClose} className="ml-auto">
            <CloseCircle size={32} />
          </button>
        </div>

        <div
          ref={termsRef}
          className="overflow-y-scroll h-3/4 border border-gray-400 p-8 mb-3 flex flex-col gap-4"
        >
          {contractData && (
            <div dangerouslySetInnerHTML={{ __html: contractData }}></div>
          )}
        </div>
        <label className="flex items-center gap-2">
          <input
            className="h-5 w-5"
            type="checkbox"
            onClick={() => setIsChecked(prev => !prev)}
            disabled={!isCheckboxEnabled}
          />
          <span className="text-lg font-light">
          {t('buyContract.iAgreeToThe') }{' '}
            <a
              className="underline"
              href="https://amazon-tree-r.gitbook.io/white-paper-amz3/condicoes-gerais-anexo-i"
              target="_blank"
              rel="noreferrer"
            >
               {t('buyContract.termsAndConditions') }
            </a>
          </span>
        </label>
        <FormSubmit
          className={twMerge(
            !isChecked &&
              'border border-gray-400 text-gray-400 bg-transparent hover:bg-gray-400 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in'
          )}
          isSubmitting={false}
          disabled={!isChecked}
          onClick={() => {
            if (isChecked) {
              onConfirm()
            }
          }}
        >
           {t('buyContract.confirm') }
        </FormSubmit>
      </div>
    </div>
  )
}
