import { Link, LinkProps } from 'react-router-dom'

type FooterButtonProps = {} & LinkProps

export function FooterButton({ children, to, ...props }: FooterButtonProps) {
  return (
    <Link
      to={to}
      className="bg-amz3white-100 w-36 h-12 rounded-lg font-medium text-amz3black-200 flex items-center justify-center gap-2 hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
      {...props}
    >
      {children}
    </Link>
  )
}
