import { ChevronLeft, ChevronRight } from 'lucide-react'
import { twMerge } from 'tailwind-merge'

export type HelpProps = {
  title: string;
  text: string;
}

export type TimelineProps = {
  availableDates: string[];
  selectedIndex: number;
  onChangeDate: (date: string, index:number) => void;
}

export function Timeline({ availableDates, selectedIndex, onChangeDate }:TimelineProps) {

  function handlePrev(){
    const index = selectedIndex-1
    onChangeDate(availableDates[index], index)
  }
  
  function handleNext(){
    const index = selectedIndex+1
    onChangeDate(availableDates[index], index)
  }

  function handlePoint(index:number){
    onChangeDate(availableDates[index], index)
  }

  return (
    <div
      className={twMerge(
        'bg-amz3green-300 text-amz3green-50 p-2 pr-0',
        'flex flex-row gap-1 items-center',
      )}
      >
      <button
        className='disabled:opacity-50'
        disabled={selectedIndex === 0}
        onClick={handlePrev}
      >
        <ChevronLeft size={16}/>
      </button>
      <div className='select-none w-[80px] text-center'>
        {/* <span className='text-amz3green-100'>{`${selected+1}/${availableDates.length}`}</span>  */}
        <span>{availableDates[selectedIndex].toString()}</span>
      </div>
      <button
        className='disabled:opacity-50'
        disabled={selectedIndex === availableDates.length-1}
        onClick={handleNext}
      >
        <ChevronRight size={16}/>
      </button>
      <div className="w-[200px] h-full overflow-hidden relative bg-no-repeat">
        <div className="absolute inset-x-0 top-1/2 border-t border-amz3green-100 opacity-30" />
        <div
          className='relative flex flex-row gap-2 h-full items-center'
          style={{
            ['--index' as string]: `${100-(selectedIndex*16)}px`,
            transform: "translate(var(--index),0)",
            transition: "transform 0.5s",
          }}
        >
        {availableDates.map((date, index)=>{
          return(
            <button
              key={index}
              className={twMerge(
                "bg-amz3green-100 w-2 h-2 rounded-full shrink-0", 
                selectedIndex!==index && 'bg-amz3green-200 hover:bg-amz3green-100',
                selectedIndex===index && 'scale-125'
              )}
              onClick={()=>handlePoint(index)}
            ></button>
          )
        })}
        </div>
      </div>
    </div>
  )
}
