import axios from 'axios'
import { authStore } from '../store/useAuth/use-auth'
import { loadingStore } from '../store/useLoading/use-loading'
import { ROUTES_PATH } from '../routes'
import i18n from '../i18n'


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
api.interceptors.request.use(
  async config => {
    const token = authStore.getState().token

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      loadingStore.setState({ isLoading: true })
    }
    return config
  },
  error => {
    loadingStore.setState({ isLoading: false })
    return Promise.reject(error)
  }
)

api.interceptors.request.use(
  config => {
    config.headers.locale = `${i18n.language}`
    loadingStore.setState({ isLoading: true })
    return config
  },
  error => {
    loadingStore.setState({ isLoading: false })
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  async response => {
    loadingStore.setState({ isLoading: false })
    return response
  },
  async error => {
    loadingStore.setState({ isLoading: false })

    if (error?.response?.status === 401) {
      if(error.config.url !== 'accounts/refresh-token') {
        await refreshToken()
        window.location.href = ROUTES_PATH.CART.path
      return api.request(error.config)
      }else {
        window.location.href = ROUTES_PATH.SIGN_IN.path
      }
    }

    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    loadingStore.setState({ isLoading: false })

    return response
  },
  error => {
    loadingStore.setState({ isLoading: false })

    if (error?.response?.status === 403) {
      // console.log('unauthorized', error?.response?.status)
      window.location.href = ROUTES_PATH.COMPLETE_SIGN_UP.path
    }

    return Promise.reject(error)
  }
)

async function refreshToken() {
  authStore.setState({ token: '' })
  await (api.get<{ access_token: string }>('accounts/refresh-token',
    {
      withCredentials: true
    }
  ).then(response => {
    const { access_token } = response.data
    if (access_token) {
      authStore.setState({ token: access_token })
    }
  })
    .catch(error => {
      console.log('refresh token error', error)
      window.location.href = ROUTES_PATH.SIGN_IN.path
      return Promise.reject(error)
    }))
}




export { api }
