import { z } from 'zod'
import i18n from '../../i18n'

export let checkoutFormSchema: z.ZodObject<
  {
    wallet: z.ZodString
    currency: z.ZodEnum<['BRL', 'USD', 'SOL']>
  },
  'strip',
  z.ZodTypeAny,
  {
    wallet: string
    currency: 'BRL' | 'USD' | 'SOL'
  },
  {
    wallet: string
    currency: 'BRL' | 'USD' | 'SOL'
  }
>

i18n.on('languageChanged', language => {
  checkoutFormSchema = z.object({
    wallet: z.string(),
    currency: z.enum(['BRL', 'USD', 'SOL'], {
      errorMap: () => ({
        message: i18n?.t('checkout.form-error-messages.currency', {
          lng: language
        })
      })
    })
  })
})

export type CheckoutFormProps = z.infer<typeof checkoutFormSchema>
