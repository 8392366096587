import { useQuery } from 'react-query'
import { CurrencyProps } from '../../store/useCart'
import { api } from '../api'

export async function getCurrencyValue(
  currency: CurrencyProps
): Promise<number> {
  const { data: currencyValue } = await api.get(
    `/orders/currency-exchange/${currency}`
  )
  return currencyValue
}

export function useCurrencyExchange(currency: CurrencyProps) {
  return useQuery('currency-exchange', () => getCurrencyValue(currency), {
    staleTime: 1000 * 60 * 60
  }) //1 hra
}
