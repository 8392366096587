import React, { ClipboardEvent, KeyboardEvent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type VerificationCodeInputProps = {
  onChange: (code: string) => void
  className?: string
}

export function VerificationCodeInput({
  onChange,
  className
}: VerificationCodeInputProps) {
  const [inputs, setInputs] = useState(['', '', '', '', '', ''])

  const handleChange = (index: number, value: string) => {
    const newInputs = [...inputs]
    newInputs[index] = value.slice(0, 1)
    setInputs(newInputs)

    if (index < 5 && value) {
      document.getElementById(`code-${index + 1}`)?.focus()
    }

    if (newInputs.every(input => input)) {
      onChange(newInputs.join(''))
    }
  }

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && inputs[index] === '') {
      const previousIndex = index - 1
      if (previousIndex >= 0) {
        document.getElementById(`code-${previousIndex}`)?.focus()
      }
    }
  }

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()

    const paste = event.clipboardData.getData('text').split('').slice(0, 6)
    if (paste.length === 6) {
      setInputs(paste)
      document.getElementById(`code-${paste.length - 1}`)?.focus()
      onChange(paste.join(''))
    }
  }

  return (
    <div onPaste={handlePaste} className="grid grid-cols-6 gap-4">
      {inputs.map((value, index) => (
        <input
          key={index}
          id={`code-${index}`}
          value={value}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          maxLength={1}
          type="text"
          className={twMerge(
            'h-12 rounded-2lg border border-amz3green-50 bg-transparent text-center text-amz3white-50 text-base placeholder:text-amz3white-50 placeholder:text-base focus:outline-none',
            className
          )}
          placeholder="_"
          autoComplete="off"
        />
      ))}
    </div>
  )
}

export default VerificationCodeInput
