import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import { Submit as RadixSubmit } from '@radix-ui/react-form'
import { twMerge } from 'tailwind-merge'
import { Spin } from '../../Spin'

type SubmitProps = {
  isSubmitting: boolean
  children: ReactNode
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export function Submit({
  isSubmitting,
  className,
  children,
  ...props
}: SubmitProps) {
  return (
    <RadixSubmit asChild>
      <button
        type="submit"
        disabled={isSubmitting}
        className={twMerge(
          'bg-amz3green-100 hover:bg-amz3green-50 mt-4 rounded-2lg w-full h-[4.25rem] flex justify-center items-center gap-4 text-[#1B1B1B] transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in',
          className
        )}
        {...props}
      >
        {isSubmitting ? <Spin /> : children}
      </button>
    </RadixSubmit>
  )
}
