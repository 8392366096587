import { useQuery } from 'react-query'
import { isAxiosError } from 'axios'
import { toast } from 'react-toastify'
import { api } from '../api'
import {
  ChangeProfileErrorResponse,
  MessageTypeProps
} from '../../pages/Dashboard/pages/ChangeProfile/types'
import i18n from '../../i18n'

export type GetProfileResponseProps = {
  address: Address
  id: string
  cpf: string
  email: string
  name: string
  phoneDdi: string
  phoneNumber: string
} | null

type Address = {
  city: string
  complement: string
  countryCode: string
  number: string
  state: string
  street: string
  zipCode: string
}

export async function getProfile(): Promise<GetProfileResponseProps> {
  
  try {
    const { data: profile } = await api.get<GetProfileResponseProps>(
      '/accounts/me'
    )
    return profile
  } catch (error) {
    if (isAxiosError<ChangeProfileErrorResponse>(error)) {
      const errorMessage = error?.response?.data?.messages

      if (errorMessage) {
        errorMessage.forEach(message => {
          toast.error(
            message?.Message[i18n?.language as keyof MessageTypeProps] ||
              i18n?.t('dashboard.change-profile.form.request-error'),
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            }
          )
        })
      }
      return null
    }
    // console.log({ error })
    return null
  }
}

export async function getAvatar(): Promise<string> {
  try {
    
    const { data: avatar } = await api.get('/accounts/get-avatar', {
      responseType: 'blob'
    })
    const url = URL.createObjectURL(avatar)
    return url
  } catch (error) {
    if (isAxiosError<ChangeProfileErrorResponse>(error)) {
      const errorMessage = error?.response?.data?.messages

      if (errorMessage) {
        errorMessage.forEach(message => {
          toast.error(
            message?.Message[i18n?.language as keyof MessageTypeProps] ||
              i18n?.t('dashboard.change-profile.form.request-error'),
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            }
          )
        })
      }
      return ''
    }
    // console.log({ error })
    return ''
  }
}

export function useProfile(hasToken = true) {
  return useQuery('profile-info', hasToken ? () => getProfile() : () => null, {
    staleTime: 1000 * 60 * 60, //1 hra
    retry: 0
  })
}

export function useAvatar(hasToken = true) {
  return useQuery('avatar-info', hasToken ? () => getAvatar() : () => '', {
    staleTime: 1000 * 60 * 60, //1 hra
    retry: 0
  })
}
