export const revealSlideUp = (isInView:boolean, duration = 1, delay = 0) => ({
  transform: isInView ? "none" : "translateY(100px)",
  opacity: isInView ? 1 : 0,
  transition: `opacity ${duration}s ${delay}s, transform ${duration}s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay}s`
})

export const revealZoomOut = (isInView:boolean, duration = 1, delay = 0, scale = 1.2) => ({
  opacity: isInView ? 1 : 0,
  transform: isInView ? `scale(1)` : `scale(${scale})`,
  transition: `opacity ${duration}s ${delay}s, transform ${duration}s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay}s`
})

export const revealFade = (isInView:boolean, duration = 1, delay = 0) => ({
  opacity: isInView ? 1 : 0,
  transition: `opacity ${duration}s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay}s`
})