import { z } from 'zod'
import i18n from '../../../../i18n'

export let changeProfileFormSchema: z.ZodEffects<
  z.ZodObject<
    {
      name: z.ZodString
      email: z.ZodString
      country: z.ZodString
      zipCode: z.ZodString
      'first-address': z.ZodString
      number: z.ZodString
      'second-address': z.ZodOptional<z.ZodString>
      complement: z.ZodOptional<z.ZodString>
      city: z.ZodString
      state: z.ZodString
      cpf: z.ZodOptional<z.ZodString>
      phone: z.ZodString
      instagram: z.ZodOptional<z.ZodString>
      facebook: z.ZodOptional<z.ZodString>
      discord: z.ZodOptional<z.ZodString>
      linkedIn: z.ZodOptional<z.ZodString>
    },
    'strip',
    z.ZodTypeAny,
    {
      number: string
      name: string
      email: string
      country: string
      zipCode: string
      'first-address': string
      'second-address'?: string | undefined
      complement?: string | undefined
      city: string
      state: string
      phone: string
      cpf?: string | undefined
      instagram?: string | undefined
      facebook?: string | undefined
      discord?: string | undefined
      linkedIn?: string | undefined
    },
    {
      number: string
      name: string
      email: string
      country: string
      zipCode: string
      'first-address': string
      'second-address'?: string | undefined
      complement?: string | undefined
      city: string
      state: string
      phone: string
      cpf?: string | undefined
      instagram?: string | undefined
      facebook?: string | undefined
      discord?: string | undefined
      linkedIn?: string | undefined
    }
  >,
  {
    number: string
    name: string
    email: string
    country: string
    zipCode: string
    'first-address': string
    'second-address'?: string | undefined
    complement?: string | undefined
    city: string
    state: string
    phone: string
    cpf?: string | undefined
    instagram?: string | undefined
    facebook?: string | undefined
    discord?: string | undefined
    linkedIn?: string | undefined
  }
>

i18n.on('languageChanged', language => {
  changeProfileFormSchema = z
    .object({
      name: z.string().min(2, {
        message: i18n?.t('dashboard.change-profile.form.name.error-message', {
          lng: language
        })
      }),
      email: z.string().email({
        message: i18n?.t('dashboard.change-profile.form.email.error-message', {
          lng: language
        })
      }),
      country: z.string().min(2, {
        message: i18n?.t(
          'dashboard.change-profile.form.country.error-message',
          {
            lng: language
          }
        )
      }),
      zipCode: z.string().min(2, {
        message: i18n?.t(
          'dashboard.change-profile.form.zipCode.error-message',
          {
            lng: language
          }
        )
      }),
      'first-address': z.string().min(2, {
        message: i18n?.t(
          'dashboard.change-profile.form.first-address.error-message',
          { lng: language }
        )
      }),
      number: z.string().min(1, {
        message: i18n?.t('dashboard.change-profile.form.number.error-message', {
          lng: language
        })
      }),
      'second-address': z.string().optional(),
      complement: z.string().optional(),
      city: z.string().min(2, {
        message: i18n?.t('dashboard.change-profile.form.city.error-message', {
          lng: language
        })
      }),
      state: z.string().min(2, {
        message: i18n?.t('dashboard.change-profile.form.state.error-message', {
          lng: language
        })
      }),
      cpf: z.string().optional(),
      phone: z.string().min(2, {
        message: i18n?.t('dashboard.change-profile.form.phone.error-message', {
          lng: language
        })
      }),
      instagram: z.string().optional(),
      facebook: z.string().optional(),
      discord: z.string().optional(),
      linkedIn: z.string().optional()
    })
    .refine(
      data => {
        if (
          (data.country.includes('Brasil') ||
            data.country.includes('Brazil')) &&
          (!data.cpf || data.cpf.trim() === '')
        ) {
          return false
        }
        return true
      },
      {
        message: i18n?.t('dashboard.change-profile.form.cpf.error-message', {
          lng: language
        }),
        path: ['cpf']
      }
    )
})

export type ChangeProfileFormProps = z.infer<typeof changeProfileFormSchema>

export interface ChangeProfileErrorResponse {
  messages: MessageProps[]
}

export interface MessageProps {
  Item: string
  Message: MessageTypeProps
}

export type MessageTypeProps = {
  pt: string
  en: string
}

export type TChangeUserBody = {
  id: string
  name: string
  locale?: string
  cpf?: string
  phoneNumber: string
  phoneDdi: string
  email: string
  password?: string
  hadConsent?: boolean
  address: {
    street: string
    number: string
    neighborhood?: string
    city: string
    state: string
    countryCode: string
    zipCode: string
    complement?: string
  }
}
