import { MapNFT } from "../../../../../models/map-nft";

export const SECONDS_IN_YEAR = 365 * 24 * 60 * 60;

export function flipNumberFormat(x:number) {
  return Math.floor(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function calculateInitialFlip(nft:MapNFT, ratePerMeter:number) {
  const now = new Date();
  const soldDate = new Date(nft.soldDate);
  const secondsSinceSold = (now.getTime() - soldDate.getTime()) / 1000;
  const yearsSinceSold = secondsSinceSold / SECONDS_IN_YEAR;
  return (nft.areaInSquareMeters * ratePerMeter * yearsSinceSold);
}