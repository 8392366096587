import { create, StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'
import { api } from '../../services/api'

type AuthState = {
  token: string
  email: string
  setToken: (token: string) => void
  setEmail: (email: string) => void
  signOut: () => void
}

type PersistProps = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>

export const authStore = create<AuthState>(
  (persist as PersistProps)(
    (set:any, get:any) => {
      return {
        token: '',
        email: '',
        setEmail: (email: string) =>
          set(() => ({
            email
          })),
        setToken: (token: string) =>
          set(() => ({
            token
          })),
        signOut: () =>{
          api.get('accounts/sign-out',{withCredentials: true})
          .then(()=>{
            set(() => ({
              token: '',
              email: ''
            }))
          })
          .catch((error)=>{
            console.error(error)
            set(() => ({
              token: '',
              email: ''
            }))
          })
        }
      }
    },
    {
      name: 'auth-storage'
    }
  )
)

export const useAuth = authStore
