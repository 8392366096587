import { useQuery } from 'react-query'
import { api } from '../api'

export type GetNFTOwnerResponseProps = {
  owner: string
  nftToken: string
  projectAreaId: string
} | null

export async function getNFTOwner(
  projectAreaId: string
): Promise<GetNFTOwnerResponseProps> {
  try {
    const { data: nftOwner } = await api.get<GetNFTOwnerResponseProps>(
      `/nfts/nft-owner/${projectAreaId}`
    )
    return nftOwner
  } catch (error) {
    // console.log(error)
    return null
  }
}

export function useNFTOwner(projectAreaId: string) {
  return useQuery('nft-owner', () => getNFTOwner(projectAreaId), {
    staleTime: 1000 * 60 * 60, //1 hra
    retry: 0
  })
}
