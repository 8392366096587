import { useTranslation } from 'react-i18next'
import { FooterButton } from './FooterButton'
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg'
import { ReactComponent as Discord } from '../../assets/icons/discord.svg'

export function Footer() {
  const { t } = useTranslation()

  return (
    <footer className="mt-auto flex flex-col items-center lg:flex-row lg:gap-4">
      <h1 className="text-amz3white-50 text-center">{t('footer.follow-us')}</h1>
      <div className="mt-4 flex gap-4 md:mt-0">
        <FooterButton to="https://discord.com/invite/vfpHHjG2" target="_blank">
          <Discord className="fill-amz3black-200" />
          Discord
        </FooterButton>
        <FooterButton to="https://t.me/AmazonTree" target="_blank">
          <Telegram className="fill-amz3black-200" />
          Telegram
        </FooterButton>
      </div>
    </footer>
  )
}
