import { z } from 'zod'

export const registerCustomerFormSchema = z.object({
  name: z.string().min(3, { message: 'É necessário informar seu nome.' }),
  cpf: z.string().min(11, { message: 'É necessário informar seu cpf.' }),
  email: z.string().email({ message: 'É necessário informar seu e-mail.' }),
  cep: z.string().min(3, { message: 'É necessário informar seu CEP.' }),
  address: z
    .string()
    .min(3, { message: 'É necessário informar seu endereço.' }),
  number: z
    .string()
    .min(2, { message: 'É necessário informar seu número ou SN.' }),
  complement: z.string().nullable(),
  phone: z.string().min(11, { message: 'É necessário informar seu telefone.' }),
  password: z.string().min(2, { message: 'É necessário informar sua senha.' })
})

export type RegisterCustomerFormProps = z.infer<
  typeof registerCustomerFormSchema
>
