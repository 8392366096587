import { useTranslation } from "react-i18next";
import { MapNFT } from "../../../../../models/map-nft";
import exampleGraphSrc from "./example-graph.svg";
import { interpolate } from "../../../../../utils/i18n-util";
import { FlipField } from "./flip-field";
import { HelpDialog } from "./help-dialog";
import { RATE_PER_METER } from "./constants";

type Props = {
  nft: MapNFT;
}

function daysSinceSold(soldDate: Date): number {
  const soldDateObj = new Date(soldDate);
  const currentDate = new Date();
  const differenceMs = Math.abs(currentDate.getTime() - soldDateObj.getTime());
  return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
}

export function SidebarInfo({nft}:Props) {

  const { t } = useTranslation()
  
  return (
    <div className="flex flex-col gap-2">
      <div className="text-amz3green-50 font-bold">
        {nft && interpolate(
          t('dashboard.conservation-data.sidebar.header'), 
          {days: daysSinceSold(nft.soldDate), date: nft.soldDate.toString().split('T')[0]}
        )}
      </div>
      <dl>
       <dt className="text-amz3green-100 font-bold">{t("dashboard.conservation-data.biomass")}</dt> 
       <dd className="text-amz3green-50">
        <FlipField nft={nft} ratePerMeter={RATE_PER_METER.biomass} suffix="m²"/>
       </dd>
      </dl>
      <dl>
       <dt className="text-amz3green-100 font-bold">{t("dashboard.conservation-data.vapor")}</dt> 
       <dd className="text-amz3green-50">
        <FlipField nft={nft} ratePerMeter={RATE_PER_METER.vapor} suffix="m²"/>
       </dd>
      </dl>
      <dl>
       <dt className="text-amz3green-100 font-bold">{t("dashboard.conservation-data.carbon")}</dt> 
       <dd className="text-amz3green-50">
        <FlipField nft={nft} ratePerMeter={RATE_PER_METER.carbon} suffix="m²"/>
       </dd>
      </dl>
      <div className="flex flex-col gap-2">
        <p className="text-amz3green-100 font-bold grow">
          {t("dashboard.conservation-data.sidebar.ndvi")}
          <span className="relative top-[4px] ml-2"><HelpDialog help={{
            title: t("dashboard.conservation-data.sidebar.ndvi"),
            text: t("dashboard.conservation-data.help.ndvi"),
          }}/></span>
        </p>
        <img src={exampleGraphSrc} alt="Example Graph" />
      </div>
      {/* <button className="text-amz3green-100 font-bold rounded-md border-amz3green-100 border-[1px] border-solid p-2 mt-4">
        {t("dashboard.conservation-data.sidebar.download-report")}
      </button> */}
    </div>
  )

}