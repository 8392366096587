import { Avatar, Box, Card, Flex, Text } from '@mantine/core'
import { Calendar2 as Calendar2Icon, Ruler as RulerIcon } from 'iconsax-react'
import { RescueButton } from '../../components/RescueButton'
import { TNft } from '../../services/useOrders/types';

type TOrdersMobileItem = {
  nft: TNft,
  currency: string
}

export function OrdersMobileItem({ nft, currency }: TOrdersMobileItem) {

  return (
    <Card withBorder bg="dark.4" mt="xs">
      <Flex gap="lg">
        <Avatar
          className="[&>img]:scale-125"
          radius="sm"
          size="6rem"
          src={nft.image}
          mb="xs"
        >
          AMZ
        </Avatar>
        <Flex direction="column" gap="xs" c="gray.2">
          <Text size="sm" fw={700}>
            {nft.name}
          </Text>
          <Text size="sm">
            {!!currency
              ? `${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: currency
              }).format(nft.price)}`
              : nft.price}
          </Text>
        </Flex>
      </Flex>
      <Flex my="xs" gap="md">
        <Box
          bg="gray.2"
          className="flex items-center gap-1 rounded-full py-1 px-2 text-xs w-full"
        >
          <RulerIcon size={14} />
          <Text size="xs" mx="auto">
            {nft.areaInSquareMeters}m²
          </Text>
        </Box>
        <Box
          bg="gray.2"
          className="flex items-center gap-1 rounded-full py-1 px-2 text-xs w-full"
        >
          <Calendar2Icon size={14} />
          <Text size="xs" c="dark.8" mx="auto">
            {nft?.edition}
          </Text>
        </Box>
      </Flex>
      <RescueButton transferredToUserWallet={nft.transferredToUserWallet ?? false} small nftToken={nft?.nftToken || ''} />
    </Card>
  )
}
