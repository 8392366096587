import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import Figure from '../../../assets/v2/figure-amazon-2.jpg'
import FigureTokens from '../../../assets/v2/tokens-1.png'
import { revealZoomOut, revealFade } from "../../../motion";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from '../../../routes'

export function SectionCollection () {
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });

  const refImage = useRef(null);
  const isInViewImage = useInView(refImage, { once: true, amount: .3 });
  
  const { t } = useTranslation()
  
  return (
    <section
      ref={ref}
      className={cx(
        'lg:min-h-[80dvh] bg-black',
        'grid grid-cols-1 lg:grid-cols-2',
        'relative overflow-hidden'
      )}
      style={{
        background: `url(${Figure}) center center no-repeat fixed`,
        backgroundSize: 'cover',
        ...revealFade(isInView, 2)
      }}

    >
      <div className='w-full h-full absolute bg-black/30'></div>
      <div ref={refImage} className='lg:order-last relative px-20 pt-20 flex flex-col justify-center items-center'>
        <img style={revealZoomOut(isInViewImage, 2, 0.5)} alt="AMZ3 Tokens" src={FigureTokens} className='w-[540px] h-auto max-w-full'/>
      </div>
      <div className='relative p-10 py-20 md:p-20 text-white [&>p]:mb-4'>
        <h3 className='text-6xl font-semibold mb-8'>
          <span className='text-amz3green-100'>{t('about-token.collection.title')}</span>{' '}
          <span className='text-amz3green-50'>{t('about-token.collection.subtitle')}</span>
        </h3>
        <p>{t('about-token.collection.first-paragraph')}</p>
        <p>{t('about-token.collection.second-paragraph')}</p>
        <p className='mt-8'>
          <Link
            className='button-green'
            to={ROUTES_PATH.CATALOG.path}
          >
            {t('about-token.collection.cta')}
          </Link>
        </p>
      </div>
    </section>
  )
}