import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { BitcoinCard } from 'iconsax-react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { twMerge } from 'tailwind-merge'
import { api } from '../../services/api'
import { useTranslation } from 'react-i18next'

type AddWalletProps = {
  className?: string
  classNameText?: string
  children?: ReactNode
}

type VisitorsResponseProps = {
  wallet: string
  date: string
}

export function AddWallet({
  className,
  classNameText,
  children
}: AddWalletProps) {
  const { connected, publicKey, wallet } = useWallet()
  const { t } = useTranslation()

  const walletPublicKey = useMemo(() => {
    const base58 = publicKey?.toBase58() || ''
    return base58.slice(0, 4) + '..' + base58.slice(-4)
  }, [publicKey])

  const addVisitor = useCallback(async (walletPublicKey: string) => {
    await api.post<VisitorsResponseProps>('/visitors', {
      wallet: walletPublicKey
    })
  }, [])

  useEffect(() => {
    if (connected && publicKey) {
      addVisitor(publicKey?.toBase58())
    }
  }, [connected, publicKey, addVisitor])

  return (
    <WalletMultiButton
      className={twMerge(
        '!p-0 !rounded-2lg !h-14 !w-14 !flex !items-center !justify-center xl:!w-[170px] xl:!gap-2 xl:!font-medium hover:!bg-amz3green-150 hover:!bg-opacity-25 !transition-all !duration-200 !ease-in leave:!opacity-0 leave:!duration-200 leave:!ease-in [&>i]:mr-0',
        wallet && !connected && '[&>i]:hidden',
        className
      )}
      style={{ border: '1px solid #05EB35' }}
    >
      {children ? (
        children
      ) : (
        <>
          <span
            className={twMerge(
              '!hidden !text-amz3green-150 xl:!flex xl:items-center xl:h-full',
              classNameText
            )}
          >
            {connected ? walletPublicKey : t('wallet')}
          </span>
          {!connected && (
            <BitcoinCard size="24" className="!text-amz3green-150" />
          )}
        </>
      )}
    </WalletMultiButton>
  )
}
