import { useCallback, useEffect, useRef, useState } from 'react'
import { isAxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
// import { ToastContainer, toast } from 'react-toastify'
import { toast } from 'react-toastify'
import { SecondStep } from './SecondStep'
import BackgroundTree from '../../assets/images/background-solutions.png'
import {
  SignUpErrorResponse,
  SignUpPayloadProps,
} from './types'
import { api } from '../../services/api'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes'
import { usePrivacyPolicy } from '../../store/usePrivacyPolicy'
import i18n from '../../i18n'
import { NotificationResponse } from '../../models/notification-response'
import { useInView } from 'framer-motion'
import { revealFade } from '../../motion'
import { useAuth } from '../../store'

export function CompleteSignUp() {
  const { token } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true, amount: .3 });
  const { isPrivacyPolicyOpen } = usePrivacyPolicy()
  const [payload, setPayload] = useState<SignUpPayloadProps>({
    name: '',
    password: '',
    cpf: '',
    phoneNumber: '',
    phoneDdi: '',
    address: {
      street: '',
      number: '',
      city: '',
      state: '',
      countryCode: '',
      neighborhood: '',
      zipCode: '',
      complement: ''
    },
    email: ''
  })
  const [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    if (!token) {
      navigate('/sign-in')
    }
  }, [navigate, token])

  const handleSecondStep = useCallback(
    async (data: SignUpPayloadProps) => {
      const locale = `${i18n.language}`
      try {
        const newPayload: SignUpPayloadProps = {
          ...payload,
          ...data,
          hadConsent: !isPrivacyPolicyOpen
        }
        delete newPayload.address?.description
        setPayload(prevState => ({ ...prevState, ...data }))
        const { data: notification } = await api.post<NotificationResponse>(
          'accounts/complete-sign-up',
          newPayload
        )

        // console.log(newPayload)



        if (notification) {
          console.log(notification)

          const { messages } = notification

          const { Complement, Message } = messages[0]

          toast.success(locale === "en"
            ? Message.en
            : Message.pt, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })

          navigate(
            `${ROUTES_PATH.VERIFY_PHONE.path}?token=${Complement.token}`
          )

        }
      } catch (error) {
        if (isAxiosError<SignUpErrorResponse>(error)) {
          const errorMessages = error?.response?.data?.messages

          if (errorMessages?.length) {
            errorMessages.forEach(errorMessage => {
              toast.error(locale === "en"
                ? errorMessage.Message.en
                : errorMessage.Message.pt, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: 'dark'
              })
            })
          } else {
            toast.error(t('sign-up.request-error'), {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })
          }
        } else {
          toast.error(t('sign-up.request-error'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      }
    },
    [isPrivacyPolicyOpen, navigate, payload, t]
  )

  const handleClearCountry = () => setRefreshKey((prev) => prev + 1)

  return (
    <>
      <script>fbq('track', 'CompleteRegistration');</script>
      <section
        id={ROUTES_PATH.COMPLETE_SIGN_UP.path}
        ref={ref}
        style={{
          // @ts-ignore
          '--bg-image-url': `url(${BackgroundTree})`,
          ...revealFade(isInView, 3)
        }}
        className={`bg-amz3black-500 bg-none min-h-full md:bg-[image:var(--bg-image-url)] md:bg-no-repeat md:bg-cover md:bg-center flex items-center justify-center`}
      >
        <div className="relative w-[1200px] max-w-[95%] text-gray-200 bg-amz3black-500 p-10 rounded-lg">
          <div className='lg:grid lg:grid-cols-2'>
            <div className='flex flex-col justify-center items-center p-16'>
              <div className='text-3xl'>
                <span className='text-amz3green-50' >{t('sign-up.title1')}</span>{' '}
                <span className='text-amz3green-100'>{t('sign-up.title2')}</span>
              </div>

              <div className='text-amz3green-50 text-base mt-8'>
                {t('sign-up.body1')}
              </div>
            </div>
            <SecondStep
              key={refreshKey}
              payload={payload}
              handleClearCountry={handleClearCountry}
              onSubmit={handleSecondStep}
            />
          </div>
        </div>
      </section>
    </>
  )
}
