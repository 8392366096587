import { PublicKey } from '@solana/web3.js'

export function goToSolscanAccount(key: string | PublicKey) {
  return `https://solscan.io/account/${key}${
    process.env.REACT_APP_SOLSCAN_NETWORK !== 'mainnet-beta'
      ? `?cluster=${process.env.REACT_APP_SOLSCAN_NETWORK}`
      : ''
  }`
}

export function goToSolscanToken(key: string | PublicKey) {
  return `https://solscan.io/token/${key}${
    process.env.REACT_APP_SOLSCAN_NETWORK !== 'mainnet-beta'
      ? `?cluster=${process.env.REACT_APP_SOLSCAN_NETWORK}`
      : ''
  }`
}
