import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import { AM3Logo } from '../AM3Logo'

type NFTCardProps = {
  name: string
  image: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function NFTCard({ className, name, image, ...props }: NFTCardProps) {
  return (
    <div
      className={twMerge(
        'rounded-[20px] backdrop-blur flex flex-col items-center bg-[#00000020] p-5',
        className
      )}
      {...props}
    >
      <img src={image} alt="" className="w-[210px] h-[210px]" />
      <span className="text-amz3green-50 text-2xl font-bold mt-5">
        {name?.split('#')[0]}
      </span>
      <div className="flex items-center">
        <AM3Logo className="w-[27px] h-[27px]" />
        <span className="text-amz3white-50 font-medium text-2xl ">
          #{name?.split('#')[1]}
        </span>
      </div>
    </div>
  )
}
