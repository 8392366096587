import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import { revealZoomOut, revealSlideUp, revealFade } from "../../../motion";
import { useTranslation } from "react-i18next";

export function SectionWelcome () {
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const { t } = useTranslation()

  function openVideo(){
    const videoLightbox = window.GLightbox({
      elements: [
        {
          'href': t('home.welcome.video'),
          'type': 'video',
          'source': 'youtube',
          'width': 900,
        } 
      ],
      autoplayVideos: true,
    });
    videoLightbox.open();
  }
  
  return (
    <section
      ref={ref}
      className={cx(
        'relative w-full overflow-hidden lg:min-h-[80dvh] bg-amz3green-300',
        'p-16 grid md:grid-cols-2 lg:grid-cols-3 grid-rows-2'
      )}
    >
        <div
          className='w-full h-full absolute top-0 left-0 bg-black'
          style={{...revealFade(isInView,3)}}
        >
          <video
            poster="/video/video-amazontree-home.jpg"
            style={{imageRendering: 'pixelated'}}
            autoPlay muted loop playsInline
            className='w-full h-full object-cover opacity-50'
          >
            <source src="/video/video-amazontree-home.mp4"/>
          </video>
        </div>
        <div className='relative row-start-2 shrink-1'>
            <h3
              style={revealSlideUp(isInView, 1, 0.5)}
              className='text-3xl md:text-4xl '
            >
              <span className='text-amz3green-100'>{t('home.welcome.line1')}</span>{` `}
              <span className='text-amz3green-50'>{t('home.welcome.line2')}</span>
            </h3>
            <button
              style={revealZoomOut(isInView, 3, 3)}
              onClick={openVideo}
              className='button-green'
            >
              <span className='mr-4'>{'\u25BA'}</span>
              {t('home.welcome.video-label')}
            </button>
        </div>
      </section>
  )
}