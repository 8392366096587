import FlipNumbers from "react-flip-numbers";
import { useEffect, useState } from "react";
import { MapNFT } from "../../../../../models/map-nft";
import { calculateInitialFlip, flipNumberFormat, SECONDS_IN_YEAR } from "./flip-util";

interface Props {
  nft: MapNFT,
  ratePerMeter: number,
  suffix: string,
}

export const FlipField = ({nft, ratePerMeter, suffix}:Props) => {

  const [carbonCaptured, setCarbonCaptured] = useState<number>(0);

  useEffect(() => {

    if(!nft) return;

    const initialCarbonCapture = calculateInitialFlip(nft, ratePerMeter);
    const carbonCaptureRate = nft.areaInSquareMeters * ratePerMeter / SECONDS_IN_YEAR;

    setCarbonCaptured(initialCarbonCapture ?? 0);

    const interval = setInterval(() => {
      setCarbonCaptured(prevCapture => prevCapture + (carbonCaptureRate ?? 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [nft, ratePerMeter]);

  return (
    <div className="text-amz3green-50 text-sm font-bold [&>section]:!justify-start">
      <FlipNumbers
        height={14}
        width={12}
        color="#fcf2c8"
        play
        perspective={400}
        numbers={`${flipNumberFormat(carbonCaptured)} ${suffix}`}
      />
    </div>
)}