import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import Figure from '../../../assets/v2/graphics-1.svg'
import { revealSlideUp, revealFade } from '../../../motion'
import { useTranslation } from 'react-i18next';

export function SectionPayment () {

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const isInView1 = useInView(ref1, { once: true, amount: .3 });
  const isInView2 = useInView(ref2, { once: true, amount: .3 });
  const { t } = useTranslation()

  return (
    <section
      className={cx(
        'lg:min-h-[80dvh] grid grid-cols-1 md:grid-cols-2',
        'bg-amz3green-300 text-amz3earth-light'
      )}
      style={revealFade(isInView1,3)}
    >
        <div ref={ref1} className='opacity-50'
            style={{
              background: `url(${Figure}) center center no-repeat fixed`,
              backgroundSize: "cover",
            }}
          >
            <div className='w-full h-full bg-black/30'></div>
          </div>
        <div ref={ref2} className='p-10 py-20 md:p-20 bg-amz3earth-light text-amz3black-300'>
          <h2 className="text-amz3green-200">{t('about-token.payment.title')}</h2>
          <h3 className='text-4xl text-white mb-8 font-semibold [&>span]:block'>
            <span className="text-amz3green-200" style={revealSlideUp(isInView2, 1, 0.5)}>{t('about-token.payment.subtitle1')}</span>
            <span className="text-amz3green-300" style={revealSlideUp(isInView2, 1, 1)}>{t('about-token.payment.subtitle2')}</span>
          </h3>
          <div style={revealFade(isInView2, 4, 2.0)} className='[&>p]:mb-4'>
            <p>{t('about-token.payment.first-paragraph')}</p>
            <p>{t('about-token.payment.second-paragraph')}</p>
          </div>
        </div>
      </section>
  )
}