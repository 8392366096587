import { Accordion, Flex, Text } from '@mantine/core'
import { OrderToPrint } from './orderToPrint'
import { useOrders } from '../../services'
import { OrdersMobileItem } from './orders-mobile-item'
import { CustomBadge } from '../../components/CustomBadge'

export function OrdersMobile() {
  const { data: ordersItems } = useOrders()
  const modifiedId = (id: string) => id.slice(0, 4) + '...' + id.slice(-4)
  console.log({ordersItems})

  return (
    <Flex
      display={{ base: 'flex', md: 'none' }}
      direction="column"
      gap="md"
      mt="xl"
    >
      {ordersItems?.map(orderItem => (
        <Accordion key={orderItem.id} variant="separated">
          <Accordion.Item value={orderItem.id} bg="dark.6">
            <Accordion.Control c="gray.2">
              <Flex align="center" justify="space-between">
                <Text size="xs">{modifiedId(orderItem?.orderHash)}</Text>
                <Flex align="center" gap="6px" mx="6px">
                  <CustomBadge orderStatus={orderItem.orderStatus}/>
                  <OrderToPrint small />
                  <Text size="xs">
                    {!!orderItem?.currency
                      ? `${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: orderItem.currency
                        }).format(orderItem.amount)}`
                      : orderItem.amount}
                  </Text>
                </Flex>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              {orderItem.nft.map((nft, idx) => (
                <OrdersMobileItem
                  nft={nft}
                  key={idx}
                  currency={orderItem.currency}
                />
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ))}
    </Flex>
  )
}
