export const ROUTES_PATH = {
  HOME: {
    id: 'home',
    path: '/'
  },
  TOKEN: {
    id: 'token',
    path: '/token'
  },
  CERTIFICATE: {
    id: 'certificate',
    path: '/certificate'
  },
  CART: {
    id: 'cart',
    path: '/cart'
  },
  CATALOG: {
    id: 'catalog',
    path: '/catalog'
  },
  MARKETPLACE: {
    id: 'marketplace',
    path: '/marketplace'
  },
  CHECKOUT_SUCCESS: {
    id: 'amz-checkout-success',
    path: '/amz-checkout-success'
  },
  CHECKOUT_FAIL: {
    id: 'amz-checkout-fail',
    path: '/amz-checkout-fail'
  },
  PROBLEMS: {
    id: 'problems',
    path: '/#problems'
  },
  ROADMAP: {
    id: 'roadmap',
    path: '/#roadmap'
  },
  ABOUT: {
    id: 'about',
    path: '/'
  },
  FAQ: {
    id: 'faq',
    path: '/#faq'
  },
  CONTACT: {
    id: 'contact-us',
    path: '/#contact-us'
  },
  HOW_TO_BUY: {
    id: 'how-to-buy',
    path: '/marketplace/#how-to-buy'
  },
  SIGN_IN: {
    id: 'sign-in',
    path: '/sign-in'
  },
  SIGN_UP: {
    id: 'sign-up',
    path: '/sign-up'
  },
  COMPLETE_SIGN_UP: {
    id: 'complete_sign-up',
    path: '/complete_sign-up'
  },
  NOTIFICATION: {
    id: 'notification',
    path: '/amz-notification'
  },
  CHANGE_PASSWORD: {
    id: 'change-password',
    path: '/change-password'
  },
  RESET_PASSWORD: {
    id: 'reset-password',
    path: '/reset-password'
  },
  VERIFY_PHONE: {
    id: 'verify-phone',
    path: '/verify-phone'
  },
  DASHBOARD: {
    id: 'dashboard',
    path: '/dashboard'
  },
  DASHBOARD_PROFILE: {
    id: 'dashboard-profile',
    path: '/dashboard/me'
  },
  PROFILE: {
    id: 'me',
    path: 'me'
  },
  MY_CERTIFICATES: {
    id: 'my-certificates',
    path: 'my-certificates'
  },
  ORDERS: {
    id: 'orders',
    path: 'orders'
  },
  CONSERVATION_DATA: {
    id: 'conservation-data',
    path: 'conservation-data'
  },
  HELP: {
    id: 'help',
    path: 'help'
  }
}
