import React, { useEffect, useState } from 'react'
import { decode } from 'jsonwebtoken'
import { Menu } from './components'
import { MyCertificates, ChangeProfile, Home, Help } from './pages'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes'
import { CertificateDetail } from './pages/MyCertificates/certificate-detail'
import { Orders } from './pages/Orders'
import { useAvatar, useProfile } from '../../services/hooks/useProfile'
import { ConservationData } from './pages/ConservationData'
// import { ConservationDataDetail } from './pages/ConservationData/conservation-data-detail'
import { useAuth } from '../../store'
import { twMerge } from 'tailwind-merge'

export function Dashboard() {
  const { token } = useAuth()
  const navigate = useNavigate()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const { isLoading: isLoadingProfile } = useProfile()
  const { isLoading: isLoadingAvatar } = useAvatar()

  // useEffect(() => console.log('### token', token, typeof token, !token), [token]);

  useEffect(() => {
    if (!token) {
      navigate(ROUTES_PATH.SIGN_IN.path)
      return
    }

    const decodedToken = decode(token) as any

    if (!decodedToken?.roles?.includes('VALID_USER')) {
      navigate(ROUTES_PATH.COMPLETE_SIGN_UP.path)
    }
  }, [navigate, token])

  useEffect(() => {

    if(window && window.innerWidth >= 768){
      setIsCollapsed(false)
    }

    const header = document.getElementById('page-top-header')
    const footer = document.getElementById('page-footer')

    if (footer && header) {
      const prevMainDisplay = header.style.display
      const prevFooterDisplay = footer.style.display

      header.style.display = 'none'
      footer.style.display = 'none'

      return () => {
        header.style.display = prevMainDisplay
        footer.style.display = prevFooterDisplay
      }
    }
  }, [])

  if (isLoadingProfile || isLoadingAvatar) {
    return null
  }

  return (
    <>
      <Menu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <div className={twMerge('h-full', isCollapsed ? 'ml-20' : 'ml-[18rem]')}>
        <Routes>
          <Route index element={<Home />} />
          <Route path={ROUTES_PATH.PROFILE.path} element={<ChangeProfile />} />
          <Route path={ROUTES_PATH.ORDERS.path} element={<Orders />} />
          <Route path={ROUTES_PATH.MY_CERTIFICATES.path}>
            <Route index element={<MyCertificates />} />
            <Route path=":id" element={<CertificateDetail />} />
          </Route>
          <Route path={ROUTES_PATH.CONSERVATION_DATA.path}>
            <Route index element={<ConservationData />} />
            {/* <Route path=":id"  element={<ConservationDataDetail /> } /> */}
          </Route>
          <Route path={ROUTES_PATH.HELP.path} element={<Help />} />
        </Routes>
      </div>
    </>
  )
}
