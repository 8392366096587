import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BitcoinCard } from 'iconsax-react'
import { Hexagon } from 'lucide-react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useTranslation } from 'react-i18next'
import { ROUTES_PATH } from '../../routes'

import { ReactComponent as SuccessCheck } from '../../assets/icons/success-check.svg'
import { goToSolscanAccount } from '../../utils/goToSolscan'
import { useCartStore } from '../../store'

export function Success() {
  const { t } = useTranslation()
  const { publicKey } = useWallet()
  const { clearNFTs } = useCartStore()

  useEffect(() => {
    clearNFTs()
  }, [clearNFTs])

  return (
    <div
      id={ROUTES_PATH.CHECKOUT_SUCCESS.id}
      className="min-h-[68.31vh] px-8 max-w-[540px] mx-auto pt-12 pb-28 flex flex-col items-center"
    >
      <SuccessCheck />
      <h1 className="mt-5 text-amz3green-150 font-medium text-3xl text-center">
        {t('success.title')}
      </h1>
      <p className="mt-8 text-amz3white-50 text-xl text-center">
        {t('success.description')}
      </p>
      <div className="mt-24 flex flex-col gap-11 md:flex-row">
        <Link
          to={ROUTES_PATH.DASHBOARD.path}
          className="bg-amz3green-150 flex items-center justify-center gap-4 h-[72px] w-[280px] rounded-2lg text-[#1B1B1B] font-semibold text-xl border border-amz3green-150  hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
        >
          <span>{t('success.dash-btn')}</span>
          <Hexagon />
        </Link>
        {publicKey && (
          <Link
            to={goToSolscanAccount(publicKey)}
            target="_blank"
            className="bg-transparent flex items-center justify-center gap-4 h-[72px] w-[280px] rounded-2lg text-amz3green-150 font-semibold text-xl border border-amz3green-150 hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in"
          >
            <span>{t('success.wallet-btn')}</span>
            <BitcoinCard />
          </Link>
        )}
      </div>
    </div>
  )
}
