import React, { useEffect, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  Lock1 as Lock1Icon,
  Logout as LogoutIcon,
  ProfileCircle as ProfileCircleIcon,
  User
} from 'iconsax-react'
import { ProfileMenuItem } from './profile-menu-item'
import { useAuth } from '../../store'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes'
import { useTranslation } from 'react-i18next'
import { useAvatar, useProfile } from '../../services/hooks/useProfile'
import { Avatar, Button } from '@mantine/core'
import { twMerge } from 'tailwind-merge'

type ProfileMenuProps = {
  onSelect?: () => void
}

const hoverLineStyle = twMerge(
  'inline-block relative',
  'after:content-[""] after:absolute after:w-[120%] after:scale-x-0 after:h-0.5 after:bottom-0 after:left-0 after:bg-amz3white-50 after:origin-bottom-right after:transition after:transform after:duration-300', 
  'hover:after:scale-x-[1] hover:after:origin-bottom-left'
);

export function ProfileMenu({ onSelect }: ProfileMenuProps) {
  const { token, signOut } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const { data: profile, refetch: refetchProfile } = useProfile(!!token)
  const { data: avatar, refetch: refetchAvatar } = useAvatar(!!token)

  useEffect(() => {
    if (token) {
      refetchProfile()
      refetchAvatar()
    }
  }, [refetchAvatar, refetchProfile, token])

  if (!token) {
    return (
      <Button
        variant="transparent"
        aria-label={t('user-menu.trigger')}
        px="0px"
        onClick={() => {
          // console.log(token)
          if (!token) {
            navigate(`${ROUTES_PATH.SIGN_IN.path}`)
            onSelect && onSelect()
          }
        }}
      >
        <Avatar mr="xs" src="#" alt="Avatar" size="2.25rem">
          <ProfileCircleIcon size={26} />
        </Avatar>
        <span
          className={twMerge(
            hoverLineStyle,
            'text-zinc-50 text-lg font-light max-w-[80%] text-center hidden md:block'
          )
          }
        >
          {t('user-menu.sign-in')}
        </span>
      </Button>
    )
  }

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger asChild>
        <Button
          variant="transparent"
          aria-label={t('user-menu.trigger')}
          px="0px"
        >
          <Avatar mr="xs" src={avatar} alt={profile?.name} size="2.25rem" />
          <span
            className={twMerge(
              hoverLineStyle,
              'text-zinc-50 text-lg font-light max-w-[80%] text-center hidden md:block',
            )}
          >
            {profile?.name}
          </span>
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={'w-56 bg-zinc-700 px-2 py-4 rounded-lg z-[51]'}
          sideOffset={5}
        >
          <>
            <ProfileMenuItem>
              <Link
                onClick={() => {
                  setIsOpen(false)
                  onSelect && onSelect()
                }}
                to={ROUTES_PATH.DASHBOARD_PROFILE.path}
                className="flex items-center gap-2 w-full"
              >
                <User size={16} />
                {t('dashboard.menu.account')}
              </Link>
            </ProfileMenuItem>
            <ProfileMenuItem>
              <Link
                onClick={() => {
                  setIsOpen(false)
                  onSelect && onSelect()
                }}
                to={ROUTES_PATH.CHANGE_PASSWORD.path}
                className="flex items-center gap-2 w-full"
              >
                <Lock1Icon size={16} />
                {t('user-menu.change-password')}
              </Link>
            </ProfileMenuItem>
            <ProfileMenuItem className="data-[highlighted]:bg-red-600">
              <Link
                to={ROUTES_PATH.HOME.path}
                onClick={() => {
                  signOut()
                  setIsOpen(false)
                  refetchProfile()
                  refetchAvatar()
                  onSelect && onSelect()
                }}
                className="flex items-center gap-2 w-full"
              >
                <LogoutIcon size={16} />
                {t('user-menu.sign-out')}
              </Link>
            </ProfileMenuItem>
          </>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
