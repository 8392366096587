import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import useOnScreen from '../../../utils/hooks/useOnScreen';

type AnimationProps = {
  src: string,
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Animation({ src, className, ...props }: AnimationProps) {

  const [dotLottie, setDotLottie] = useState<any>(null);

  const ref = useRef(null)
  const visible = useOnScreen(ref)

  const dotLottieRefCallback = (dotLottie: any) => {
    setDotLottie(dotLottie);
  };

  useEffect(() => {

    function onComplete() {
      setTimeout(()=> {
        dotLottie?.play()
      }, 2000)
    }

    if (dotLottie) {
      dotLottie.addEventListener('complete', onComplete);
    }

    return () => {
      if (dotLottie) {
        dotLottie.removeEventListener('complete', onComplete);
      }
    };
  }, [dotLottie]);

  useEffect(() => {
    if(dotLottie) {
      if(visible){
        dotLottie.setFrame(0)
        dotLottie.play()
      } else {
        dotLottie.stop()
        dotLottie.setFrame(0)
      }
    }
  }, [visible, dotLottie])

  return (
    <div ref={ref} className='w-[200px] h-[300px] [&_canvas]:!w-[200px] [&_canvas]:!h-[300px]'>
      {!!visible && <DotLottieReact
        autoplay
        src={src}
        dotLottieRefCallback={dotLottieRefCallback}
      />}
    </div>
  )
}
