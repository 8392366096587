export function scrollTo(id: string | number, behavior = "smooth") {
  
  let top = 0

  if(typeof id === "string") {
    const elementTop = document.getElementById(id)?.offsetTop ?? 0
    top = elementTop - 96
  } else {
    top = id
  }

  window.scrollTo({
    behavior: behavior as ScrollBehavior,
    top
  })

}
