import { TickCircle as TickCircleIcon } from 'iconsax-react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
// import { ToastContainer, toast } from 'react-toastify'
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { Form, FormInput, FormSubmit } from '../../components'
import { ROUTES_PATH } from '../../routes'
import {
  ChangePasswordErrorResponse,
  ChangePasswordFormProps,
  MessageTypeProps,
  changePasswordFormSchema
} from './types'
import { api } from '../../services/api'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../store'
import { SignInDiv } from '../../components/SignInDiv'

export function ChangePassword() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { setToken } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const changePasswordForm = useForm<ChangePasswordFormProps>({
    resolver: zodResolver(changePasswordFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = changePasswordForm

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setToken(token)
      searchParams?.delete('token')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams, setToken])

  const onSubmit = useCallback(
    async (data: ChangePasswordFormProps) => {
      try {
        const { password } = data

        const { data: changePasswordData } = await api.post<{
          'access-token': string
        }>('accounts/change-password', { password })

        if (changePasswordData) {
          navigate(ROUTES_PATH.HOME.path)
          toast.success(t('change-password.success-message'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      } catch (error) {
        if (isAxiosError<ChangePasswordErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.message
          if (errorMessage?.length) {
            errorMessage.forEach(message => {
              toast.error(
                t(message?.Message[i18n.language as keyof MessageTypeProps]),
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
            })
          } else {
            toast.error(t('change-password.request-error'), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })
          }
          // console.log({ error })
        } else {
          toast.error(t('change-password.request-error'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
        // console.log({ error })
      }
    },
    [i18n.language, navigate, t]
  )

  return (
    <>
      <SignInDiv id={ROUTES_PATH.CHANGE_PASSWORD.path}>
        <h1 className="text-3xl">{t('change-password.title')}</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...changePasswordForm}>
            <FormInput
              inputName="password"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('change-password.form.password')}
              type="password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />
            <FormInput
              inputName="confirmPassword"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.confirm-password')}
              type="password"
              errorMessage={errors.confirmPassword?.message}
              {...register('confirmPassword')}
            />
            <FormSubmit isSubmitting={isSubmitting} className="h-12 text-base">
              <span className="font-medium text-base">
                {t('change-password.form.submit-btn')}
              </span>
              <TickCircleIcon />
            </FormSubmit>
          </FormProvider>
        </Form>
      </SignInDiv>
      {/* <ToastContainer /> */}
    </>
  )
}
