import cx from 'classnames'
import { Wind, Drop } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { ROUTES_PATH } from '../../routes'
import { NFTResumeCard, NFTTotal, NFTCardDetail } from './components'
import { useCurrencyExchange } from '../../services/hooks/useCurrencyExchange'
import { useAuth, useCartStore } from '../../store'
import { useNavigate } from 'react-router-dom'
import { MapNFT } from '../../models/map-nft'
import { twMerge } from 'tailwind-merge'
// import { VideoTutorial } from '../NFTCatalog'

type NFTCatalogResumeContentProps = {
  className?: string
  cartNFTs: MapNFT[]
}

export function NFTCatalogResumeContent({
  className,
  cartNFTs
}: NFTCatalogResumeContentProps) {
  const { t } = useTranslation()
  const { token } = useAuth()
  const navigate = useNavigate()
  const { validNFTs, deleteNFT, currency } = useCartStore()
  const { data: currencyValue } = useCurrencyExchange(currency)

  const handleDeleteNFT = (cartNFT: MapNFT) => {
    deleteNFT(cartNFT)

    if (!cartNFTs.length) {
      navigate(ROUTES_PATH.CATALOG.path)
    }
  }

  const totalNFT =
    Number(
      cartNFTs
        .reduce((prev, curr) => prev + curr.price, 0)
        .toFixed(2)
    ) * (currencyValue || 1)

  const waterVaporTotal = validNFTs().reduce(
    (prev, curr) => prev + curr.areaInSquareMeters * 5,
    0
  )

  const areaTotal = cartNFTs.reduce(
    (prev, curr) => prev + curr.areaInSquareMeters,
    0
  )

  return cartNFTs.length ? (
    <div className={twMerge(className)}>
      <h3 className="text-amz3green-50 text-center lg:text-left text-2.5xl uppercase font-semibold mb-4">
        {t('catalog.nfts-cart-title')}
      </h3>
      <div className="flex flex-col items-center mt-2 gap-3 md:flex-row md:mt-0 md:h-28">
        <NFTResumeCard
          icon={<Wind size={32} />}
          title={t('cart.area-total')}
          description={`${areaTotal} m²`}
        />
        <NFTResumeCard
          icon={<Drop size={32} />}
          title={t('cart.water-total')}
          description={`${waterVaporTotal} m³`}
        />
      </div>
      <div
        className={cx(
          "flex flex-col items-center my-2 gap-2 ",
          "lg:overflow-y-auto lg:max-h-[calc(100%-300px)]"
        )}
      >
        {cartNFTs.map(cartNFT => (
          <NFTCardDetail
            key={cartNFT.id}
            id={cartNFT.id}
            name={cartNFT.name}
            image={cartNFT.image}
            nftPosition={cartNFT.nftPosition}
            edition={cartNFT.edition}
            area={cartNFT.areaInSquareMeters}
            price={Number((cartNFT.price * (currencyValue || 1)).toFixed(2))}
            status={cartNFT.status}
            currency={currency}
            onDelete={() => handleDeleteNFT(cartNFT)}
          />
        ))}
      </div>

      <NFTTotal
        className="mt-auto"
        nftTotal={validNFTs().length}
        totalValue={totalNFT}
        currency={currency}
        to={token ? ROUTES_PATH.CART.path : ROUTES_PATH.SIGN_IN.path}
      />
    </div>
  ) : null
}
