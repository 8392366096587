import { useTranslation } from 'react-i18next'
import { MapNFT } from '../../models/map-nft'
import { twMerge } from 'tailwind-merge'
import { ViewfinderCircleIcon } from '@heroicons/react/20/solid'
import { NFTOwnedCard } from './components';

type NFTOwnedCatalogContentProps = {
  className?: string
  ownedNFTs: MapNFT[]
  setSelectedOwnedNFT: (nft: MapNFT) => void
}

export function NFTOwnedCatalogContent({
  className,
  ownedNFTs,
  setSelectedOwnedNFT
}: NFTOwnedCatalogContentProps) {
  const { t } = useTranslation()

  return (
    <div className={twMerge(className)}>
      <p className="text-amz3white-100 text-md mb-8">{t('owner-nfts.instructions')}</p>
      <div className="grid gap-8">
        {ownedNFTs.map((nft) => (
          <NFTOwnedCard
            nft={nft}
            icon={<ViewfinderCircleIcon width={26} color="inherit" />}
            onClick={setSelectedOwnedNFT}
          />
        ))}
      </div>
    </div>
  )
}
