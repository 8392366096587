import cx from 'classnames'
import { Link, To } from 'react-router-dom'
import { BitcoinCard } from 'iconsax-react'
import { twMerge } from 'tailwind-merge'
import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckoutButton } from './checkout-button'
import { CurrencyProps } from '../../../store/useCart'

type NFTTotalProps = {
  to?: To
  currency: CurrencyProps
  nftTotal?: number
  totalValue: number
  onClick?: () => void
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function NFTTotal({
  to,
  className,
  currency,
  nftTotal,
  totalValue = 0,
  onClick,
  ...props
}: NFTTotalProps) {
  const { t } = useTranslation()
  const path = to ? { to } : null

  return (
    <div className={twMerge('mt-4 mr-4', className)} {...props}>
      {!!nftTotal && (
        <div className="flex items-center justify-between">
          <span className="text-amz3green-50 font-semibold">
            {t('catalog.nft-card-total-quantity')}
          </span>
          <span className="text-amz3green-50 font-semibold">{nftTotal}</span>
        </div>
      )}
      <div className="flex items-center justify-between">
        <span className="text-amz3green-50 font-semibold">
          {t('catalog.nft-card-total-value')}
        </span>
        <span className="text-amz3green-50 font-semibold text-2xl">
          {currency
            ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency
              }).format(totalValue)
            : totalValue}
        </span>
      </div>
      <CheckoutButton
        as={to ? Link : 'button'}
        {...path}
        className={cx(
          "flex justify-center items-center gap-4",
          "bg-amz3green-150 text-[#1B1B1B] mt-2 hover:bg-opacity-80 rounded-2lg w-full h-14",
          "transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
        )}
        onClick={onClick}
      >
        <span className="font-semibold text-xl">
          {t('catalog.nft-card-total-checkout-btn')}
        </span>
        <BitcoinCard />
      </CheckoutButton>
    </div>
  )
}
