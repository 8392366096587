import { twMerge } from 'tailwind-merge'

import { CertificateNFT } from './certificate-nft'
import { MapNFT } from '../../../../models/map-nft'
import { useCurrencyExchange } from '../../../../services/hooks/useCurrencyExchange'
import { useCartStore } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import {useFeedMapsByUser, getFeedMapsByUser} from '../../../../services/hooks/useNFTs';

export function MyCertificates() {
  const { currency } = useCartStore()
  const { data: currencyValue } = useCurrencyExchange(currency)
  const { t } = useTranslation()
  const [nftData, setNFTData] = useState<MapNFT[]>([])
  const [filter, setFilter] = useState<'all' | 'pending' | 'retrieved'>('all')

  const { data: nftDataReactQuery } = useFeedMapsByUser()

  const localGetFeedMapsByUser = useCallback(async () => {
    const currentOwnedNFT = await getFeedMapsByUser()

    if (currentOwnedNFT?.length) {
      setNFTData(currentOwnedNFT)
    }
  }, [])



  useEffect(() => {
    localGetFeedMapsByUser()
  }, [localGetFeedMapsByUser])

  return (
    nftDataReactQuery?
    <>
      <div className="text-zinc-50 mb-4 text-base font-medium flex gap-3 flex-wrap mt-[6rem]">
        <button
          className={twMerge(
            'border border-zinc-700 rounded-full py-2 px-3',
            filter === 'retrieved' && 'bg-zinc-700'
          )}
          onClick={() => {
            setFilter('retrieved')
            setNFTData(nftDataReactQuery!.filter(nft => nft.transferredToUserWallet))
          }}
        >
          {t('dashboard.my-certificates.filter.retrieved')}
        </button>
        <button
          className={twMerge(
            'border border-zinc-700 rounded-full py-2 px-3',
            filter === 'pending' && 'bg-zinc-700'
          )}
          onClick={() => {
            setFilter('pending')
            setNFTData(nftDataReactQuery.filter(nft => !nft.transferredToUserWallet))
          }}
        >
          {t('dashboard.my-certificates.filter.pending')}
        </button>
        <button
          className={twMerge(
            'mr-2 border border-zinc-700 rounded-full py-2 px-3',
            filter === 'all' && 'bg-zinc-700'
          )}
          onClick={() => {
            setFilter('all')
            setNFTData(nftDataReactQuery)
          }}
        >
          {t('dashboard.my-certificates.filter.all')}
        </button>
      </div>
      <div
        className={twMerge(
          'grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
        )}
        style={{ maxHeight: 'calc(100% - 240px)' }}
      >
        {nftData.map(cartNFT => (
          <CertificateNFT
            key={cartNFT.id}
            nft={cartNFT}
            price={Number((cartNFT.price * (currencyValue || 1)).toFixed(2))}
            currency={currency}
          />
        ))}
      </div>
    </>
    : <div>Loading...</div>
  )
}
