import { Avatar, Flex, Text } from '@mantine/core'
import { ROUTES_PATH } from '../../../../routes'
import { useNavigate } from 'react-router-dom'
import { Calendar2 as Calendar2Icon, Ruler as RulerIcon } from 'iconsax-react'
import { RescueButton } from '../../components/RescueButton'
import { TNft } from '../../services/useOrders/types';

type OrderItemProps = {
  nft: TNft,
  currency: string
}

export function OrderItem({ nft, currency }: OrderItemProps) {
  const navigate = useNavigate()

  return (
    <Flex
      pt="md"
      pb="lg"
      pl="md"
      align="center"
      gap="md"
      className="cursor-pointer"
      onClick={() =>
        navigate(
          `${ROUTES_PATH.DASHBOARD.path}/${ROUTES_PATH.MY_CERTIFICATES.path}/${nft.id}`
        )
      }
    >
      <Avatar src={nft.image} className="[&>img]:scale-125" radius="sm">
        AMZ
      </Avatar>
      <Text size="sm">{nft.name}</Text>
      <div className="flex items-center gap-1 px-2 py-1 text-xs font-semibold rounded-full bg-zinc-700">
        <RulerIcon size={16} />
        <Text size="xs">{nft.areaInSquareMeters}m²</Text>
      </div>
      <div className="flex items-center gap-1 px-2 py-1 text-xs font-semibold rounded-full bg-zinc-700">
        <Calendar2Icon size={16} />
        <Text size="xs">{nft?.edition}</Text>
      </div>
      <Text size="md">
        {!!currency
          ? `${new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency
          }).format(nft.convertedPrice)}`
          : nft.convertedPrice}
      </Text>
      {!nft.transferredToUserWallet &&
        nft.nftToken && (
          <div
            className="w-full"
            onClick={e => {

              e.stopPropagation()
            }}
          >
            <RescueButton transferredToUserWallet={nft.transferredToUserWallet ?? false} small nftToken={nft.nftToken} />
          </div>
        )}
    </Flex>
  )
}
