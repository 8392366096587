import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { FormInput, FormSubmit, FormTextArea, Form } from '../../../../components'
import { ContactFormProps, contactFormSchema } from './type'
import { useTranslation } from 'react-i18next'

export function Help() {
  const { t } = useTranslation()
  const contactUsForm = useForm<ContactFormProps>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  })

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting }
  } = contactUsForm

  const onSubmit = async (data: ContactFormProps) => {
    const uId = process.env.REACT_APP_SERVICE_ID!
    const tempId = process.env.REACT_APP_TEMPLATE_ID!
    const pKey = process.env.REACT_APP_USER_ID!

    try {
      await emailjs.send(uId, tempId, data, pKey)
      reset()
      toast.success('Mensagem enviada com sucesso!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: 'dark'
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div className="dashboard-section flex flex-col justify-center">
      <h1 className="text-zinc-50 text-3xl font-semibold mb-4">
        {t('dashboard.menu.help')}
      </h1>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-5xl [&_label]:text-amz3green-50 [&_label]:font-medium [&_label]:text-xs"
      >
        <FormProvider {...contactUsForm}>
          <div className="flex flex-col gap-2">
            <label htmlFor="name">{t('contact-us.form.name')}</label> 
            <FormInput
              inputName="name"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('contact-us.form.name')}
              errorMessage={errors.name?.message}
              {...register('name')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="email">{t('contact-us.form.email')}</label> 
            <FormInput
              inputName="email"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('contact-us.form.email')}
              errorMessage={errors.email?.message}
              {...register('email')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="subject">{t('contact-us.form.subject')}</label> 
            <FormInput
              inputName="subject"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('contact-us.form.subject')}
              errorMessage={errors.subject?.message}
              {...register('subject')}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="message">{t('contact-us.form.message')}</label> 
            <FormTextArea
              inputName="message"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('contact-us.form.message')}
              errorMessage={errors.message?.message}
              {...register('message')}
            />
          </div>
          <FormSubmit
            isSubmitting={isSubmitting}
            className="h-12 text-base w-full md:w-32 md:ml-auto"
          >
            {t('contact-us.form.submit')}
          </FormSubmit>
        </FormProvider>
      </Form>
    </div>
  )
}
