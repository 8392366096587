import { z } from 'zod'
import i18n from '../../i18n'
import { validatePassword } from '../../utils'

export let changePasswordFormSchema: z.ZodEffects<
  z.ZodEffects<
    z.ZodObject<
      { password: z.ZodString; confirmPassword: z.ZodString },
      'strip',
      z.ZodTypeAny,
      { password: string; confirmPassword: string },
      { password: string; confirmPassword: string }
    >,
    { password: string; confirmPassword: string },
    { password: string; confirmPassword: string }
  >,
  { password: string; confirmPassword: string },
  { password: string; confirmPassword: string }
>

i18n.on('languageChanged', language => {
  changePasswordFormSchema = z
    .object({
      password: z.string().min(2, {
        message: i18n?.t('change-password.form.error-messages.password', {
          lng: language
        })
      }),
      confirmPassword: z.string().min(2, {
        message: i18n?.t(
          'change-password.form.error-messages.confirm-password',
          {
            lng: language
          }
        )
      })
    })
    .refine(data => data.password === data.confirmPassword, {
      message: i18n?.t('change-password.form.error-messages.password-match', {
        lng: language
      }),
      path: ['confirmPassword']
    })
    .refine(data => validatePassword.test(data.password), {
      message: i18n?.t(
        'change-password.form.error-messages.password-strength',
        {
          lng: language
        }
      ),
      path: ['password']
    })
})

export type ChangePasswordFormProps = z.infer<typeof changePasswordFormSchema>

export interface ChangePasswordErrorResponse {
  message: Message[]
}

export interface Message {
  Item: string
  Message: MessageTypeProps
}

export type MessageTypeProps = {
  pt: string
  en: string
}
