import { User as UserIcon } from 'iconsax-react'
import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import {  toast } from 'react-toastify'
// import { ToastContainer, toast } from 'react-toastify'
import { ArrowLeft as ArrowLeftIcon } from 'iconsax-react'
import { isAxiosError } from 'axios'
import { Link } from 'react-router-dom'
import { Form, FormInput, FormSubmit } from '../../components'
import { ROUTES_PATH } from '../../routes'
import {
  MessageTypeProps,
  ResetPasswordErrorResponse,
  ResetPasswordFormProps,
  resetPasswordFormSchema
} from './types'
import 'react-toastify/dist/ReactToastify.css'
import { api } from '../../services/api'
import { SignInDiv } from '../../components/SignInDiv'

export function ResetPassword() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const resetPasswordForm = useForm<ResetPasswordFormProps>({
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      email: searchParams?.get('email') || ''
    }
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = resetPasswordForm

  const onSubmit = useCallback(
    async (data: ResetPasswordFormProps) => {
      const { email } = data

      try {
        const response = await api.post('accounts/reset-password', {
          email
        })
        if (response.data) {
          navigate(ROUTES_PATH.HOME.path)
          toast.success(t('reset-password.success-message'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      } catch (error) {
        if (isAxiosError<ResetPasswordErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.message
          if (errorMessage?.length) {
            errorMessage.forEach(message => {
              toast.error(
                t(message?.Message[i18n.language as keyof MessageTypeProps]),
                {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
            })
          } else {
            toast.error(t('reset-password.error-message'), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })
          }
          // console.log({ error })
        } else {
          toast.error(t('reset-password.error-message'), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      }
    },
    [i18n.language, navigate, t]
  )

  return (
    <>
      <SignInDiv id={ROUTES_PATH.RESET_PASSWORD.path}>
        <h1 className="text-3xl flex items-center gap-4">
          <Link to={ROUTES_PATH.SIGN_IN.path}>
            <ArrowLeftIcon size={24} />
          </Link>
          {t('reset-password.title')}
        </h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...resetPasswordForm}>
            <FormInput
              inputName="email"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('reset-password.form.email')}
              errorMessage={errors.email?.message}
              {...register('email')}
            />
            <FormSubmit isSubmitting={isSubmitting} className="h-12 text-base">
              <span className="font-medium text-base">
                {t('reset-password.form.submit-btn')}
              </span>
              <UserIcon />
            </FormSubmit>
          </FormProvider>
        </Form>
      </SignInDiv>
      {/* <ToastContainer /> */}
    </>
  )
}
