import { useQueries, useQuery } from 'react-query'
import { api } from '../api'
import { MapNFT, PropertiesFeatureProps } from '../../models/map-nft'

export const NFT_SIZES = {
  TWO_EXTRA_LARGE: 'TWO_EXTRA_LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE',
  LARGE: 'LARGE',
  MEDIAN: 'MEDIAN',
  SMALL: 'SMALL',
  EXTRA_SMALL: 'EXTRA_SMALL'
} as const
export type NFTSizesProps = (typeof NFT_SIZES)[keyof typeof NFT_SIZES]
export async function getMapFeeds(): Promise<MapNFT[]> {
  const { data: nfts } = await api.get('nfts/feed-maps')
  return nfts
}
export async function getNftFeeds(): Promise<MapNFT[]> {
  const { data: nfts } = await api.get('nfts/short-nfts-feed')
  return nfts
}

export async function getNFTsUnavailable(): Promise<
  GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[]
> {
  const { data: nfts } = await api.get<
    GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[]
  >('nfts/short-unavailable')

  return nfts
}

export async function getFeedMapsByUser(): Promise<MapNFT[]> {
  const { data: nfts } = await api.get<MapNFT[]>(
    `nfts/nft-feeds-by-user`)
  return nfts
}
export async function getFeedMapsBySize(
  size: NFTSizesProps
): Promise<MapNFT[]> {
  const { data: nfts } = await api.get<MapNFT[]>(
    `nfts/nft-feeds-by-size/${size}`
  )

  return nfts
}

export async function getShortFeedMapsBySize(
  size: NFTSizesProps
): Promise<GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[]> {
  const { data: nfts } = await api.get<
    GeoJSON.Feature<GeoJSON.Polygon, PropertiesFeatureProps>[]
  >(`nfts/short-nft-feeds-by-size/${size}`)

  return nfts
}

export async function getNFTsIndications(): Promise<MapNFT[]> {
  const { data: nfts } = await api.get<MapNFT[]>('nfts/indications-nft')

  return nfts
}

export function useMapFeeds() {
  return useQuery('map-feed-list-all', getMapFeeds, {
    staleTime: 1000 * 60 * 60
  }) //1 hra
}
export function useNftFeeds() {
  return useQuery('nfts-feed-list-all', getNftFeeds, {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
export function useNFTsUnavailable() {
  return useQuery('short-nfts-unavailable-all', getNFTsUnavailable, {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
export function useFeedMapsByUser() {
  return useQuery('nfts-feed-by-user', () => getFeedMapsByUser(), {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
export function useFeedMapsBySize(size: NFTSizesProps) {
  return useQuery('nfts-feed-by-size', () => getFeedMapsBySize(size), {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
export function useFeedMaps() {
  return useQueries(
    (Object.keys(NFT_SIZES) as Array<keyof typeof NFT_SIZES>).map(key => {
      return {
        queryKey: ['nfts-by-size', NFT_SIZES[key]],
        queryFn: () => getFeedMapsBySize(NFT_SIZES[key]),
        staleTime: Infinity
      }
    })
  )
}
export function useShortFeedMaps() {
  return useQueries(
    (Object.keys(NFT_SIZES) as Array<keyof typeof NFT_SIZES>).map(key => {
      return {
        queryKey: ['short-nfts-by-size', NFT_SIZES[key]],
        queryFn: () => getShortFeedMapsBySize(NFT_SIZES[key]),
        staleTime: Infinity
      }
    })
  )
}
export function useNFTsIndications() {
  return useQuery('indications-nft', getNFTsIndications, {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
