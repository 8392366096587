import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Field, Control } from '@radix-ui/react-form'
import { Combobox, Transition, ComboboxInputProps } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { api } from '../../../services/api'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type CountryProps = {
  countryCode: string
  flag: string
  en: string
  pt: string
}

type AutocompleteProps = {
  inputName: string
  onSelectCountry: (countryCode: string) => void
  disabled?: boolean
  errorMessage?: string
  className?: string
} & ComboboxInputProps<'input', CountryProps>

export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(
  (
    {
      inputName,
      onSelectCountry,
      disabled,
      className,
      errorMessage,
      ...props
    }: AutocompleteProps,
    ref
  ) => {
    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState('')
    const [countries, setCountries] = useState<CountryProps[]>([])
    const { i18n } = useTranslation()

    const listCountries = async () => {
      try {
        const { data } = await api.get<CountryProps[]>(
          '/accounts/list-countries'
        )

        !!data.length && setCountries(data)
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      listCountries()
    }, [])

    const handleDisplayValue = useCallback(
      (country: CountryProps) => {
        if (country) {
          onSelectCountry(country.countryCode)
          return i18n.language === 'pt'
            ? `${country.flag} ${country.pt}`
            : `${country.flag} ${country.en}`
        }
        return ''
      },
      [i18n.language, onSelectCountry]
    )

    const filteredCountry = useMemo(
      () =>
        query === ''
          ? countries
          : countries.filter(country =>
              i18n.language === 'pt'
                ? country.pt
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
                : country.en
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            ),
      [countries, i18n.language, query]
    )

    return (
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative w-full">
          <div className="flex flex-col relative cursor-default overflow-hidden rounded-lg text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Field asChild name={inputName}>
              <Control asChild>
                <>
                  <Combobox.Input
                    className={twMerge(
                      'rounded-2lg pl-7 border border-amz3green-50 h-[4.5rem] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none',
                      className,
                      disabled &&
                        'backdrop-brightness-75 text-opacity-60 cursor-not-allowed'
                    )}
                    {...props}
                    onChange={event => setQuery(event.target.value)}
                    displayValue={handleDisplayValue}
                    readOnly={disabled}
                    ref={ref}
                  />
                  {errorMessage && (
                    <span className="text-red-500 font-normal text-base">
                      {errorMessage}
                    </span>
                  )}
                </>
              </Control>
            </Field>
            {!disabled && (
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredCountry.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredCountry.map(country => (
                  <Combobox.Option
                    key={country.countryCode}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={country}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {i18n.language === 'pt'
                            ? `${country.flag} ${country.pt}`
                            : `${country.flag} ${country.en}`}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    )
  }
)
