import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

type RemoveDuplicatedProps<ComponentProps, PassedProps> = Omit<
  ComponentProps,
  keyof PassedProps
>

type GetPropsWithOverride<Root extends ButtonRoots, Props> = {
  as?: Root
} & Props &
  RemoveDuplicatedProps<React.ComponentProps<Root>, Props>

type ButtonRoots = 'a' | 'button' | typeof Link

interface BaseButton<Props> {
  <Root extends ButtonRoots>(
    props: GetPropsWithOverride<Root, Props>
  ): JSX.Element
  (props: Props): JSX.Element
}

export const CheckoutButton: BaseButton<{
  className?: string
  children?: ReactNode
}> = ({ as: Component = 'button', ...rest }) => {
  return <Component {...rest} />
}
