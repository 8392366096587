import { GoogleAddressProps } from '../models/google-address-api.type'
import { api } from '../services/api'

export const searchZIPCode = async (zipCode: string) => {
  if (!zipCode) return
  const { data: response } = await api.get<GoogleAddressProps>(
    `/accounts/get-address-by-zipcode/${zipCode}`
  )

  return response?.results.length ? response?.results[0] : null
}
