import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Google from "../../assets/icons/social/google.svg";
import Microsoft from "../../assets/icons/social/microsoft.svg";
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react'
import { api } from '../../services/api';
import { decode } from 'jsonwebtoken'
import { useAuth } from '../../store'
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from "axios";
import { SignInErrorResponse } from "../../pages/SignIn/types";
import { toast } from "react-toastify";
import i18n from '../../i18n';
export const SocialLoginButton = () => {
  const { t } = useTranslation()
  const { instance } = useMsal();
  const { setToken, setEmail } = useAuth()
  const navigate = useNavigate()

  const handleError=useCallback((error:any)=>{
  if (isAxiosError(error)) {
      const { response } = error
      if (response) {
        const { data } = response
        const { messages } = data as SignInErrorResponse
        const {  Message } = messages[0]
        const locale = `${i18n.language}`
        toast.error(locale === "en"
          ? Message.en
          : Message.pt, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: 'dark'
        })
      }
    } else {
      toast.error(t('sign-in.error'))
    }

  },[t])


  // const handleError = (error: any) => {
  
  // }

  const callbackSignIn = useCallback((response: any) => {
    const { access_token, redirect_url } = response.data
    if (access_token) {
      const decodedToken = decode(access_token) as any
      setToken(access_token)
      setEmail(decodedToken.email)
      navigate(-1)
    }
    else redirect_url && navigate(redirect_url)
  }, [setToken, setEmail, navigate])
  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      const gtagGoogleLogin = JSON.stringify({ 'method': 'Google' });
      <script>gtag("event", "login", { gtagGoogleLogin});</script>

      const { access_token: googleAccessToken } = response
      api
        .post(`/accounts/sign-in-with-google`, { token: googleAccessToken }, {
          withCredentials: true
        })
        .then((res) => { callbackSignIn(res) })
        .catch((err) => handleError(err));
    },
    onError: (error) => {
      handleError(error);
    },
  })

  const msLogin = useCallback(() => {
    instance.loginPopup({
      scopes: ["User.Read", "openid", "profile"],
    }).then((res: any) => {
      const gtagMicrosoftLogin = JSON.stringify({ 'method': 'Microsoft' });
      <script>gtag("event", "login", { gtagMicrosoftLogin});</script>
      const { accessToken } = res
      api
        .post(`/accounts/sign-in-with-microsoft`, { token: accessToken }, {
          withCredentials: true
        })
        .then((response) => { callbackSignIn(response) })
        .catch((err) => handleError(err));
    })
  }, [callbackSignIn, handleError, instance])


  return (<div className="flex flex-col gap-2 mt-4">
    <div className="text-center text-sm text-amz3green-50">{t('sign-in.or')}</div>
    <button
      onClick={() => googleLogin()}
      className="border border-amz3green-150 text-amz3green-150 bg-amz3black-500 rounded-2lg w-full h-12 flex justify-center items-center gap-4 font-medium text-base hover:bg-amz3black-200 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
    >
      <span><img width={16} height={16} alt="Google" src={Google} /></span>
      <span>{t('sign-in.google')}</span>
    </button>
    <button
      onClick={() => msLogin()}
      className="border border-amz3green-150 text-amz3green-150 bg-amz3black-500 rounded-2lg w-full h-12 flex justify-center items-center gap-4 font-medium text-base hover:bg-amz3black-200 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
    >
      <span><img width={16} height={16} alt="Microsoft" src={Microsoft} /></span>
      <span>{t('sign-in.microsoft')}</span>
    </button>
  </div>)
};
