import { create, StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'

export type LanguageProps = 'pt' | 'en'

type LanguageState = {
  language: LanguageProps
  setLanguage: (languageValue: LanguageProps) => void
  getLanguage: () => LanguageProps
}

type PersistProps = (
  config: StateCreator<LanguageState>,
  options: PersistOptions<LanguageState>
) => StateCreator<LanguageState>

export const useLanguage = create<LanguageState>(
  (persist as PersistProps) (
    (set, get) => {
      return {
        language: 'pt',
        setLanguage: (languageValue: LanguageProps) => set(() => ({
          language: languageValue
        })),
        getLanguage: () => get().language
      }
    },
    {
      name: 'cart-storage'
    }
  )
)
