import Figure from '../../../assets/v2/figure-satelite-1.png'
import { useTranslation } from 'react-i18next';
import { useRef } from "react";
import { useInView } from "framer-motion";
import { revealSlideUp, revealFade } from '../../../motion'

export function SectionProsperity () {
  
  const { t } = useTranslation()

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInView1 = useInView(ref1, { once: true, amount: .3 });
  const isInView2 = useInView(ref2, { once: true, amount: .3 });
  const isInView3 = useInView(ref3, { once: true, amount: .3 });

  return (
    <>
      <section className='pt-10 lg:min-h-[70dvh] bg-amz3green-300 text-white grid grid-cols-1 md:grid-cols-2'>
        <div className="px-10 pt-20 md:p-20" ref={ref1}>
          <h3 className="text-3xl md:text-5xl [&>span]:block">
            <span
              style={revealSlideUp(isInView1, 1, 0.5)}
              className="text-amz3green-50"
            >{t('home.prosperity.title1')}</span>
            <span
              style={revealSlideUp(isInView1, 1, 1.0)}
              className="text-amz3green-100"
            >{t('home.prosperity.title2')}</span>
          </h3>
        </div>
        <div style={revealFade(isInView1, 4, 2.0)} className="p-10 md:p-20 [&>p]:mb-4">
          <p>{t('home.prosperity.first-paragraph')}</p>
          <p>{t('home.prosperity.second-paragraph')}</p>
          <figure ref={ref2} className="relative mt-10 md:mt-20 min-h-[80px]">
            <img
              src={Figure}
              alt="satelite"
              className='absolute max-w-full h-auto'
              style={revealSlideUp(isInView2, 3, 2)}
            />
          </figure>
        </div>
      </section>
      <section
        ref={ref3}
        className='lg:min-h-[40dvh] bg-amz3green-200 text-white grid grid-cols-1 md:grid-cols-2'>
        <div className="p-10 pb-20 md:p-20 mt-[50dvw] relative md:mt-0">
          <p style={revealFade(isInView3, 4, 2.0)}>{t('home.prosperity.third-paragraph')}</p>
        </div>
      </section>
    </>
  )
}