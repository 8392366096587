import { Collapse, Flex, Table } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { OrderItem } from './order-item'
import { OrderToPrint } from './orderToPrint'
import { TOrder } from '../../services/useOrders/types'
import { useTranslation } from 'react-i18next'
import { CustomBadge } from '../../components/CustomBadge'

type OrderItemsProps = {
  orderItem: TOrder
}

export function OrderItems({ orderItem }: OrderItemsProps) {
  const [opened, { toggle }] = useDisclosure(false)
  const { i18n } = useTranslation()
  const modifiedId =
    orderItem?.orderHash.slice(0, 4) + '...' + orderItem?.orderHash.slice(-4)

  const usFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const usDate = usFormatter.format(new Date(orderItem.date))
  const brFormatter = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const brDate = brFormatter.format(new Date(orderItem.date))

  return (
    <>
      <Table.Tr
        key={orderItem.id}
        onClick={toggle}
        className="cursor-pointer border-y-[1px]"
      >
        <Table.Td ta="center">{modifiedId}</Table.Td>
        <Table.Td ta="center">
          {i18n.language === 'en' ? usDate : brDate}
        </Table.Td>
        <Table.Td ta="center">
          <CustomBadge orderStatus={orderItem.orderStatus} />
        </Table.Td>
        <Table.Td ta="center" onClick={e => e.stopPropagation()}>
          <OrderToPrint />
        </Table.Td>
        <Table.Td ta="center">
          {!!orderItem?.currency
            ? `${new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: orderItem?.currency
            }).format(orderItem.convertedAmount)}`
            : orderItem.convertedAmount}
        </Table.Td>
        <Table.Td>
          {opened ? (
            <ArrowUp2 className="mx-2" size={16} />
          ) : (
            <ArrowDown2 className="mx-2" size={16} />
          )}
        </Table.Td>
      </Table.Tr>
      <Table.Tr className="border-0">
        <Table.Td colSpan={8}>
          <Collapse in={opened}>
            <Flex direction="column">
              {orderItem.nft.map((nft, idx) => (
                <OrderItem
                  nft={nft}
                  key={idx}
                  currency={orderItem.currency}
                />
              )
              )}
            </Flex>
          </Collapse>
        </Table.Td>
      </Table.Tr>
    </>
  )
}
