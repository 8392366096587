import { useTranslation } from 'react-i18next'
import { ContactForm } from './contact-form'
import { ROUTES_PATH } from '../../routes/utils'
import { useRef } from 'react';
import { useInView } from 'framer-motion';
import { revealSlideUp } from '../../motion';

export function ContactUs() {
  const { t } = useTranslation()
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  return (
    <div
      className="bg-amz3green-300 flex flex-col px-9 py-32 md:px-16 lg:px-40"
      id={ROUTES_PATH.CONTACT.id}
      ref={ref}
    >
      <div className="flex flex-col gap-12 justify-between md:flex-row">
        <div className="flex flex-col">
          <span style={revealSlideUp(isInView, 1, 0.5)} className="text-center text-amz3green-50 text-base md:text-left mb-4">
            {t('contact-us.title')}
          </span>
          <h1 style={revealSlideUp(isInView, 1, 0.75)} className="text-center text-white font-regular text-3xl md:text-5xl md:text-left">
            {t('contact-us.subtitle1')}
            <br/>
            {t('contact-us.subtitle2')}
            <br/>
            <span style={revealSlideUp(isInView, 1, 1.0)} className='text-amz3green-100'>
              {t('contact-us.subtitle3')}
            </span>
          </h1>
        </div>
        <ContactForm />
      </div>
    </div>
  )
}
