import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import { revealSlideUp } from "../../../motion";
import { useTranslation } from "react-i18next";

import Icon1 from '../../../assets/v2/greentech-icon-1.svg'
import Icon2 from '../../../assets/v2/greentech-icon-2.svg'
import Icon3 from '../../../assets/v2/greentech-icon-3.svg'
import Icon4 from '../../../assets/v2/greentech-icon-4.svg'
import Icon5 from '../../../assets/v2/greentech-icon-5.svg'

interface GreentechColumnProps {
  svg:any;
  text:string;
  delay:number;
}

function GreentechColumn({ svg, text, delay }:Readonly<GreentechColumnProps>){
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  return(
    <div
      className="flex flex-col items-center justify-center gap-4 text-center text-sm"
      style={revealSlideUp(isInView, 1, delay)}
      ref={ref}
    >
      <img alt={text} src={svg}/>
      <p>{text}</p>
    </div>
  )
}

export function SectionGreentech () {
  const ref = useRef(null);
  const refSub = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const isInViewSub = useInView(refSub, { once: true, amount: .3 });
  const { t } = useTranslation()
  return (
    <section
      ref={ref}
      className={cx(
        'py-32 p-16 lg:min-h-[80dvh]  bg-amz3green-300 text-amz3green-100',
        'flex flex-col gap-8 justify-center items-center'
      )}
    >
      <div className="w-full max-w-[1100px] flex flex-col gap-16">
        <h3 className='text-3xl lg:text-4xl w-full text-justify md:flex md:flex-row md:justify-between'>
          <span className="inline-block" style={revealSlideUp(isInView, 1, 0.1)}>DATA</span>{' '}
          <span className="inline-block" style={revealSlideUp(isInView, 1, 0.2)}>FOR</span>{' '}
          <span className="inline-block" style={revealSlideUp(isInView, 1, 0.3)}>A</span>{' '}
          <span className="inline-block" style={revealSlideUp(isInView, 1, 0.4)}>GREENER</span>{' '}
          <span className="inline-block" style={revealSlideUp(isInView, 1, 0.5)}>FUTURE</span>
        </h3>
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-8'>
          <GreentechColumn svg={Icon1} text={t('home.greentech.topic1')} delay={1.0}/>
          <GreentechColumn svg={Icon2} text={t('home.greentech.topic2')} delay={1.2}/>
          <GreentechColumn svg={Icon3} text={t('home.greentech.topic3')} delay={1.4}/>
          <GreentechColumn svg={Icon4} text={t('home.greentech.topic4')} delay={1.6}/>
          <GreentechColumn svg={Icon5} text={t('home.greentech.topic5')} delay={1.8}/>
        </div>
        <h3
          ref={refSub}
          style={revealSlideUp(isInViewSub, 1, 1.5)}
          className='text-2xl lg:text-4xl text-justify uppercase'
        >
          {t('home.greentech.subtitle')}
        </h3>
      </div>
    </section>
  )
}