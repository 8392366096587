import { NFTCard } from '../NFTCard/nft-card'
import { useNFTsIndications } from '../../services/hooks/useNFTs'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ROUTES_PATH } from '../../routes'

export function NFTCardGroup() {
  const { data: mapIndicationsData } = useNFTsIndications()
  const navigate = useNavigate()

  return (
    <motion.div
      animate={{
        x: [-300, 300],
        transition: {
          x: {
            repeat: Infinity,
            duration: 8,
            ease: 'linear',
            repeatType: 'reverse'
          }
        }
      }}
      className="overflow-hidden whitespace-nowrap gap-4 flex mt-9 items-start"
    >
      {!!mapIndicationsData?.length &&
        mapIndicationsData.map((item, index) => (
          <NFTCard
            key={item.id}
            name={item.name}
            image={item.image}
            style={{
              marginTop: index % 2 === 1 ? '3rem' : '',
              flex: '0 0 auto'
            }}
            onClick={() => navigate(ROUTES_PATH.CATALOG.path)}
            className="cursor-pointer"
          />
        ))}
    </motion.div>
  )
}
