

import { MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon, ViewfinderCircleIcon } from '@heroicons/react/20/solid'
import Map, { Layer, MapLayerMouseEvent, MapRef, Source } from 'react-map-gl'
import { MAP_INITIAL_STATE } from '../../../../../components/NFTMap/Mapbox/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MapNFT } from '../../../../../models/map-nft'
import { useProject } from '../../../../../services/hooks/useProject'
import { SidebarInfo } from './sidebar-info'
import { MapSelector } from './map-selector'
import { useTranslation } from 'react-i18next'
import { Timeline } from './timeline'
import { generateDateArray } from './timeline-util'

type MapboxProps = {
  data: MapNFT[];
}

export function ConservationDataMapbox({data}:MapboxProps) {

  const { t } = useTranslation()
  
  const mapRef = useRef<MapRef>(null)

  const projectId = data?.[0]?.projectId
  const {data:project} = useProject(projectId)

  const [selected, setSelected] = useState<MapNFT|undefined>(undefined)
  const [geojson, setGeojson] = useState<Object>({})
  const [projectGeojson, setProjectGeojson] = useState<Object>({})
  const [labelGeojson, setLabelGeojson] = useState<Object>({})
  
  const mapStyles = [
    'mapbox://styles/mapbox/satellite-v9',
    'mapbox://styles/mapbox/outdoors-v12',
    // 'mapbox://styles/mapbox/dark-v11',
  ];
  const [mapStyleIndex, setMapStyleIndex] = useState(0);
  const mapStyle = mapStyles[mapStyleIndex];

  const [cursor, setCursor] = useState<string>('default');

  const sortedData = data.sort((a, b) => a.name.localeCompare(b.name))

  // 
  // FUNCTIONS
  // 

  function zoomIn(){
    mapRef.current?.zoomIn({duration: 1000})
  }
  function zoomOut(){
    mapRef.current?.zoomOut({duration: 1000})
  }

  function flyTo(lng:number, lat:number, zoom = 14, duration = 2000){
    mapRef.current?.flyTo({
      center: [lng,lat],
      duration: duration,
      zoom: zoom
    })
  }

  // function flyHome(lng: number, lat: number, zoom = 14, duration = 2000){
  //   flyTo(lng, lat, zoom, duration)
  // }

  const flySelected = useCallback((nft:MapNFT)=>{
    if(nft){
      flyTo(
        nft.area.pointCenter.longitude,
        nft.area.pointCenter.latitude,
        nft.areaInSquareMeters > 2000 ? 16 : 18
      )
    }    
  },[])
  
  // 
  // EFFECTS
  // 

  useEffect(()=>{ 

    function populateProject() {
      return [{
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [project?.area.pointTopLeft.longitude, project?.area.pointTopLeft.latitude],
            [project?.area.pointTopRight.longitude, project?.area.pointTopRight.latitude],
            [project?.area.pointBottomRight.longitude, project?.area.pointBottomRight.latitude],
            [project?.area.pointBottomLeft.longitude, project?.area.pointBottomLeft.latitude],
            [project?.area.pointTopLeft.longitude, project?.area.pointTopLeft.latitude],
          ]],
        },
        properties: { id: project?.projectId, type: 'project' },
      }]
    }

    function populateNfts(){
      return data.map(nft => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [nft.area.pointTopLeft.longitude, nft.area.pointTopLeft.latitude],
            [nft.area.pointTopRight.longitude, nft.area.pointTopRight.latitude],
            [nft.area.pointBottomRight.longitude, nft.area.pointBottomRight.latitude],
            [nft.area.pointBottomLeft.longitude, nft.area.pointBottomLeft.latitude],
            [nft.area.pointTopLeft.longitude, nft.area.pointTopLeft.latitude],
          ]],
        },
        properties: { id: nft.id },
      }))
    }

    const geojson = {
      type: 'FeatureCollection',
      features: populateNfts()
    };
    setGeojson(geojson)

    const projectGeojson = {
      type: 'FeatureCollection',
      features: populateProject()
    };
    setProjectGeojson(projectGeojson)

    if(!selected){
      setSelected(data[0])
    }
  },[data, project, selected])

  useEffect(() => {
    if (selected) {
      const labelFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            selected.area.pointBottomRight.longitude,
            selected.area.pointBottomRight.latitude
          ]
        },
        properties: {
          id: selected.id,
          name:  selected.name.split('#')[1],
          latitude: selected.area.pointCenter.latitude.toFixed(6),
          longitude: selected.area.pointCenter.longitude.toFixed(6)
        }
      };
      setLabelGeojson({
        type: 'FeatureCollection',
        features: [labelFeature]
      });
    }
  }, [selected]);


  // 
  // EVENTS
  // 

  const onMapClick = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    if (feature && data) {
      const nftId = feature.properties?.id as string;
      const nft = data.find(item=>item.id===nftId)
      if(nft) {
        flySelected(nft);
        setSelected(nft);
      }
    }
  }, [data, flySelected, setSelected]);

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('grab'), []);

  function handleOnSelect(e:any){
    const nft = data.find(item=>item.id===e.currentTarget.value)
    if(nft){
      setSelected(nft)
      flySelected(nft)
    }
  }

  // TODO: retrive dates from backend
  const samples = generateDateArray(30,5,{startDate: new Date(), backwards: true}).sort()
  const [selectedSample, setSelectedSample] = useState(samples.length-1)

  function handleTimelineChangeDate(date:string, index:number){
    setSelectedSample(index)
  }

  return (
    <Map
    initialViewState={MAP_INITIAL_STATE}
    attributionControl={false}
    ref={mapRef}
    mapStyle={mapStyle}
    mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_KEY}
    interactiveLayerIds={['user-ucf-fill']}
    onClick={onMapClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    pitch={50}
    onLoad={() => flySelected(selected as MapNFT)}
    cursor={cursor}
    // onMouseMove={onMapMove}
  >
    <Source id="project-area" type="geojson" data={projectGeojson}>
    <Layer
        id="project-stroke"
        type="line"
        paint={{
          'line-color': '#fff',
          'line-width': 1,
        }}
      />
    </Source>
    <Source id="nft-areas" type="geojson" data={geojson}>
      <Layer
        id="user-ucf-stroke"
        type="line"
        paint={{
          'line-color': '#03ff00',
          'line-width': [
            'case',
            ['==', ['get', 'id'], selected?.id as string],
            8,
            2
          ],
        }}
      />
      <Layer
        id="user-ucf-fill"
        type="fill"
        paint={{
          'fill-color': 'transparent',
        }}
      />
    </Source>

    <Source id="selected-label" type="geojson" data={labelGeojson}>
        <Layer
          id="selected-label-layer"
          type="symbol"
          layout={{
            'text-field': [
              'format',
              ['concat', 'UCF #', ['get', 'name']], {'font-scale': 0.7, 'text-color': '#03ff00', 'text-font': ['literal', ['Roboto Mono Bold']]},
              '\n',
              ['concat', 'Lat. ', ['get', 'latitude']], {'font-scale': 0.7, 'text-color': '#ffffff', 'text-font': ['literal', ['Roboto Mono Regular']]},
              '\n',
              ['concat', 'Long. ', ['get', 'longitude']], {'font-scale': 0.7, 'text-color': '#ffffff', 'text-font': ['literal', ['Roboto Mono Regular']]}
            ],
            'text-anchor': 'top-left',
            'text-offset': [2, -1],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-justify': 'left'
          }}
          paint={{
            'text-halo-color': '#0b230f',
            'text-halo-width': 2,
            'text-halo-blur': 4,
          }}
        />
      </Source>
    
    <div id="mapbox-controls" className="absolute top-0 left-0 p-4 flex gap-2">
        <button
          className="catalog-mapbox-button"
          onClick={() => selected && flySelected(selected)}
        >
          <ViewfinderCircleIcon width={24} color='inherit'/>
        </button>
        <button className="catalog-mapbox-button" onClick={() => zoomIn()}>
          <MagnifyingGlassPlusIcon width={24} color='inherit'/>
        </button>
        <button className="catalog-mapbox-button" onClick={() => zoomOut()}>
          <MagnifyingGlassMinusIcon width={24} color='inherit'/>
        </button>
    </div>
    
    <div id="mapbox-sidebar" className="absolute right-4 top-4 flex flex-col gap-2 max-w-[300px]">
      <h3 className='text-base text-amz3green-50 font-bold'>
        {t('dashboard.conservation-data.sidebar.select-token')}
      </h3>
      <div id="mapbox-select" className="bg-amz3green-300 rounded-lg pr-4">
        <select
          className="p-4 bg-amz3green-300 text-amz3green-100 rounded-lg w-[280px] font-bold"
          onChange={handleOnSelect}
          value={selected?.id}
        >
          {sortedData.map(item => {
            return (<option key={item.id} value={item.id}>UCF #{item.name.split('#')[1]} ({item.areaInSquareMeters.toLocaleString('pt-BR')}m²)</option>)
          })}
        </select>
      </div>
      <div className='bg-amz3green-300 rounded-lg p-4'>
          <SidebarInfo nft={selected as MapNFT}/>
      </div>
    </div>

    <div id="mapbox-source-select" className='absolute left-4 bottom-8 flex flex-row gap-1'>
      <div className='flex flex-col'>
        <MapSelector
          index={0}
          label={t("dashboard.conservation-data.selector.satellite")}
          selected={mapStyleIndex}
          onClick={()=>setMapStyleIndex(0)}
          />
        <MapSelector
          index={1}
          label={t("dashboard.conservation-data.selector.ndvi")}
          selected={mapStyleIndex}
          onClick={()=>setMapStyleIndex(1)}
          />
      </div>
      <Timeline
        availableDates={samples}
        selectedIndex={selectedSample}
        onChangeDate={(d,i)=>handleTimelineChangeDate(d,i)}
      />
    </div>
  </Map>
)}