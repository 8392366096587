import React, { useState, useRef } from 'react'
import { Camera as CameraIcon } from 'iconsax-react'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Avatar, Button, Flex, Modal } from '@mantine/core'
import { api } from '../../../../services/api'
import { useAvatar } from '../../../../services/hooks/useProfile'
import { toast } from 'react-toastify'


type TAvatarProfile = {
  initialImage: string
  imageName: string
}

export function AvatarProfile({ initialImage, imageName }: TAvatarProfile) {
  const { refetch: refetchAvatar } = useAvatar()
  const [image, setImage] = useState<string>(initialImage)
  const [croppedImage, setCroppedImage] = useState<string>(initialImage)
  const [hover, setHover] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [crop, setCrop] = useState<Crop>({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: '%'
  })
  const imageRef = useRef<HTMLImageElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImage(reader.result as string)
        setUploading(true)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const getCroppedImg = () => {
    const canvas = previewCanvasRef.current
    const image = imageRef.current
    if (canvas && image && crop.width && crop.height) {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      const ctx = canvas.getContext('2d')

      canvas.width = crop.width
      canvas.height = crop.height

      ctx?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )

      return new Promise<string>((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => resolve(reader.result as string)
        }, 'image/jpeg')
      })
    }
  }

  const onCropComplete = async (crop: Crop) => {
    const croppedImageUrl = await getCroppedImg()
    setCroppedImage(croppedImageUrl || '')
  }

  const handleSave = async () => {
    if (!croppedImage) {
      console.error('No cropped image to save')
      return
    }
    console.log({ croppedImage })
    const res = await fetch(croppedImage)
    console.log({ res })
    const blob = await res.blob()
    console.log({ blob })
    const formData = new FormData()
    formData.append('avatar', blob, `${imageName}.jpg`)
    console.log({ formData })

    try {
      const response =await api.put('accounts/upload-avatar', formData)

      if(response.status >= 400) {
        console.error('Error uploading the image:', response)
        toast.error('Error uploading the image')

      }

      setUploading(false)
      setImage(croppedImage)
      refetchAvatar()
    } catch (error) {
      console.error('Error saving the image:', error)
    }
  }

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: '128px', height: '128px', position: 'relative' }}
    >
      {hover && (
        <label
          htmlFor="file-input"
          className="absolute z-10 bg-zinc-950 bg-opacity-60 w-full h-full flex items-center justify-center cursor-pointer"
        >
          <CameraIcon size={32} />
        </label>
      )}
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Avatar
        src={uploading ? croppedImage : image}
        alt={imageName}
        w="100%"
        h="100%"
        radius="sm"
      />
      <Modal
        opened={uploading}
        onClose={() => setUploading(false)}
        title="Upload Image"
      >
        <Flex direction="column" gap="md">
          <ReactCrop
            crop={crop}
            onComplete={onCropComplete}
            onChange={(newCrop: Crop) => setCrop(newCrop)}
          >
            <img ref={imageRef} src={image} alt={imageName} />
          </ReactCrop>
          <Button onClick={handleSave}>Save</Button>
        </Flex>
      </Modal>
      <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
    </div>
  )
}
