import { z } from 'zod'
import i18n from '../../../i18n'

export let secondStepFormSchema: z.ZodEffects<
  z.ZodObject<
    {
      country: z.ZodString
      zipCode: z.ZodString
      street: z.ZodString
      district: z.ZodString
      city: z.ZodString
      state: z.ZodString
      number: z.ZodString
      complement: z.ZodOptional<z.ZodString>
      cpf: z.ZodOptional<z.ZodString>
      phone: z.ZodString
    },
    'strip',
    z.ZodTypeAny,
    {
      country: string
      number: string
      zipCode: string
      street: string
      district?: string | undefined
      city: string
      state: string
      phone: string
      complement?: string | undefined
      cpf?: string | undefined
    },
    {
      country: string
      number: string
      zipCode: string
      street: string
      district?: string | undefined
      city: string
      state: string
      phone: string
      complement?: string | undefined
      cpf?: string | undefined
    }
  >,
  {
    country: string
    number: string
    zipCode: string
    street: string
    district?: string | undefined
    city: string
    state: string
    phone: string
    complement?: string | undefined
    cpf?: string | undefined
  }
>

i18n.on('languageChanged', language => {
  secondStepFormSchema = z
    .object({
      country: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.country', {
          lng: language
        })
      }),
      zipCode: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.zip-code', {
          lng: language
        })
      }),
      street: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.street', {
          lng: language
        })
      }),
      district: z.string(),
      city: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.city', {
          lng: language
        })
      }),
      state: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.state', {
          lng: language
        })
      }),
      number: z.string().min(1, {
        message: i18n?.t('sign-up.form.error-messages.number', {
          lng: language
        })
      }),
      complement: z.string().optional(),
      cpf: z.string().optional(),
      phone: z.string().min(1, {
        message: i18n?.t('sign-up.form.error-messages.phone', { lng: language })
      })
    })
    .refine(
      data => {
        if (
          (data.country.includes('Brasil') ||
            data.country.includes('Brazil')) &&
          (!data.cpf || data.cpf.trim() === '')
        ) {
          return false
        }
        return true
      },
      {
        message: i18n?.t('sign-up.form.error-messages.cpf', { lng: language }),
        path: ['cpf']
      }
    )
})

export type SecondStepFormProps = z.infer<typeof secondStepFormSchema>
