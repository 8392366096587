import { ArrowRight } from 'iconsax-react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Form from '@radix-ui/react-form'
import emailjs from 'emailjs-com'
import { useTranslation } from 'react-i18next'
import { ContactFormProps, contactFormSchema } from './type'
import { useState, useRef } from 'react'
import { useInView } from 'framer-motion';
import { Spin } from '../../components/Spin'
import {  toast } from 'react-toastify'
// import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { revealFade } from '../../motion'

export function ContactForm() {
  const { t } = useTranslation()
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const [sending, setSending] = useState(false)
  const contactUsForm = useForm<ContactFormProps>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  })

  const {
    handleSubmit,
    register,
    reset
    // formState: { errors }
  } = contactUsForm

  const onSubmit = async (data: ContactFormProps) => {
    setSending(true)
    const uId = process.env.REACT_APP_SERVICE_ID!
    const tempId = process.env.REACT_APP_TEMPLATE_ID!
    const pKey = process.env.REACT_APP_USER_ID!

    try {
      await emailjs.send(uId, tempId, data, pKey)
      reset()
      setSending(false)
      toast.success('Mensagem enviada com sucesso!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: 'dark'
      })
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <>
      <Form.Root
        className="flex flex-col gap-3.5"
        onSubmit={handleSubmit(onSubmit)}
        ref={ref} style={revealFade(isInView, 3, 1)}
      >
        <FormProvider {...contactUsForm}>
          <div className="md:flex md:items-center md:gap-4 md:justify-end">
            <Form.Field className="mt-10 md:mt-0" name="name">
              <Form.Control asChild>
                <input
                  className="rounded-2lg pl-7 border border-amz3green-50 h-[72px] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none md:w-[154px]"
                  placeholder={t('contact-us.form.name')}
                  type="text"
                  {...register('name')}
                  required
                />
              </Form.Control>
            </Form.Field>
            <Form.Field className="mt-3.5 md:mt-0" name="email">
              <Form.Control asChild>
                <input
                  className="rounded-2lg pl-7 border border-amz3green-50 h-[72px] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none md:w-[295px]"
                  placeholder={t('contact-us.form.email')}
                  type="email"
                  {...register('email')}
                  required
                />
              </Form.Control>
            </Form.Field>
          </div>
          <Form.Field className="" name="email">
            <Form.Control asChild>
              <input
                className="rounded-2lg pl-7 border border-amz3green-50 h-[72px] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none md:w-[465px]"
                placeholder={t('contact-us.form.subject')}
                type="text"
                {...register('subject')}
                required
              />
            </Form.Control>
          </Form.Field>
          <Form.Field className="" name="email">
            <Form.Control asChild>
              <input
                className="rounded-2lg pl-7 border border-amz3green-50 h-[72px] w-full bg-transparent text-amz3white-50 text-lg placeholder:text-amz3white-50 placeholder:text-lg focus:outline-none md:w-[465px]"
                placeholder={t('contact-us.form.message')}
                type="text"
                {...register('message')}
                required
              />
            </Form.Control>
          </Form.Field>
          <Form.Submit asChild>
            <button className="mt-4.5 ml-auto flex justify-center items-center rounded-2lg px-7 h-[72px] w-[171px] bg-amz3green-50 text-amz3green-300 text-lg md:mt-0 hover:bg-opacity-80 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in">
              {sending ? (
                <Spin />
              ) : (
                <>
                  <span className="mr-4">{t('contact-us.form.submit')}</span>
                  <ArrowRight />
                </>
              )}
            </button>
          </Form.Submit>
        </FormProvider>
      </Form.Root>
      {/* <ToastContainer /> */}
    </>
  )
}
