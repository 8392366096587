import { Drop, Wind } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { NFTResumeCard } from '../../components'

import { NFTCardDetail } from '../../components/NFTCatalogResume/components'
import { CheckoutContent } from './checkout-content'
import { useAuth, useCartStore } from '../../store'
import { useCurrencyExchange } from '../../services/hooks/useCurrencyExchange'
import { MapNFT } from '../../models/map-nft'
import { ROUTES_PATH } from '../../routes'
import { useNavigate } from 'react-router-dom'
// import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'

export function Cart() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { validNFTs, deleteNFT, currency } = useCartStore()
  const { data: currencyValue } = useCurrencyExchange(currency)
  const { token } = useAuth()

  useEffect(() => {
    if (!token) {
      navigate(ROUTES_PATH.SIGN_IN.path)
    }
    if (token && validNFTs().length === 0) {
      navigate(ROUTES_PATH.CATALOG.path)
    }
  }, [navigate, token, validNFTs])

  const handleDeleteNFT = (cartNFT: MapNFT) => {
    deleteNFT(cartNFT)
    if (!validNFTs().length) {
      navigate(ROUTES_PATH.CATALOG.path)
    }
  }

  const waterVaporTotal = validNFTs().reduce(
    (prev, curr) => prev + curr.areaInSquareMeters * 5,
    0
  )

  const areaTotal = validNFTs().reduce(
    (prev, curr) => prev + curr.areaInSquareMeters,
    0
  )

  return (
    <>
      <div className="px-4">
        <h1 className="text-center text-amz3green-50 font-semibold text-3xl uppercase mt-6 md:hidden">
          {t('cart.title')}
        </h1>
        <div className="flex flex-col items-center mt-8 gap-3 md:hidden">
          <NFTResumeCard
            icon={<Wind size={32} />}
            title={t('cart.area-total')}
            description={`${areaTotal} m²`}
          />
          <NFTResumeCard
            icon={<Drop size={32} />}
            title={t('cart.water-total')}
            description={`${waterVaporTotal} m³`}
          />
        </div>
        <div className="md:flex md:gap-8 md:py-6">
          <div className="flex gap-6 overflow-x-auto md:overflow-y-auto md:max-h-screen md:flex-col md:flex-1">
            {validNFTs().map(cartNFT => (
              <NFTCardDetail
                id={cartNFT.id}
                name={cartNFT.name}
                image={cartNFT.image}
                nftPosition={cartNFT.nftPosition}
                edition={cartNFT.edition}
                area={cartNFT.areaInSquareMeters}
                price={Number(
                  (cartNFT.price * (currencyValue || 1)).toFixed(2)
                )}
                currency={currency}
                onDelete={() => handleDeleteNFT(cartNFT)}
                key={cartNFT.id}
                className="w-[360px] md:w-full"
              >
                <p className="text-[#BABABA] text-sm mt-6 ">
                  {cartNFT.description}
                </p>
                <div className="mt-6 text-amz3white-50 text-xs flex flex-col gap-2">
                  <h1 className="text-amz3green-50 text-base font-medium">
                    {t('cart.nft-card-coordinates')}
                  </h1>
                  <span>
                    {t('cart.nft-card-coordinates-top-left')} [
                    {`${cartNFT.area.pointTopLeft.latitude}, ${cartNFT.area.pointTopLeft.longitude}`}
                    ]
                  </span>
                  <span>
                    {t('cart.nft-card-coordinates-bottom-left')} [
                    {`${cartNFT.area.pointBottomLeft.latitude}, ${cartNFT.area.pointBottomLeft.longitude}`}
                    ]
                  </span>
                  <span>
                    {t('cart.nft-card-coordinates-top-right')} [
                    {`${cartNFT.area.pointTopRight.latitude}, ${cartNFT.area.pointTopRight.longitude}`}
                    ]
                  </span>
                  <span>
                    {t('cart.nft-card-coordinates-bottom-right')} [
                    {`${cartNFT.area.pointBottomRight.latitude}, ${cartNFT.area.pointBottomRight.longitude}`}
                    ]
                  </span>
                </div>
              </NFTCardDetail>
            ))}
          </div>
          <div className="md:w-[530px]">
            <div className="flex flex-col items-center mt-8 gap-3 md:mt-0">
              <div className="hidden md:flex md:h-28 md:items-center md:mt-8 md:gap-3">
                <NFTResumeCard
                  icon={<Wind size={32} />}
                  title={t('cart.area-total')}
                  description={`${areaTotal} m²`}
                />
                <NFTResumeCard
                  icon={<Drop size={32} />}
                  title={t('cart.water-total')}
                  description={`${waterVaporTotal} m³`}
                />
              </div>
              <div className="md:block">
                <CheckoutContent />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}
