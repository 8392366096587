import { Link, LinkProps } from 'react-router-dom'

type SocialMediaButtonProps = {} & LinkProps

export function SocialMediaButton({
  to,
  children,
  ...props
}: SocialMediaButtonProps) {
  return (
    <Link
      to={to}
      target="_blank"
      className="w-[36px] h-[36px] flex items-center justify-center rounded-2lg hover:opacity-50 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
      {...props}
    >
      {children}
    </Link>
  )
}
