import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type NFTResumeCardProps = {
  icon: ReactNode
  title: string
  description: string
  contentProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function NFTResumeCard({
  icon,
  title,
  description,
  className,
  contentProps,
  ...props
}: NFTResumeCardProps) {
  return (
    <div
      className={twMerge(
        'h-full w-full rounded-2lg bg-amz3black-50 flex gap-4 p-4',
        className
      )}
      {...props}
    >
      <div className="text-amz3green-150 mt-1">{icon}</div>
      <div
        className={twMerge(
          'flex flex-col justify-start',
          contentProps?.className
        )}
        {...contentProps}
      >
        <span className="text-amz3green-50 font-regular text-sm">{title}</span>
        <span className="text-amz3green-150 text-lg font-semibold">
          {description}
        </span>
      </div>
    </div>
  )
}
