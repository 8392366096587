type RotateImageProps = {
  imageURL: string
  rotationDegrees: number
  flipImage?: boolean
}

export function rotateImage(
  { imageURL, rotationDegrees, flipImage = false }: RotateImageProps,
  callback: (arg0: string) => void
) {
  const image = new Image()
  image.onload = function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const width = image.width
    const height = image.height

    canvas.width = width
    canvas.height = height

    ctx?.translate(width / 2, height / 2)
    ctx?.rotate((rotationDegrees * Math.PI) / 180)
    flipImage && ctx?.scale(-1, 1)
    ctx?.drawImage(image, -width / 2, -height / 2)

    callback(canvas.toDataURL())
  }
  image.src = imageURL
}
