import { LegacyRef } from 'react'
import { Area } from '../../models/area'
import { Polygon } from '@react-google-maps/api'

export enum MOUSE_OVER {
  OVER,
  OUT
}

export const AREA_OPTIONS = {
  SELECTED: {
    fillColor: '#f3d007',
    strokeWeight: 1,
    fillOpacity: 1,
    strokeOpacity: 1,
    strokeColor: '#f3d007'
  },
  ACTIVE: {
    strokeColor: '#fff',
    strokeOpacity: 1,
    strokeWeight: 0.5,
    fillColor: '#00ff0d',
    fillOpacity: 0.1,
    geodesic: true
  },
  SOLD: {
    strokeColor: '#021a02',
    strokeOpacity: 0.9,
    strokeWeight: 0.9,
    fillColor: '#021a02',
    fillOpacity: 0.9,
    geodesic: true
  },
  RESERVED: {
    strokeColor: '#021a02',
    strokeOpacity: 0.9,
    strokeWeight: 0.9,
    fillColor: '#021a02',
    fillOpacity: 0.9,
    geodesic: true
  },
  EXPIRED: {
    strokeColor: '#021a02',
    strokeOpacity: 0.8,
    strokeWeight: 0.8,
    fillColor: '#021a02',
    fillOpacity: 0.8,
    geodesic: true
  },
  PRE_LAUNCH: {
    strokeColor: '#021a02',
    strokeOpacity: 0.8,
    strokeWeight: 0.8,
    fillColor: '#021a02',
    fillOpacity: 0.8,
    geodesic: true
  },
  DISABLED: {
    strokeColor: '#00ff80',
    strokeOpacity: 1,
    strokeWeight: 1,
    fillColor: '#00ff80',
    fillOpacity: 1,
    geodesic: true
  }
}

export const DEFAULT_LONG_LAT = {
  longitude: -59.48763906176899,
  latitude: -2.3661823122326044
} as const

export type CustomPolygonProps = {
  area: Area
  status: keyof typeof AREA_OPTIONS
  onClick: () => void
  onMouseMove: (event: google.maps.MapMouseEvent) => void
  ref?: LegacyRef<Polygon>
}
