import {
  ArrowRight,
  ArrowRotateRight,
  BitcoinCard,
  TickCircle,
  CloseCircle,
  Danger
} from 'iconsax-react'

export interface NotificationProps {
  id: string
  title: string
  message: string | { text: string; weight: 'normal' | 'bold' }[]
  status: keyof typeof NOTIFICATION_STATUS
  actionButton: ActionButton
}

interface ActionButton {
  buttonType: string
  label: keyof typeof ACTION_BUTTON_LABEL
  URL: string
}

enum ACTION_BUTTON_LABEL {
  TRY_AGAIN = 'TRY_AGAIN',
  WALLET = 'WALLET',
  FORWARD = 'FORWARD',
  GO_TO_HOME = 'GO_TO_HOME',
}

export const NotificationButtonTypes = {
  TRY_AGAIN: {
    label: 'notification.button-types.try-again',
    Icon: ArrowRotateRight
  },
  WALLET: {
    label: 'notification.button-types.wallet',
    Icon: BitcoinCard
  },
  FORWARD: {
    label: 'notification.button-types.forward',
    Icon: ArrowRight
  },
  GO_TO_HOME: {
    label: 'notification.button-types.home',
    Icon: null
  }
}

export enum NOTIFICATION_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  WARNING = 'WARNING'
}

export const NotificationStatusTypes = {
  SUCCESS: {
    icon: TickCircle,
    color: 'amz3green-150'
  },
  FAIL: {
    icon: CloseCircle,
    color: 'red-600'
  },
  WARNING: {
    icon: Danger,
    color: 'yellow-300'
  }
}
