import { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import AMZ3Logo from '../../assets/v2/logo-light.svg'
import AMZ3LogoDark from '../../assets/v2/logo-dark.svg'
import AMZ3FullLogo from '../../assets/v2/logo-full-light.svg'
import AMZ3FullLogoDark from '../../assets/v2/logo-full-dark.svg'

type AM3LogoProps = {
  full?: boolean,
  dark?: boolean,
} & DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export function AM3Logo({ full = false, dark = false, className, ...props }: AM3LogoProps) {
  const darkLogo = full ? AMZ3FullLogoDark : AMZ3LogoDark
  const lightLogo = full ? AMZ3FullLogo : AMZ3Logo
  return (
    <img
      src={dark ? darkLogo : lightLogo}
      className={twMerge('h-8', className)}
      alt="Amazon Tree Logo"
      {...props}
    />
  )
}
