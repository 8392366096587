import { useTranslation } from 'react-i18next'
import { FormSubmit } from '../Form'
import { LanguageSelector } from '../LanguageSelector'
import { useLanguage, LanguageProps } from '../../store/useLanguage'
import { AM3Logo } from '../AM3Logo'
import i18n from '../../i18n';

type PrivacyPolicyProps = {
  isOpen: boolean
  onSubmit: () => void
}

export function PrivacyPolicy({ isOpen, onSubmit }: PrivacyPolicyProps) {
  const { t } = useTranslation()
  const { setLanguage } = useLanguage()

  function handleCloseLangueSelector(language: LanguageProps) {
    setLanguage(language)
  }

  function handleSubmitPolicy() {
    onSubmit()
  }

  if (!isOpen) {
    return null
  }
  const locale = `${i18n.language}`
  return (
    <div className="fixed w-screen h-screen bg-white bg-opacity-30 text-amz3white-150 z-[52] flex justify-center items-end">
      <div className="fixed top-0 left-0 h-24 w-full bg-amz3black-400 flex items-center justify-between px-4.5 z-50">
        <AM3Logo full className="px-4" />
        <div className="ml-auto h-6">
          <LanguageSelector onClose={handleCloseLangueSelector} />
        </div>
      </div>
      <div className="bg-amz3black-500 mb-24 max-w-[90%] rounded-lg p-4 flex flex-col justify-center">
        <h1 className="text-3xl font-thin">{t('privacy-policy.title')}</h1>
        <p className="mt-4 font-thin">
          {t('privacy-policy.description')}
          <a
            className="underline"
            href={locale === "en"
              ? process.env.REACT_APP_PRIVACY_POLICY_URL_EN!
              : process.env.REACT_APP_PRIVACY_POLICY_URL_PT!}
            target="_blank"
            rel="noreferrer"
          >
            {t('privacy-policy.link')}
          </a>
        </p>

        <FormSubmit
          className="ml-auto max-w-xs h-12 mt-8"
          isSubmitting={false}
          onClick={handleSubmitPolicy}
        >
          {t('privacy-policy.submit')}
        </FormSubmit>
      </div>
    </div>
  )
}
