import { useCallback, useEffect, useState } from 'react'
import { ActionIcon, Button, Flex, Input } from '@mantine/core'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  CloseCircle as CloseCircleIcon,
  TickCircle as TickCircleIcon
} from 'iconsax-react'
import { api } from '../../../../services/api'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'
import { Spin } from '../../../../components';
import { TransferNftDto } from '../../../../models/transfer-nft.dto';


import 'react-toastify/dist/ReactToastify.css'

type TRetrieveButton = {
  nftToken: string;
  transferredToUserWallet: boolean;
  primary?: boolean;
  small?: boolean;
}

export function RescueButton({
  nftToken,
  primary,
  small,
  transferredToUserWallet
}: TRetrieveButton) {
  const [openInput, setOpenInput] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [activeSpinner, setActiveSpinner] = useState(false)
  const { publicKey } = useWallet()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (!inputValue && publicKey) {
      setInputValue(`${publicKey || ''}`)
    }
  }, [inputValue, publicKey])

  if (activeSpinner) {
    <Spin />
  }

  const handleOnConfirm = useCallback(async () => {
    setActiveSpinner(true)
    if (nftToken) {
      try {
        const response = await api.post(
          `/nfts/transfer-nft`,
          {
            nftToken,
            customerWallet: inputValue,
          } as TransferNftDto
        )
        if (response.status === 200) {
          setOpenInput(false)
          setActiveSpinner(false)
          console.log(response.data)
          toast.success(response.data[i18n.language], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
          window.location.reload()
          return response.data

        }
        console.log(response.data)
      } catch (error: any) {
        toast.error(error.response.data.messages[0].Message[i18n.language], {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: 'dark'
        })
      }
      setActiveSpinner(false)
    }
  }, [i18n.language, inputValue, nftToken])

  if(!nftToken || transferredToUserWallet) return <></>

  if (small) {
    if (!openInput) {
      return (
        <Button
          color="amz3-green.3"
          variant="outline"
          radius="lg"
          size="xs"
          onClick={() => setOpenInput(true)}
        >
          {t('dashboard.buttons.rescue')}
        </Button>
      )
    }

    return (
      <Flex align="center" gap="0.6rem">
        <Input
          placeholder="Digite sua wallet"
          c="gray.2"
          color="amz3-green.3"
          size="md"
          radius="xs"
          w="10rem"
          classNames={{ input: 'placeholder:text-sm text-sm' }}
          wrapperProps={{
            className:
              'bg-transparent border border-amz3green-150 rounded-md pl-2'
          }}
          value={inputValue}
          onChange={event => setInputValue(event.currentTarget.value)}
        />
        <Flex gap="0.375rem" ml="auto">
          <ActionIcon
            color="amz3-green.3"
            radius="sm"
            variant="outline"
            size="md"
            onClick={() => setOpenInput(false)}
          >
            <CloseCircleIcon size={18} />
          </ActionIcon>
          <ActionIcon
            size="md"
            color="amz3-green.3"
            c="dark.7"
            fw={700}
            radius="sm"
            onClick={handleOnConfirm}
          >
            <TickCircleIcon size={18} />
          </ActionIcon>
        </Flex>
      </Flex>
    )
  }
  if (transferredToUserWallet) return null

  if (!openInput) {
    return (
      <Button
        color="amz3-green.3"
        fullWidth
        variant={'outline'}
        radius="lg"
        onClick={() => setOpenInput(true)}
      >
        {t('dashboard.buttons.rescue')}
      </Button>
    )
  }
  return (
    <div className='flex flex-col gap-2'>
      <label className='text-xs font-medium text-amz3green-50'>{t('dashboard.rescue.label')}</label>
      <Input
        placeholder={t('dashboard.rescue.placeholder')}
        color="amz3-green.3"
        c="gray.1"
        wrapperProps={{
          className:
            'bg-transparent border border-amz3green-150 rounded-md px-4 py-2'
        }}
        value={inputValue}
        onChange={event => setInputValue(event.currentTarget.value)}
      />
      <Flex gap="xs" justify="end">
        <Button
          color="amz3-green.3"
          radius="md"
          variant="outline"
          onClick={() => setOpenInput(false)}
        >
          {t('dashboard.buttons.cancel')}
        </Button>
        <Button
          color="amz3-green.3"
          c="dark.7"
          fw={700}
          radius="md"
          onClick={handleOnConfirm}
        >
          {t('dashboard.buttons.send')}
        </Button>
      </Flex>
    </div>
  )
}

