import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import * as Icons from 'iconsax-react'
import * as Avatar from '@radix-ui/react-avatar'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { AM3Logo, AddWallet } from '../../../../components'
import { useLanguage } from '../../../../store'
import { useAvatar, useProfile } from '../../../../services/hooks/useProfile'
import { ROUTES_PATH } from '../../../../routes'
import { useNavigate, useLocation } from 'react-router-dom'
import { Select } from '@mantine/core'
import { Link } from 'react-router-dom'

type ItemsProps = {
  name: string
  icon?: keyof typeof Icons
  route?: string
}

type MenuProps = {
  isCollapsed: boolean
  setIsCollapsed: Dispatch<SetStateAction<boolean>>
}

const buttonStyle = twMerge(
  "flex items-center gap-2 w-full px-4 py-2 rounded-md",
  "cursor-pointer text-base font-semibold text-zinc-400",
  "hover:text-zinc-50 hover:bg-amz3black-300 hover:text-amz3green-50"
);

export function Menu({ isCollapsed, setIsCollapsed }: MenuProps) {
  const { data: profile } = useProfile()
  const { data: avatar } = useAvatar()
  const { t } = useTranslation()
  const { language, setLanguage } = useLanguage()
  const navigate = useNavigate()
  const location = useLocation()
  const [isSelected, setIsSelected] = useState('')

  useEffect(() => {
    const route = location?.pathname.split('/')
    if (!!route.length) {
      setIsSelected(route[route.length - 1] || '')
    }
  }, [location?.pathname])

  const Items: ItemsProps[] = useMemo(
    () => [
      {
        name: 'profile',
      },
      {
        name: t('dashboard.menu.certificates'),
        icon: 'ArchiveBook',
        route: ROUTES_PATH.DASHBOARD.path
      },
      {
        name: t('dashboard.menu.orders'),
        icon: 'Layer',
        route: ROUTES_PATH.ORDERS.path
      },
      {
        name: t('dashboard.menu.conservation'),
        icon: 'Tree',
        route: ROUTES_PATH.CONSERVATION_DATA.path
      },
      // {
      //   name: t('dashboard.menu.recent'),
      //   icon: 'Refresh2'
      // },
      // {
      //   name: t('dashboard.menu.contracts'),
      //   icon: 'Notepad2'
      // },
      // {
      //   name: t('dashboard.menu.certificates'),
      //   icon: 'ArchiveBook',
      //   route: ROUTES_PATH.MY_CERTIFICATES.path
      // },
      // {
      //   name: t('dashboard.menu.satellite-images'),
      //   icon: 'Radar2'
      // },
      // {
      //   name: t('dashboard.menu.tutorials'),
      //   icon: 'VideoPlay'
      // },
      {
        name: 'divider',
      },
      {
        name: t('dashboard.menu.help'),
        icon: 'MessageQuestion',
        route: ROUTES_PATH.HELP.path
      },
      {
        name: t('dashboard.menu.home'),
        icon: "House",
        route: ROUTES_PATH.HOME.path,
      },
    ],
    [t]
  )

  return (
    <motion.aside
      className={twMerge(
        'bg-amz3black-400 h-screen fixed py-8 flex flex-col items-center z-[50]',
        '[--open-width:100%] md:[--open-width:18rem]',
      )}
      animate={{ width: isCollapsed ? '5rem' : 'var(--open-width)' }}
      transition={{ duration: 0.5 }}
    >
      <button
        className={twMerge(
          'p-1 absolute -right-3 top-20',
          !isCollapsed && '-right-1 md:-right-3'
        )}
        onClick={() => setIsCollapsed(prevState => !prevState)}
      >
        {isCollapsed ? (
          <Icons.ArrowCircleRight className="text-zinc-50" size={21} />
        ) : (
          <Icons.ArrowCircleLeft className="text-zinc-50" size={21} />
        )}
      </button>
      <Link to={ROUTES_PATH.HOME.path}>
        <AM3Logo full={!isCollapsed} className='mb-8' />
      </Link>
      <div className='w-full max-w-[320px] p-2'>
        <div className="mt-4 flex flex-col gap-2 flex-1">
          <nav className='flex flex-col gap-2 mb-4'>
            {Items.map((item, index) => {

              if(item.name === "divider") {
                return (
                  <div
                    key={`${index}-${item.name}`}
                    className='bg-gray-700 h-[2px] w-[80%] my-4 mx-auto'></div>
                )
              }

              if(item.name === "profile") {
                return (
                  <button
                    key={`${index}-${item.name}`}
                    className={twMerge(
                      buttonStyle,
                      (isCollapsed ? 'justify-center' : ''),
                      item?.route?.endsWith(isSelected) && 'bg-amz3black-300 text-amz3green-50'
                    )}
                    onClick={() => {
                      navigate(ROUTES_PATH.PROFILE.path)
                    }}
                  >
                    <Avatar.Root>
                      <Avatar.Image
                        className="h-[20px] w-[20px] rounded-full"
                        src={avatar}
                        alt={profile?.name}
                      />
                      <Avatar.Fallback delayMs={600}>
                        <Icons.ProfileCircle size={21} />
                      </Avatar.Fallback>
                    </Avatar.Root>
                    <span
                      className={twMerge(
                        'text-base font-semibold max-w-[80%]',
                        isCollapsed && 'hidden'
                      )}
                    >
                      {t('dashboard.menu.account')}
                    </span>
                  </button>
                );
              }

              const Icon: Icons.Icon = Icons[item.icon as keyof typeof Icons]
              return (
                <button
                  key={item.name}
                  className={twMerge(
                    buttonStyle,
                    (isCollapsed ? 'justify-center' : ''),
                    item?.route?.endsWith(isSelected) && 'bg-amz3black-300 text-amz3green-50'
                  )}
                  onClick={() => {
                    item.route && navigate(item.route)
                  }}
                >
                  <Icon size={21} />
                  <span className={twMerge('truncate', isCollapsed && 'hidden')}>
                    {item.name}
                  </span>
                </button>
              )
            })}
          </nav>
          <AddWallet
            className={twMerge(
              '!w-full xl:!w-full !my-2',
              'gap-2 !h-10 !text-sm [&~.wallet-adapter-dropdown-list-active]:translate-x-28',
            )}
            classNameText={twMerge('truncate',
              isCollapsed ? '!hidden xl:!hidden' : '!flex'
            )}
          />
          <Select
            bg="black"
            checkIconPosition="right"
            data={[
              { value: 'pt', label: 'Português' },
              { value: 'en', label: 'English' }
            ]}
            onChange={value => {
              if (value === 'pt' || value === 'en') {
                setLanguage(value)
              }
            }}
            className="whitespace-nowrap"
            comboboxProps={{
              classNames: { dropdown: 'min-w-[8rem] whitespace-nowrap bg-zinc-50' }
            }}
            placeholder="Select language"
            defaultValue={language}
            allowDeselect={false}
            rightSectionWidth="1.5rem"
            leftSection={language === 'en' ? '🇺🇸' : language === 'pt' ? '🇧🇷' : ''}
            styles={{
              input: {
                background: 'rgba(255,255,255,.1)',
                color: "white",
              },
            }}
          />
        </div>
      </div>
    </motion.aside>
  )
}
