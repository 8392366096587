export async function fetchImageAsDataURL(imgURL: RequestInfo | URL) {
  const response = await fetch(imgURL, { cache: 'no-cache' })
  const blob = await response.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
