import { useTranslation } from "react-i18next"
import { ConservationDataMapbox } from "./map"
import { FlipCard } from "./flip-card"
import { useFeedMapsByUser } from "../../../../../services/hooks/useNFTs"
import FlipNumbers from "react-flip-numbers"
import { useEffect, useState } from "react"
import { flipNumberFormat } from "./flip-util"
import { RATE_PER_METER } from "./constants"
import { api } from "../../../../../services/api"
import { twMerge } from "tailwind-merge"
import { useViewportSize } from "@mantine/hooks"
import { Monitor } from "lucide-react"

export function ConservationDataInterface() {
  const { t } = useTranslation()

  const [totalArea, setTotalArea] = useState<number>(0)
  const { data } = useFeedMapsByUser()
  console.log(data)

  const {width: windowWidth} = useViewportSize()
  const isDesktop = windowWidth > 1024

  useEffect(() => {

    const totalArea = data?.reduce((total, nft) => {
      return total+nft.areaInSquareMeters;
    }, 0);

    setTotalArea(totalArea ?? 0)

  },[data])

  async function downloadReport() {
    try {
  
      // Make a GET request with Axios
      const response = await api.get(`/nfts/generate-pdf-report-by-user`, {
        responseType: 'blob', // Specify the response type as blob
      });
  
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `AmazonTree-Report.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading report:', error);
      // Optionally, show an error notification to the user
    }
  }

  return (
    <div className="w-full h-full flex flex-col">
      <section className="dashboard-section flex flex-col gap-4 text-zinc-50 pb-4">
          <h1 className="text-3xl font-semibold shrink">
            {t('dashboard.menu.conservation')}
          </h1>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-4">
            
            <div className="flex flex-row gap-2 flex-wrap">
              <div className="lg:min-w-[180px] p-4 bg-amz3black-50 rounded-md relative grow lg:grow-0">
                <div className="text-amz3green-100 text-lg font-bold [&>section]:!justify-start">
                <FlipNumbers
                  height={20}
                  width={16}
                  color="#03ff00"
                  // background="black"
                  play
                  perspective={400}
                  numbers={flipNumberFormat(totalArea)+' m²'}
                />
                </div>
                <div className="text-amz3green-50 max-w-[70%] text-sm">
                  {t("dashboard.conservation-data.total-area")}
                </div>
              </div>

              <FlipCard
                ratePerMeter={RATE_PER_METER.biomass}
                label={t("dashboard.conservation-data.biomass")}
                suffix="m²"
                help={{title: t("dashboard.conservation-data.biomass"), text: t("dashboard.conservation-data.help.biomass")}}
              />
              <FlipCard
                ratePerMeter={RATE_PER_METER.vapor}
                label={t("dashboard.conservation-data.vapor")}
                suffix="m²"
                help={{title: t("dashboard.conservation-data.vapor"), text: t("dashboard.conservation-data.help.vapor")}}
              />
              <FlipCard
                ratePerMeter={RATE_PER_METER.carbon}
                label={t("dashboard.conservation-data.carbon")}
                suffix="m²"
                help={{title: t("dashboard.conservation-data.carbon"), text: t("dashboard.conservation-data.help.carbon")}}
              />
              
            </div>
            <button
              className={twMerge(
                "text-amz3green-100 font-bold flex-nowrap whitespace-nowrap shrink",
                "rounded-md border-amz3green-100 border-[1px] border-solid p-2"
              )}
              onClick={() => downloadReport()}
            >
              {t("dashboard.conservation-data.sidebar.download-report")}
            </button>
          </div>
      </section>
      <div className="w-full grow relative">
        {isDesktop ? (data && <ConservationDataMapbox data={data} />) : (
          <div className={twMerge(
            "bg-[#00000033] p-8 text-amz3green-50 text-center m-4 flex flex-col gap-2 justify-center items-center"
          )}>
            <Monitor strokeWidth={1} size={128} color="#03ff00"/>
            <p>{t('dashboard.conservation-data.mobile-disclaimer')}</p>
          </div>
        )}
      </div>
    </div>
)}