import { Link, LinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export function NavbarItem({ children, to, className, ...props }: LinkProps) {
  return (
    <Link
      className={twMerge(
        'inline-block relative after:content-[""] after:absolute after:w-full after:scale-x-0 after:h-0.5 after:bottom-0 after:left-0 after:bg-amz3white-50 after:origin-bottom-right after:transition after:transform after:duration-300 hover:after:scale-x-[1] hover:after:origin-bottom-left',
        className
      )}
      to={to}
      {...props}
    >
      {children}
    </Link>
  )
}
