import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query'
import './index.css'
import '@mantine/core/styles.css'
import App from './App'
import { Web3Provider } from './provider/Web3Provider'
import { queryClient } from './services/queryClient'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './services/hooks/auth-config'
import './i18n'

const container = document.getElementById('root');
const msalInstance = new PublicClientApplication(msalConfig)
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!
const root = createRoot(container!);
root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
     <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Web3Provider>
          <App />
        </Web3Provider>
      </QueryClientProvider>
    </MsalProvider>
  </GoogleOAuthProvider>
)
