import { z } from 'zod'
import i18n from '../../i18n'

export let resetPasswordFormSchema: z.ZodObject<
  { email: z.ZodString },
  'strip',
  z.ZodTypeAny,
  { email: string },
  { email: string }
>

i18n.on('languageChanged', language => {
  resetPasswordFormSchema = z.object({
    email: z.string().email({
      message: i18n?.t('reset-password.form.error-messages.email', {
        lng: language
      })
    })
  })
})

export type ResetPasswordFormProps = z.infer<typeof resetPasswordFormSchema>

export interface ResetPasswordErrorResponse {
  message: Message[]
}

export interface Message {
  Item: string
  Message: MessageTypeProps
}

export type MessageTypeProps = {
  pt: string
  en: string
}
