import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useTranslation } from 'react-i18next'
import { LanguageButton } from './LanguageButton'
import { useLanguage, LanguageProps } from '../../store/useLanguage'

type LanguageSelectorProps = {
  onClose: (language: LanguageProps) => void
}

export function LanguageSelector({ onClose }: LanguageSelectorProps) {
  const { t } = useTranslation()
  const { getLanguage } = useLanguage()

  const language = getLanguage()

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className='flex gap-2.5 items-center px-4 ml-4'>
          <span
            className="block w-5 h-5 ring-2 ring-white rounded-full bg-cover bg-center"
            style={{
              backgroundImage: `url(/images/flags/${language === 'en' ? 'usa' : 'brazil'}.png)`
            }}
          />
          <span className="text-base font-semibold text-white uppercase pt-0.5">
            {language || 'pt'}
          </span>
        </button>
      </Dialog.Trigger>
      <Dialog.Portal className="z-[53]">
        <Dialog.Overlay className="bg-[#00000060] fixed inset-0 z-[53]" />
        <Dialog.Content className="bg-amz3black-50 fixed left-1/2 -translate-x-1/2 top-32 rounded-[23px] w-[272px] h-[227px] z-[53]">
          <Dialog.Title className="text-amz3white-50 text-center mt-[33px] mb-[27px]">
            {t('language-selector.title')}
          </Dialog.Title>
          <div className="flex flex-col items-center gap-2">
            <Dialog.Close asChild>
              <LanguageButton onClick={() => onClose('pt')}>
                Português
              </LanguageButton>
            </Dialog.Close>
            <Dialog.Close asChild>
              <LanguageButton onClick={() => onClose('en')}>
                English
              </LanguageButton>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
