import React from 'react'
import { Document, Page, Text, StyleSheet, pdf } from '@react-pdf/renderer'
import { ActionIcon, Tooltip } from '@mantine/core'
import { DocumentDownload as DocumentDownloadIcon } from 'iconsax-react'

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingHorizontal: '48px',
    paddingVertical: '32px',
    display: 'flex'
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 12
  },
  paragraph: {
    marginTop: '12px'
  },
  strong: {
    fontWeight: 600
  }
})

const MyDocument = () => (
  <Document>
    <Page style={styles.page}>
      <>
        <Text style={styles.title}>
          TERMO DE ADESÃO ÚNICO PARA PRESTAÇÃO DE SERVIÇOS DE CONSERVAÇÃO
          AMBIENTAL E FLORESTAL DE ÁREAS DE TERRAS NA AMAZÔNIA - BRASIL
        </Text>

        <Text style={styles.paragraph}>
          O presente <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>{' '}
          juntamente com <Text style={styles.strong}>Anexo I</Text>, registrado
          sob o nº xxxx no 1º Cartório de Registro de Títulos e Documentos de
          Barueri - SP, contém as{' '}
          <Text style={styles.strong}>CONDIÇÕES GERAIS</Text> comuns a todos os
          serviços oferecidos pela{' '}
          <Text style={styles.strong}>AMAZON TREE FOREST S.A.</Text>, no âmbito
          deste instrumento.
        </Text>

        <Text style={styles.paragraph}>
          Ao optar por contratar os serviços disponibilizados pela{' '}
          <Text style={styles.strong}>AMAZON TREE FOREST S.A</Text>, no ato da{' '}
          <Text style={styles.strong}>ADESÃO</Text>, o{' '}
          <Text style={styles.strong}>CONTRATANTE</Text> concorda com as{' '}
          <Text style={styles.strong}>CONDIÇÕES GERAIS</Text> deste{' '}
          <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text> e se vincula
          também ao <Text style={styles.strong}>Anexo I</Text> que contém as{' '}
          <Text style={styles.strong}>CONDIÇÕES GERAIS</Text> aplicáveis.
        </Text>

        <Text style={styles.paragraph}>
          A <Text style={styles.strong}>AMAZON TREE FOREST S.A</Text> tem por
          objeto social o desenvolvimento e a implantação de um ecossistema
          integrado que promove iniciativas para a manutenção da conservação
          florestal da Amazônia, Brasil, com a realização de ações eficientes
          que reduzam o impacto das emissões dos gases de efeito estufa, bem
          como propagar educação ambiental e social para pessoas e empresas,
          além de também realizar a conservação ambiental e florestal de áreas
          de terras de sua propriedade ou de terceiros, na Amazônia.
        </Text>

        <Text style={styles.paragraph}>
          O presente <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>{' '}
          tem por objeto a prestação de serviços de conservação ambiental e
          florestal, de um ou mais{' '}
          <Text style={styles.strong}>MÓDULOS DE TERRA</Text>, escolhido(s) pelo{' '}
          <Text style={styles.strong}>CONTRATANTE</Text> através da{' '}
          <Text style={styles.strong}>PLATAFORMA AMAZON TREE.</Text>
        </Text>

        <Text style={styles.paragraph}>
          <Text style={styles.strong}>MÓDULO DE TERRA</Text>, significa uma área
          de terra localizada na Amazônia – Brasil, demarcada e disponibilizada
          na <Text style={styles.strong}>PLATAFORMA AMAZON TREE</Text> e
          escolhida pelo <Text style={styles.strong}>CONTRATANTE</Text>, no ato
          da assinatura do presente{' '}
          <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>, tão somente
          para fins de contratação da prestação dos serviços objeto deste{' '}
          <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO.</Text>
        </Text>

        <Text style={styles.paragraph}>
          A <Text style={styles.strong}>AMAZON TREE FOREST S.A</Text>, mediante
          a efetiva contratação dos serviços emitirá em nome do{' '}
          <Text style={styles.strong}>CONTRATANTE</Text>, via{' '}
          <Text style={styles.strong}>PLATAFORMA AMAZON TREE</Text>, certificado
          UCF (
          <Text style={styles.strong}>Unidade de Conservação Florestal</Text>)
          referente à área contratada (m²) registrada no NFT.
        </Text>

        <Text style={styles.paragraph}>
          Ao aderir ao <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>,
          o <Text style={styles.strong}>CONTRATANTE</Text> concorda que deve
          pagar os valores da <Text style={styles.strong}>CONTRAPRESTAÇÃO</Text>{' '}
          informados no site da{' '}
          <Text style={styles.strong}>AMAZON TREE FOREST S.A.</Text>
        </Text>

        <Text style={styles.paragraph}>
          O valor da <Text style={styles.strong}>CONTRAPRESTAÇÃO</Text> devida
          pelo <Text style={styles.strong}>CONTRATANTE</Text> varia de acordo
          com a quantidade e metragem, de{' '}
          <Text style={styles.strong}>MÓDULOS DE TERRAS</Text> escolhidos e
          selecionados para contratação da prestação de serviços.
        </Text>

        <Text style={styles.paragraph}>
          Por sua adesão, o <Text style={styles.strong}>CONTRATANTE</Text> se
          vincula ao presente{' '}
          <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>, contratando
          os serviços objeto deste acordo, do qual faz parte integrante e
          indissociável o <Text style={styles.strong}>Anexo I</Text> contendo as{' '}
          <Text style={styles.strong}>CONDIÇÕES GERAIS</Text>
          aplicáveis aos serviços contratados, concordando com e anuindo a seu
          inteiro teor.
        </Text>

        <Text style={styles.paragraph}>
          Este <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text> entrará
          em vigor no dia da adesão com a consolidação do pagamento da{' '}
          <Text style={styles.strong}>CONTRAPRESTAÇÃO</Text> pelo{' '}
          <Text style={styles.strong}>CONTRATANTE</Text> e vigorará pelo período
          certo e determinado de 12 (doze) meses, não sendo possível a sua
          renovação automática.
        </Text>

        <Text style={styles.paragraph}>
          O <Text style={styles.strong}>CONTRATANTE</Text> poderá a qualquer
          tempo solicitar o cancelamento dos serviços, mas não terá direito a
          qualquer reembolso da{' '}
          <Text style={styles.strong}>CONTRAPRESTAÇÃO</Text> paga. Apenas na
          hipótese da <Text style={styles.strong}>AMAZON TREE FOREST S.A.</Text>{' '}
          rescindir sem justo motivo o presente{' '}
          <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>, caberá
          restituição ao <Text style={styles.strong}>CONTRATANTE</Text> de parte
          do valor da <Text style={styles.strong}>CONTRAPRESTAÇÃO</Text> paga,
          sem qualquer atualização, de forma proporcional ao número de meses
          faltantes para encerramento do período de vigência contratual.
        </Text>

        <Text style={styles.paragraph}>
          Este <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text> obriga
          as partes, seus sucessores e cessionários a qualquer título.
        </Text>

        <Text style={styles.paragraph}>
          O presente <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>{' '}
          será regido e interpretado de acordo com as leis da República
          Federativa do Brasil, e, na eventualidade do surgimento de quaisquer
          controvérsias ou litígios em decorrência do mesmo, as questões serão
          dirimidas no Foro da Comarca de Barueri/SP, com exclusão de qualquer
          outro, por mais privilegiado que seja.
        </Text>

        <Text style={styles.paragraph}>
          O presente <Text style={styles.strong}>TERMO DE ADESÃO ÚNICO</Text>{' '}
          encontra-se registrado juntamente com o{' '}
          <Text style={styles.strong}>Anexo I</Text>, no Cartório de Registro de
          Títulos e Documentos da Comarca de Barueri, Estado de São Paulo.
        </Text>
      </>
    </Page>
  </Document>
)

type TOrderToPrint = {
  small?: boolean
}

export function OrderToPrint({ small }: TOrderToPrint) {
  const handleOpenContract = () => {
    const blob = pdf(<MyDocument />).toBlob()

    blob.then(pdfBlob => {
      const url = URL.createObjectURL(pdfBlob)
      window.open(url, '_blank')
    })
  }

  return (
    <Tooltip label="Click to open the contract">
      <ActionIcon
        size={small ? 'xs' : 'md'}
        variant="transparent"
        onClick={handleOpenContract}
      >
        <DocumentDownloadIcon size={24} />
      </ActionIcon>
    </Tooltip>
  )
}
