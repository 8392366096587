import { Whatsapp as WhatsAppIcon } from 'iconsax-react'

export function WhatsAppButton() {
  return (
    <a
      className="fixed bottom-12 right-10 z-10 bg-[#3c3c3c] w-[52px] h-[52px] rounded-full flex justify-center items-center brightness-100 hover:brightness-125 transition-all duration-300 ease-in-out drop-shadow-lg"
      href="https://wa.me/message/7BWPODFRZBBKA1"
      target="_blank"
      rel="noreferrer"
      aria-label="WhatsApp message"
    >
      <WhatsAppIcon color="white" size="1.75rem" />
    </a>
  )
}
