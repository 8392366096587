import { Image } from 'iconsax-react'

export function NFTCardDetailLoading() {
  return (
    <div
      role="status"
      className="rounded shadow animate-pulse md:p-6 dark:border-gray-700"
    >
      <div className="flex">
        <div className="flex items-center justify-center h-20 w-20 mb-4 bg-gray-300 rounded dark:bg-gray-700">
          <Image />
          <div></div>
        </div>
        <div className=" ml-4 mt-2">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
        </div>
      </div>
      <div className="flex items-center mt-4 gap-8 space-x-3">
        <div className="flex flex-col items-center">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-2"></div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-2"></div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
        </div>
        <div className="flex flex-col items-center">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-2"></div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
