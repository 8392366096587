import { MapNFT } from '../../models/map-nft'
import { NFTCardDetail } from '../NFTCatalogResume/components'
import { useNFTOwner } from '../../services/hooks/useNFTOwner'
import { useEffect } from 'react'
import { useCurrencyExchange } from '../../services/hooks/useCurrencyExchange'
import { useCartStore } from '../../store/useCart'

type PopupMapProps = {
  nft: MapNFT,
  artisticImage?: string | null
  onAdd?: () => void
  onDelete?: () => void
  onDownload?: (nftId: string, nftName: string) => void
}

export function PopupMap({ nft, artisticImage, onAdd, onDelete, onDownload }: PopupMapProps) {
  const { currency } = useCartStore()
  const { data: currencyValue } = useCurrencyExchange(currency)
  const {
    data: nftOwnerData,
    refetch,
    isLoading,
    isFetching,
    isRefetching
  } = useNFTOwner(nft.projectAreaId)

  useEffect(() => {
    if (nft.id) {
      refetch()
    }
  }, [nft.id, refetch])

  return (
    <NFTCardDetail
      id={nft.id}
      name={nft.name}
      image={artisticImage || nft.image}
      artisticImage={artisticImage}
      nftPosition={nft.nftPosition}
      edition={nft.edition}
      area={nft.areaInSquareMeters}
      nftOwner={nftOwnerData}
      isLoading={isLoading || isFetching || isRefetching}
      price={Number((nft.price * (currencyValue || 1)).toFixed(2))}
      status={nft.status}
      currency={currency}
      isPopUp
      onAdd={onAdd}
      onDelete={onDelete}
      onDownload={onDownload}
    />
  )
}
