import React from 'react'
import { twMerge } from 'tailwind-merge'

export type MapSelectorProps = {
  index: number;
  selected: number;
  label: string;
  onClick: () => void;
}

export function MapSelector({ index, selected, label, onClick }: MapSelectorProps) {
  
  return (
    <button
      onClick={()=>onClick()}
      className={twMerge(
        'bg-amz3green-300 text-amz3green-50 min-w-[100px] text-left px-2 py-1',
        'flex flex-row gap-2 leading-none select-none',
        index !== selected && 'bg-opacity-50'
      )}
    >
      <svg width="10" height="10">
        <rect width="10" height="10" className={index === selected ? 'fill-amz3green-100' : 'fill-amz3black-300 opacity-50'} />
      </svg>
      <span>
        {label}
      </span>
    </button>
  )
}
