import { ReactNode, useRef } from 'react'
import BackgroundTree from '../../assets/images/background-solutions.png'
import { revealFade } from '../../motion'
import { useInView } from 'framer-motion'

type SignInDivProps = {
  children: ReactNode
  id: string
}

export function SignInDiv({ children, id }: SignInDivProps) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true, amount: .3 });
  return (
    <section
      id={id}
      ref={ref}
      style={{
        // @ts-ignore
        '--bg-image-url': `url(${BackgroundTree})`,
        ...revealFade(isInView, 3)
      }}
      className={`bg-amz3black-500 bg-none h-full md:bg-[image:var(--bg-image-url)] md:bg-no-repeat md:bg-cover md:bg-center flex items-center justify-center`}
    >
      <div className="w-[28rem] max-w-[95%] text-gray-200 bg-amz3black-500 p-10 rounded-lg">
        {children}
      </div>
    </section>
  )
}
