import { StateCreator, create } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

type PrivacyPolicyState = {
  isPrivacyPolicyOpen: boolean
  setIsPrivacyPolicyOpen: (isPrivacyPolicyOpen: boolean) => void
}

type PersistProps = (
  config: StateCreator<PrivacyPolicyState>,
  options: PersistOptions<PrivacyPolicyState>
) => StateCreator<PrivacyPolicyState>

export const privacyPolicyStore = create<PrivacyPolicyState>(
  (persist as PersistProps)(
    set => {
      return {
        isPrivacyPolicyOpen: true,
        setIsPrivacyPolicyOpen: (isPrivacyPolicyOpen: boolean) =>
          set(() => ({
            isPrivacyPolicyOpen
          }))
      }
    },
    {
      name: 'privacy-policy-storage'
    }
  )
)

export const usePrivacyPolicy = privacyPolicyStore
