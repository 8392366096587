import {
  Accordion,
  // Card,
  // Grid,
  Text,
  Flex,
  Button,
  // ActionIcon,
  // Tooltip
} from '@mantine/core'
import { MapNFT } from '../../../../../models/map-nft'
import {
  // Activity as ActivityIcon,
  ArrowLeft as ArrowLeftIcon,
  DocumentText as DocumentTextIcon,
  TableDocument as TableDocumentIcon,
  Copy as CopyIcon,
  Calendar2 as Calendar2Icon,
  Ruler as RulerIcon,
} from 'iconsax-react'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import { History } from './history.component'
import { RescueButton } from '../../../components/RescueButton'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import { useFeedMapsByUser } from '../../../../../services/hooks/useNFTs'
import { GetNFTOwnerResponseProps } from '../../../../../services/hooks/useNFTOwner'
import { api } from '../../../../../services/api'
import { goToSolscanAccount, goToSolscanToken } from '../../../../../utils/goToSolscan'
import { toast } from 'react-toastify'
import { DateConverter } from '../../../../../utils/date-converter'
import { ReactComponent as Solscan } from '../../../../../assets/icons/solscan.svg'

// const attributes = [
//   { name: 'Country', description: 'Brasil' },
//   { name: 'Region', description: 'Amazonas' },
//   { name: 'City', description: 'Rio Preto da Eva' },
//   { name: 'Owner', description: ' AmazonTreeSA' },
//   { name: 'Area in Square Meters', description: '256' },
//   { name: 'Edition', description: '2023' }
// ]


export function CertificateDetail() {

  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  let { id } = useParams();

  const [nft, setNFTData] = useState<MapNFT>()
  const [nftOwner, setNftOwner] = useState<GetNFTOwnerResponseProps | null>(null)
  const { data: nftDataReactQuery } = useFeedMapsByUser()

  useEffect(() => {
    if (id && nftDataReactQuery) {
      const nftFind = nftDataReactQuery.find(nft => nft.id === id)
      if (nftFind) {
        setNFTData(nftFind)
        console.log(nftFind)
      }
    }
  }, [nftDataReactQuery, id])

  useEffect(() => {
    const getNFTOwner = async () => {
      if (nft?.projectAreaId) {
        try {
          const { data: nftOwner } = await api.get<GetNFTOwnerResponseProps>(
            `/nfts/nft-owner/${nft?.projectAreaId}`
          )
          setNftOwner(nftOwner)
          console.log(nftOwner)
        } catch (error) {
          setNftOwner(null)
        }
      }
    }

    getNFTOwner()
  }, [nft])

  async function downloadCertificate() {
    try {
      // Ensure the NFT ID is available
      if (!nft?.id) {
        throw new Error('NFT ID is missing.');
      }
  
      // Make a GET request with Axios
      const response = await api.get(`/nfts/generate-pdf-certificate/${nft.id}`, {
        responseType: 'blob', // Specify the response type as blob
      });
  
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
  
      // Ensure the NFT name contains a hash
      const name = nft?.name?.split('#')[1] || 'Certificate';
      a.download = `AmazonTree-Token-${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading certificate:', error);
      // Optionally, show an error notification to the user
    }
  }

  return nft ? (
    <Flex maw="75rem" direction="column" mx="auto">
      <button
        onClick={() => navigate(-1)}
        className="flex text-zinc-50 my-4 gap-2 ml-6 md:ml-0 uppercase"
      >
        <ArrowLeftIcon size={24} />
        <span> {i18n.language === 'en' ? 'back' : 'voltar'}</span>
      </button>
      <section
        className='flex flex-col lg:flex-row my-4 gap-4 pr-8'
      >

        {/* FIGURE */}

        <figure className="w-[320px] lg:w-[480px] max-w-full shrink-0 pb-8 lg:px-16 mx-auto">
          <img
            src={nft?.artisticImage || nft?.image}
            alt="Token"
          />
        </figure>

        {/* COLUMN INFO */}

        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <Text
              size="lg"
              c="gray.1"
              fw={600}
            >
              {/* TODO: remove #0000000001 on backend */}
              {nft.name.replace('#0000000001', '')}
            </Text>
            <Text size="md" c="gray.1">
              {nft.description}
            </Text>
            <Button
              color="amz3-green.1"
              variant="outline"
              radius="lg"
              onClick={() => downloadCertificate()}
            >
              {t('dashboard.buttons.download-certificate')}
            </Button>
            <RescueButton transferredToUserWallet={nft.transferredToUserWallet ?? false} primary nftToken={'nftToken' || ''} />
          </div>

          {/* ATTRIBUTES */}

          <div
            className='flex flex-col gap-4'
          >
            <Accordion variant="separated" defaultValue="attributes">
              <Accordion.Item
                value="attributes"
                c="gray.1"
                style={{
                  background: 'transparent',
                  border: '0.5px solid white',
                  borderRadius: "16px",
                }}
              >
                <Accordion.Control
                  icon={<DocumentTextIcon size="1.125rem" />}
                  c="gray.1"
                >
                  Attributes
                </Accordion.Control>
                <Accordion.Panel>


                  <div className="flex justify-start flex-wrap gap-4">

                    <div className="flex items-center gap-1 bg-[rgba(0,0,0,.2)] rounded-full py-1 px-2 text-xs font-medium">
                      <Calendar2Icon size={14} />
                      <span>{t('dashboard.my-certificates.nft.edition')}: {nft?.edition}</span>
                    </div>
                    <div className="flex items-center gap-1 bg-[rgba(0,0,0,.2)] rounded-full py-1 px-2 text-xs font-medium">
                      <RulerIcon size={14} />
                      <span>{`${nft?.areaInSquareMeters} m²`}</span>
                    </div>
                    {nft.soldDate && (
                      <div className="flex items-center gap-1 bg-zinc-700 rounded-full py-1 px-2 text-xs font-medium">
                        <Calendar2Icon size={14} />
                        <span>{t('dashboard.my-certificates.nft.minted')}: {DateConverter.ConvertSystemDateFormatToBRLDate(nft.soldDate)}</span>
                      </div>
                    )}
                    {nft.expireDate && (
                      <div className="flex items-center gap-1 bg-zinc-700 rounded-full py-1 px-2 text-xs font-medium">
                        <Calendar2Icon size={14} />
                        <span>{t('dashboard.my-certificates.nft.expiration')}: {DateConverter.ConvertSystemDateFormatToBRLDate(nft.expireDate)}</span>
                      </div>
                    )}

                  </div>

                  {/* PREVIOUS LAYOUT */}
                  {/* <Grid gutter="xs">
                    {attributes.map((attribute, index) => (
                      <Grid.Col
                        key={`${index}-${attribute.name}`}
                        span={{ base: 12, md: 4 }}
                      >
                        <Card bg="dark.5" w="100%" h="100%">
                          <Text size="sm" c="gray.2" fw={300}>
                            {attribute.name}
                          </Text>
                          <Text size="md" c="gray.1" fw={700}>
                            {attribute.description}
                          </Text>
                        </Card>
                      </Grid.Col>
                    ))}
                  </Grid> */}

                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>

            {/* DETAILS */}

            <Accordion variant="separated" defaultValue="details">
              <Accordion.Item
                value="details"
                c="gray.1"
                style={{
                  background: 'transparent',
                  border: '0.5px solid white',
                  borderRadius: "16px",
                }}
              >
                <Accordion.Control
                  icon={<TableDocumentIcon size="1.125rem" />}
                  c="gray.1"
                >
                  Details
                </Accordion.Control>
                <Accordion.Panel>

                  {nftOwner && (
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-1  w-full">
                        <Link
                          to={goToSolscanAccount(nftOwner?.owner)}
                          target="_blank"
                          className="flex items-center gap-2 text-sm font-semibold overflow-hidden grow shrink"
                          title="Go to account"
                        >
                          <Solscan className='w-[16px] h-[16px]' />
                          <span className="truncate">
                            Owner: {nftOwner?.owner}
                          </span>
                        </Link>
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(nftOwner?.owner)
                            toast.success(
                              t('catalog.nft-card-detail-copy-owner-success'),
                              {
                                position: 'top-center',
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                theme: 'dark'
                              }
                            )
                          }}
                        >
                          <CopyIcon size={20} />
                        </button>
                      </div>
                      <div className="flex items-center gap-1 w-full">
                        <Link
                          to={goToSolscanToken(nftOwner?.nftToken)}
                          target="_blank"
                          className="flex items-center gap-2 text-sm font-semibold overflow-hidden grow shrink"
                          title="Go to NFT Token"
                        >
                          <Solscan className='w-[16px] h-[16px]' />
                          <span className="truncate">
                            Token: {nftOwner?.nftToken}
                          </span>
                        </Link>
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(nftOwner?.nftToken)
                            toast.success(
                              t('catalog.nft-card-detail-copy-token-success'),
                              {
                                position: 'top-center',
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                theme: 'dark'
                              }
                            )
                          }}
                        >
                          <CopyIcon size={20} />
                        </button>
                      </div>
                    </div>
                  )}

                  {/* OLD */}

                  {/* <Flex direction="column">
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Token Address
                      </Text>
                      <Flex gap="2px" align="center">
                        <Tooltip label="Open in solscan">
                          <ActionIcon
                            variant="transparent"
                            component="a"
                            href="https://solscan.io/account/CZGk2JAoQwvDb32h5kowK6zDjJ6EmX1cppVDSLmjPdXJ?cluster=devnet"
                            target="_blank"
                          >
                            <img src={Solscan} alt="solscan" className="w-4" />
                          </ActionIcon>
                        </Tooltip>
                        <Text size="sm" fw={700}>
                          4YiNRx...21MjNW
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Mint Authority
                      </Text>
                      <Flex gap="2px" align="center">
                        <Tooltip label="Open in solscan">
                          <ActionIcon
                            variant="transparent"
                            component="a"
                            href="https://solscan.io/account/CZGk2JAoQwvDb32h5kowK6zDjJ6EmX1cppVDSLmjPdXJ?cluster=devnet"
                            target="_blank"
                          >
                            <img src={Solscan} alt="solscan" className="w-4" />
                          </ActionIcon>
                        </Tooltip>
                        <Text size="sm" fw={700}>
                          DJo3Hr...5VqkCx
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Freeze Authority
                      </Text>
                      <Flex gap="2px" align="center">
                        <Tooltip label="Open in solscan">
                          <ActionIcon
                            variant="transparent"
                            component="a"
                            href="https://solscan.io/account/CZGk2JAoQwvDb32h5kowK6zDjJ6EmX1cppVDSLmjPdXJ?cluster=devnet"
                            target="_blank"
                          >
                            <img src={Solscan} alt="solscan" className="w-4" />
                          </ActionIcon>
                        </Tooltip>
                        <Text size="sm" fw={700}>
                          DJo3Hr...5VqkCx
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Update Authority
                      </Text>
                      <Flex gap="2px" align="center">
                        <Tooltip label="Open in solscan">
                          <ActionIcon
                            variant="transparent"
                            component="a"
                            href="https://solscan.io/account/CZGk2JAoQwvDb32h5kowK6zDjJ6EmX1cppVDSLmjPdXJ?cluster=devnet"
                            target="_blank"
                          >
                            <img src={Solscan} alt="solscan" className="w-4" />
                          </ActionIcon>
                        </Tooltip>
                        <Text size="sm" fw={700}>
                          AMZ3qL...qTXhHz
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Owner Program
                      </Text>
                      <Text size="sm" fw={700}>
                        Token Program
                      </Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        SOL Balance
                      </Text>
                      <Text size="sm" fw={700}>
                        0.0014616 SOL
                      </Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text size="sm" fw={700}>
                        Current Supply
                      </Text>
                      <Text size="sm" fw={700}>
                        1
                      </Text>
                    </Flex>
                  </Flex> */}

                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>

      {/* start of token history */}
      {/* <History /> */}
      {/*
      <Flex direction="column" gap="md" justify="center" mt="md" w="100%">
        <Accordion variant="separated" defaultValue="activities" maw="100%">
          <Accordion.Item
            value="activities"
            c="gray.1"
            bg="transparent"
            style={{
              border: '0.5px solid white'
            }}
          >
            <Accordion.Control
              icon={<ActivityIcon size="1.125rem" />}
              c="gray.1"
            >
              Activities
            </Accordion.Control>
            <Accordion.Panel>
              <Table.ScrollContainer minWidth="60%">
                <Table className="whitespace-nowrap">
                  <Table.Thead>
                    <Table.Tr tt="uppercase">
                      <Table.Th></Table.Th>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Type</Table.Th>
                      <Table.Th>Total</Table.Th>
                      <Table.Th>Seller</Table.Th>
                      <Table.Th>Buyer</Table.Th>
                      <Table.Th>Time</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tr>
                    <Table.Td>
                      <Avatar radius="sm" src={nft.image}>
                        AMZ
                      </Avatar>
                    </Table.Td>
                    <Table.Td>AMZ3 SUMAUMAI #0000000158</Table.Td>
                    <Table.Td>
                      <Badge color="green" size="lg" radius="sm">
                        Mint
                      </Badge>
                    </Table.Td>
                    <Table.Td>184.1 SOL</Table.Td>
                    <Table.Td>asdf123SD123fdas123</Table.Td>
                    <Table.Td>asdf123SD123fdas123</Table.Td>
                    <Table.Td>2d</Table.Td>
                  </Table.Tr>
                </Table>
              </Table.ScrollContainer>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
      */}

      {/* end of token history */}
    </Flex>
  ) : null;
}
