import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Router } from './routes'
import { useEffect } from 'react'
import { useLanguage } from './store/useLanguage'
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { usePrivacyPolicy } from './store/usePrivacyPolicy'
import { MantineProvider, createTheme } from '@mantine/core'
import { ToastContainer } from 'react-toastify'
import { Spinner } from './components/Spinner'
import { WhatsAppButton } from './components/WhatsAppButton'

const theme = createTheme({
  fontFamily: 'Sora',
  colors: {
    'amz3-green': [
      '#fcf2c8',
      '#fcf2c8',
      '#03ff00',
      '#03ff00',
      '#02c600',
      '#02c600',
      '#028e00',
      '#028e00',
      '#0a230f',
      '#0a230f'
    ]
  }
})
function App() {
  const { language } = useLanguage()
  const {
    i18n: { changeLanguage }
  } = useTranslation()
  const { isPrivacyPolicyOpen, setIsPrivacyPolicyOpen } = usePrivacyPolicy()
  useEffect(() => {
    changeLanguage(language)
    document.documentElement.lang = language
  }, [changeLanguage, language])

  function handleClosePrivacyPolicy() {
    setIsPrivacyPolicyOpen(false)
  }
  return (
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <PrivacyPolicy
          isOpen={isPrivacyPolicyOpen}
          onSubmit={handleClosePrivacyPolicy}
        />
        <Spinner />
        <Router />
        <ToastContainer />
        <WhatsAppButton />
      </BrowserRouter>
    </MantineProvider>
  )
}
export default App