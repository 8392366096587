import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import Figure from '../../../assets/v2/figure-amazon-1.jpg'
import { revealZoomOut, revealFade } from "../../../motion";
import { useTranslation } from "react-i18next";

export function SectionGreenIs () {
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const { t } = useTranslation()
  
  return (
    <section
      ref={ref}
      className='lg:h-[80dvh] bg-black'
      style={{
        background: `url(${Figure}) center center no-repeat fixed`,
        backgroundSize: "cover",
        ...revealFade(isInView, 2)
      }}

    >
      <div className='w-full h-full flex flex-col justify-center items-center bg-black/50'>
        <h1
          style={revealZoomOut(isInView, 2, 0.5)}
          className={cx(
            'p-10 py-20 sm:p-20  w-[80%] max-w-[400px]',
            'text-amz3green-100 text-center font-semibold text-6xl sm:text-7xl'
          )}>
          {t('about-token.green-is.title')}
        </h1>
      </div>
    </section>
  )
}