import { LogLevel } from '@azure/msal-browser'

const clientId = process.env.REACT_APP_CLIENT_ID ?? ''
const redirectUri = '/'
export const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize/`,
    redirectUri
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel | any,
        message: string | any,
        containsPii: boolean | any
      ) => {
        if (containsPii) {
          return;
        }
      
      }
    }
  }
}


// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
//   graphMePictureEndpoint: 'https://graph.microsoft.com/v1.0/me/photos/48x48'
// }
export const loginRequest = {
  scopes: ["User.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me", //e.g. https://graph.microsoft.com/v1.0/me,
  graphMePictureEndpoint: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value'
};