type NFTInfoTextProps = {
  text: string
  subtext: string
}

export function NFTInfoText({ text, subtext }: NFTInfoTextProps) {
  return (
    <div className="flex flex-col items-center">
      <span className="text-amz3green-50 font-bold text-6xl">{text}</span>
      <span className="text-amz3green-150 font-medium max-w-[280px] text-center">
        {subtext}
      </span>
    </div>
  )
}
