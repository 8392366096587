import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { AnimatedGuide } from '../../components/NFTCatalog/AnimatedGuide'

type NFTAnimateGuideContentProps = {
  className?: string
}

export function NFTAnimateGuideContent({ className }: NFTAnimateGuideContentProps) {
  const { t } = useTranslation()

  return (
    <div className={twMerge(className)}>
      <div className="flex flex-col gap-4">
        <h2 className="text-amz3green-50 text-center text-xl lg:text-left lg:text-2.5xl lg:mb-4">
          <span>{t('catalog-tutorial.title.one')}</span>
          {' '}
          <span className="text-amz3green-100 font-semibold">
            {t('catalog-tutorial.title.two')}
          </span>
        </h2>
        <AnimatedGuide />
      </div>
    </div>
  )
}
