import { useRef } from "react";
import { useInView } from "framer-motion";
import { useTranslation } from 'react-i18next'
import FigureBg from '../../../assets/v2/figure-amazon-2.jpg'
import FigureTokens from '../../../assets/v2/tokens-1.png'
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from '../../../routes'
import { revealZoomOut, revealSlideUp } from "../../../motion";

export function SectionToken () {
  

  const refImage = useRef(null);
  const refText = useRef(null);
  const isInViewImage = useInView(refImage, { once: true, amount: .3 });
  const isInViewText = useInView(refText, { once: true, amount: .3 });
  
  const { t } = useTranslation()

  return (
    <section
      className='lg:min-h-[80dvh] grid grid-cols-1 md:grid-cols-8 bg-black'
      style={{
        background: `url(${FigureBg}) center center no-repeat fixed`,
        backgroundSize: "cover",
      }}
    >
      <div ref={refImage} className='md:col-span-5 p-20 flex flex-col justify-center items-center'>
        <img style={revealZoomOut(isInViewImage, 2, 0.5)} alt="AMZ3 Tokens" src={FigureTokens} className='w-[540px] h-auto max-w-full'/>
      </div>
      <div ref={refText} className='p-10 py-20 md:p-20 md:col-span-3 flex flex-col justify-center items-start'>
        <h3 className='text-4xl mb-8' style={revealSlideUp(isInViewText, 1, 1)}>
          <span className='text-amz3green-100'>{t('home.token.title1')}</span>{' '}
          <span className='text-amz3green-50'>{t('home.token.title2')}</span>
        </h3>
        <p className='text-white mb-8' style={revealSlideUp(isInViewText, 1, 1.5)}>{t('home.token.first-paragraph')}</p>
        <p className='mb-8' style={revealSlideUp(isInViewText, 1, 2)}>
          <Link
            className='button-green'
            to={ROUTES_PATH.TOKEN.path}
          >
            {/* <span className='mr-4'>{`\u2192`}</span> */}
            {t('home.token.cta')}
          </Link>
        </p>
      </div>
    </section>
  )
}