import { StateCreator, create } from 'zustand'
import { PersistOptions, persist } from 'zustand/middleware'

type useTermsState = {
  isUseTermsAccepted: boolean
  setIsUseTermsAccepted: (isUseTermsAccepted: boolean) => void
}

type PersistProps = (
  config: StateCreator<useTermsState>,
  options: PersistOptions<useTermsState>
) => StateCreator<useTermsState>

export const termsStore = create<useTermsState>(
  (persist as PersistProps)(
    set => {
      return {
        isUseTermsAccepted: false,
        setIsUseTermsAccepted: (isUseTermsAccepted: boolean) =>
          set(() => ({
            isUseTermsAccepted
          }))
      }
    },
    {
      name: 'terms-storage'
    }
  )
)

export const useTerms = termsStore
