import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import { revealFade, revealSlideUp } from "../../../motion";
import { useTranslation } from "react-i18next";

import Icon1 from '../../../assets/v2/solana-step-1.svg'
import Icon2 from '../../../assets/v2/solana-step-2.svg'
import Icon3 from '../../../assets/v2/solana-step-3.svg'
import Icon4 from '../../../assets/v2/solana-step-4.svg'

interface ColumnProps {
  svg:any;
  data:DataProps;
  delay:number;
}

interface DataProps {
  title: string;
  text: string[];
}

function Column({ svg, data, delay }:Readonly<ColumnProps>){
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  return(
    <div
      className="flex flex-col items-center justify-start gap-4 text-sm"
      ref={ref}
    >
      <img alt={data?.title} src={svg} style={revealSlideUp(isInView, 1, delay)} />
      <h3 style={revealSlideUp(isInView, 1, delay + 0.5)} className='text-amz3green-50 text-2xl font-semibold my-4 text-center'>{data?.title}</h3>
      <div style={revealFade(isInView, 1, delay + 1.5)} >
        {data?.text.map((text, i) => {
          if(text.startsWith("highlight::")){
            return <p className="text-amz3green-50 mb-4 font-medium" key={i}>{text.replace("highlight::","")}</p>;
          }
          return <p className="text-white mb-4" key={i}>{text}</p>
        })}
      </div>
    </div>
  )
}

export function SectionPaymentSolana () {
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const { t } = useTranslation()

  return (
    <section
      ref={ref}
      className={cx(
        'py-32 p-16 lg:min-h-[80dvh]  bg-black text-amz3green-100',
        'flex flex-col gap-8 justify-center items-center'
      )}
    >
      <div className="w-full max-w-[1100px] flex flex-col gap-16">
        <div className='text-center'>
          <h2 className="text-amz3green-100" style={revealSlideUp(isInView, 1, 0.5)}>{t('about-token.payment-solana.title')}</h2>
          <h3 className='text-4xl text-white mb-8 font-semibold' style={revealSlideUp(isInView, 1, 1)}>
            <span className="text-amz3green-50">
              {t('about-token.payment-solana.subtitle1')}
            </span>{' '}
            <span className="text-amz3green-100">
              {t('about-token.payment-solana.subtitle2')}
            </span>
          </h3>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'>
          <Column svg={Icon1} data={t('about-token.payment-solana.topic1', { returnObjects: true })} delay={1.0}/>
          <Column svg={Icon2} data={t('about-token.payment-solana.topic2', { returnObjects: true })} delay={1.2}/>
          <Column svg={Icon3} data={t('about-token.payment-solana.topic3', { returnObjects: true })} delay={1.4}/>
          <Column svg={Icon4} data={t('about-token.payment-solana.topic4', { returnObjects: true })} delay={1.6}/>
        </div>
      </div>
    </section>
  )
}