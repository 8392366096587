import { User as UserIcon } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import {
  FormAutocomplete,
  Form,
  FormInput,
  FormSubmit
} from '../../../components'
import { SecondStepFormProps, secondStepFormSchema } from './types'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { searchZIPCode } from '../../../utils/searchZIPCode'
import { SignUpPayloadProps } from '../types'
import { maskCEP, maskCPF } from '../../../utils/masks'
import { api } from '../../../services/api'
import { GoogleAddressResult } from '../../../models/google-address-api.type'

export interface CountryDetailProps {
  cca2: string
  cca3: string
  flag: string
  en: string
  pt: string
  ddi: string
  postalCode: {
    format: string
    regex: string
  }
}

type SecondStepProps = {
  payload: SignUpPayloadProps
  handleClearCountry: () => void
  onSubmit: (data: SignUpPayloadProps) => void
}

export function SecondStep({ onSubmit, payload, handleClearCountry }: SecondStepProps) {
  const [selectedCountry, setSelectedCountry] = useState<CountryDetailProps>(
    {} as CountryDetailProps
  )
  const { t } = useTranslation()
  const secondStepForm = useForm<SecondStepFormProps>({
    resolver: zodResolver(secondStepFormSchema),
    defaultValues: {
      zipCode: '',
      street: '',
      district: '',
      city: '',
      state: '',
      number: '',
      complement: '',
      cpf: '',
      phone: '',
      country: ''
    }
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue
  } = secondStepForm

  const handleOnSubmit = useCallback(
    async (data: SecondStepFormProps) => {
      const {
        zipCode,
        number,
        complement,
        cpf,
        phone,
        city,
        district,
        state,
        street
      } = data

      // return console.log({ ...data })

      const payload: SignUpPayloadProps = {
        address: {
          zipCode,
          street,
          number,
          complement,
          neighborhood: district,
          city,
          state,
          countryCode: selectedCountry?.cca3 || ''
        },
        cpf,
        phoneNumber: phone,
        phoneDdi: selectedCountry?.ddi || ''
      }

      onSubmit(payload)
    },
    [onSubmit, selectedCountry?.cca3, selectedCountry?.ddi]
  )

  async function handleZipCode(zipCode: string) {
    const response = await searchZIPCode(`${zipCode}+${selectedCountry.en}`)
    if (response) {
      setValue(
        'street',
        extractStreetName(response)
      )
      setValue(
        'district',
        extractDistrictName(response)
      )
      setValue(
        'city',
        extractCityName(response)
      )
      setValue(
        'state',
        response?.address_components?.find(el =>
          el.types.includes('administrative_area_level_1')
        )?.long_name || ''
      )
    } else {
      setValue('street', '')
      setValue('district', '')
      setValue('city', '')
      setValue('state', '')
    }
  }

  function extractCityName(googleAddressResult: GoogleAddressResult): string {
    const { address_components } = googleAddressResult
    let cityName = address_components.find(
      el => el.types.includes('administrative_area_level_2'))?.long_name
    if (cityName) return cityName
    cityName = address_components.find(
      el => el.types.includes('political'))?.long_name
    if (cityName) return cityName
    cityName = address_components.find(
      el => el.types.includes('locality'))?.long_name
    return cityName || ''
  }
  function extractStreetName(googleAddressResult: GoogleAddressResult): string {
    const { address_components,formatted_address } = googleAddressResult
    let streetName = address_components.find(
      el => el.types.includes('route'))?.long_name
    if (streetName) return streetName
    streetName = address_components.find(
      el => el.types.includes('street_address'))?.long_name
    if (streetName) return streetName
    streetName = address_components.find(
      el => el.types.includes('locality'))?.long_name
    return streetName || formatted_address.split(",")[0] || ''
  }
  function extractDistrictName(googleAddressResult: GoogleAddressResult): string {
    const { address_components } = googleAddressResult
    let districtName = address_components.find(
      el => el.types.includes('administrative_area_level_3'))?.long_name
    if (districtName) return districtName
    districtName = address_components.find(
      el => el.types.includes('sublocality_level_1'))?.long_name
    if (districtName) return districtName
    districtName = address_components.find(
      el => el.types.includes('sublocality'))?.long_name
    return districtName || ''
  }
  function handleCPFMask(cpf: string) {
    const maskedCPF = maskCPF(cpf)
    setValue('cpf', maskedCPF)
  }

  function handleCEPMask(cep: string) {
    if (selectedCountry.cca3 === 'BRA') {
      setValue('zipCode', maskCEP(cep))
    } else {
      setValue('zipCode', cep)
    }
  }

  const handleSelectCountry = useCallback(
    async (countryCode: string) => {
      if (!selectedCountry?.cca3) {
        const { data } = await api.get<CountryDetailProps>(
          `accounts/countries-details/${countryCode}`
        )

        if (data?.cca3) {
          setSelectedCountry(data)
          // console.log(data)
        }
      }
    },
    [selectedCountry?.cca3]
  )

  return (
    <Form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="h-full pb-6 !overflow-visible"
    >
      <FormProvider {...secondStepForm}>
        <div className="flex flex-col gap-4 md:flex-row items-center h-full">
          <FormAutocomplete
            inputName="country"
            className="h-12 text-base placeholder:text-base"
            placeholder={t('sign-up.form.country')}
            errorMessage={errors.country?.message}
            onSelectCountry={handleSelectCountry}
            disabled={!!selectedCountry?.cca3}
            {...register('country')}
          />
          {selectedCountry?.cca3 && (
            <FormInput
              inputName="zipCode"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.zip-code')}
              errorMessage={errors.zipCode?.message}
              {...register('zipCode')}
              onBlur={event => handleZipCode(event.target.value)}
              onChange={event => handleCEPMask(event.target.value)}
            />
          )}
        </div>

        {selectedCountry?.cca3 && (
          <>
            <FormInput
              inputName="street"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.street')}
              errorMessage={errors.street?.message}
              {...register('street')}
            />
            <FormInput
              inputName="district"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.district')}
              errorMessage={errors.district?.message}
              {...register('district')}
            />
            <div className="flex flex-col gap-4 md:flex-row">
              <FormInput
                inputName="number"
                className="h-12 text-base placeholder:text-base w-full"
                placeholder={t('sign-up.form.number')}
                errorMessage={errors.number?.message}
                {...register('number')}
              />
              <FormInput
                inputName="complement"
                className="h-12 text-base placeholder:text-base w-full"
                placeholder={t('sign-up.form.complement')}
                errorMessage={errors.complement?.message}
                {...register('complement')}
              />
            </div>
            <FormInput
              inputName="city"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.city')}
              errorMessage={errors.city?.message}
              {...register('city')}
            />
            <FormInput
              inputName="state"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-up.form.state')}
              errorMessage={errors.state?.message}
              {...register('state')}
            />
            {selectedCountry?.cca3 === 'BRA' && (
              <FormInput
                inputName="cpf"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('sign-up.form.cpf')}
                errorMessage={errors.cpf?.message}
                {...register('cpf')}
                onChange={event => handleCPFMask(event.target.value)}
              />
            )}
            <div className="flex gap-2">
              <span className="flex items-center justify-center rounded-2lg px-4 border border-amz3green-50 bg-transparent text-amz3white-50 h-12 text-base placeholder:text-base">
                {selectedCountry?.ddi}
              </span>
              <FormInput
                inputName="phone"
                className="h-12 text-base placeholder:text-base"
                placeholder={t('sign-up.form.phone')}
                errorMessage={errors.phone?.message}
                {...register('phone')}
              />
            </div>
            <FormSubmit
              isSubmitting={isSubmitting}
              className="min-h-[3rem] text-base"
            >
              <span className="font-medium text-base">
                {t('sign-up.form.submit-btn')}
              </span>
              <UserIcon />
            </FormSubmit>
          </>
        )}
      </FormProvider>
      {!!selectedCountry.cca3 && (
        <button
          className="absolute top-8 left-8 w-8 h-8 text-inherit hover:text-amz3green-100 transition-all duration-200 ease-in leave:duration-200 leave:ease-in"
          onClick={handleClearCountry}
        >
          <ChevronLeftIcon width={32} color="inherit" />
        </button>
      )}
    </Form>
  )
}
