import { useState } from 'react'
import { Header, Menu } from './components'
import { Outlet } from 'react-router-dom';

function MainLayout() {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <>
    {openMenu ? (
      <Menu onClose={() => setOpenMenu(false)} />
    ) : (
      <Header onOpenMenu={() => setOpenMenu(true)} />
    )}
      <main id="main-layout" className="bg-amz3black-500 h-full">
        <Outlet/>
      </main>
    </>
  )
}

export default MainLayout
