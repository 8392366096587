import { ArrowRight2, BitcoinCard } from 'iconsax-react'

type NFTStepGuideProps = {
  title: string
  description: string
  subDescription?: string
  hasNextStep?: boolean
}

export function NFTStepGuide({
  title,
  description,
  subDescription,
  hasNextStep
}: NFTStepGuideProps) {
  return (
    <div
      style={{
        flex: '0 0 auto'
      }}
    >
      <div className="flex items-center gap-4">
        <div className="bg-[#70CD5C] w-24 h-24 rounded-[20px] flex items-center justify-center">
          <BitcoinCard size={40} color="#000" />
        </div>
        <span className="text-amz3green-50 font-semibold text-2xl max-w-[160px]">
          {title}
        </span>
        {hasNextStep && (
          <div className="flex">
            <ArrowRight2 className="text-amz3green-150" size="20" />
            <ArrowRight2 className="text-amz3green-150 -ml-3" size="20" />
            <ArrowRight2 className="text-amz3green-150 -ml-3" size="20" />
          </div>
        )}
      </div>
      <p className="mt-8 text-sm text-amz3white-50 max-w-[240px]">
        {description}
      </p>
      <p className="mt-8 text-sm text-amz3white-50 max-w-[240px]">
        {subDescription}
      </p>
    </div>
  )
}
