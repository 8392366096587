import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import { revealSlideUp, revealFade } from "../../../motion";
import { useTranslation } from "react-i18next";

export function SectionMetaverse () {
  
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: .3 });
  const { t } = useTranslation()
  
  return (
    <section
      ref={ref}
      className={cx(
        'relative w-full overflow-hidden lg:min-h-[80dvh] bg-amz3green-300',
        'p-16 grid md:grid-cols-2 lg:grid-cols-3 grid-rows-2'
      )}
    >
        <div
          className='w-full h-full absolute top-0 left-0 bg-black'
          style={{...revealFade(isInView,3)}}
        >
          <video
            poster="/video/video-metaverse.jpg"
            style={{imageRendering: 'pixelated'}}
            autoPlay muted loop playsInline
            className='w-full h-full object-cover opacity-50'
          >
            <source src="/video/video-metaverse.mp4"/>
          </video>
        </div>
        <div className='relative row-start-2 shrink-1'>
            <h3
              style={revealSlideUp(isInView, 1, 0.5)}
              className='text-xl md:text-2xl '
            >
              <span className='text-amz3green-50'>{t('home.metaverse.line1')}</span>{` `}
              <span className='text-amz3green-100'>{t('home.metaverse.line2')}</span>
            </h3>
            <p className='mt-8' style={revealSlideUp(isInView, 1, 1)}>
              <a
                className='button-green'
                href='https://www.spatial.io/s/AMAZONTREE-66aaf9825a4bd3cf6f863364?share=0'
                target="_blank"
                rel="noreferrer"
              >
                {t('home.metaverse.cta')}
              </a>
            </p>
        </div>
      </section>
  )
}