import cx from 'classnames'
import { useRef } from "react";
import { useInView } from "framer-motion";
import Frame from '../../../assets/v2/frame-1.svg'
import Badge from '../../../assets/v2/badge-1.svg'
import { revealZoomOut, revealFade } from "../../../motion";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { ROUTES_PATH } from '../../../routes'

export function SectionTokenInfo () {
  
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInViewBlock1 = useInView(ref1, { once: true, amount: .3 });
  const isInViewBlock2 = useInView(ref2, { once: true, amount: .3 });
  const isInViewBlock3 = useInView(ref3, { once: true, amount: .3 });
  const { t } = useTranslation()

  function openVideo(){
    const videoLightbox = window.GLightbox({
      elements: [
        {
          'href': t('about-token.token-info.block1.video'),
          'type': 'video',
          'source': 'youtube',
          'width': 900,
        } 
      ],
      autoplayVideos: true,
    });
    videoLightbox.open();
  }
  
  return (
    <>
      
      {/* BLOCK 1 */}

      <section
        ref={ref1}
        className='lg:h-[60dvh] bg-amz3green-50 grid grid-cols-1 md:grid-cols-6 lg:grid-cols-9 overflow-hidden'
      >
        <div
          className={cx(
            'px-10 py-20 md:p-20 md:col-span-3 lg:col-span-4 bg-amz3green-100',
            'flex flex-col justify-center items-center lg:items-start'
          )}
          style={revealZoomOut(isInViewBlock1, 3)}
        >
          <div className="p-10" style={{
            backgroundImage: `url(${Frame})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center"
          }}>
            <h2>{t('about-token.token-info.block1.title')}</h2>
            <h3 className='text-6xl lg:text-8xl text-amz3green-300'>
              UCF<br/>AMZ3
            </h3>
          </div>
        </div>
        <div
          className='px-10 py-20 md:p-20 md:col-span-3 lg:col-span-5 [&>p]:mb-4'
          style={revealFade(isInViewBlock1, 3, 1)}
        >
          <p>{t('about-token.token-info.block1.first-paragraph')}</p>
          <p>{t('about-token.token-info.block1.second-paragraph')}</p>
          <button
              onClick={openVideo}
              className='button-green hover:bg-amz3green-200 hover:text-amz3green-50'
            >
              <span className='mr-4'>{'\u25BA'}</span>
              {t('about-token.token-info.block1.video-label')}
            </button>
        </div>
      </section>
      
      {/* BLOCK 2 */}

      <section
        ref={ref2}
        className={cx(
          'lg:h-[60dvh] bg-amz3green-300',
          'grid grid-cols-1 md:grid-cols-6 lg:grid-cols-9'
        )}
      >
        <div
          className='px-10 pt-20 md:p-20 md:col-span-3 lg:col-span-3 text-white'
          style={revealFade(isInViewBlock2, 3, 0.5)}
        >
          <h2>{t('about-token.token-info.block2.title')}</h2>
          <h3 className='text-7xl font-semibold'>
            <span className='text-amz3green-100'>{t('about-token.token-info.block2.subtitle1')}</span>{' '}
            <span className='text-amz3green-50'>{t('about-token.token-info.block2.subtitle2')}</span>
          </h3>
        </div>
        <div
          className='px-10 py-20 md:p-20 md:col-span-3 lg:col-start-5 lg:col-span-5 text-white [&>p]:mb-4'
          style={revealFade(isInViewBlock2, 3, 1)}
        >
          <p>{t('about-token.token-info.block2.first-paragraph')}</p>
          <p>{t('about-token.token-info.block2.second-paragraph')}</p>
        </div>
      </section>
      
      {/* BLOCK 3 */}

      <section
        ref={ref3}
        className={cx(
          'lg:h-[60dvh] bg-amz3green-200 overflow-hidden',
          'grid grid-cols-1 md:grid-cols-6 lg:grid-cols-9'
        )}
      >
        <div
          className='px-10 pt-20 md:p-20 md:col-span-3 lg:col-span-5 text-white'
          style={revealZoomOut(isInViewBlock3, 3, 0.5)}
        >
          <img src={Badge} alt={'Badge'}/>
        </div>

        <div
          className='px-10 py-20 md:p-20 md:order-first md:col-span-3 lg:col-span-4 text-white [&>p]:mb-4'
          style={revealFade(isInViewBlock3, 3, 0.5)}
        >
          <p>{t('about-token.token-info.block3.first-paragraph')}</p>
          <p>{t('about-token.token-info.block3.second-paragraph')}</p>
          <p className='pt-8'>
            <Link
              className='button-green-light'
              to={ROUTES_PATH.CATALOG.path}
            >
              {t('about-token.token-info.block3.cta')}
            </Link>
          </p>
        </div>
        
      </section>
    </>
  )
}