import { useQuery } from 'react-query'
import { api } from '../api'
import { Area } from '../../models/area'

export type Project = {
  projectId: string,
  area: Area
}

export async function getProject(
  projectId: string
): Promise<Project> {
  const { data: project } = await api.get<Project>(
    `projects/total-project/${projectId}`
  )
  return project
}

export function useProject(projectId: string) {
  return useQuery('project-by-id', () => getProject(projectId), {
    staleTime: 1000 * 60 * 60 //1 hra
  })
}
