import { Link } from 'react-router-dom'
import { HambergerMenu } from 'iconsax-react'
import { AddWallet } from '../AddWallet/'
import { LanguageSelector } from '../LanguageSelector'
import { Navbar } from '../Navbar'

import { ROUTES_PATH } from '../../routes'
import { useLanguage, LanguageProps } from '../../store/useLanguage'
import { AM3Logo } from '../AM3Logo'
import { ProfileMenu } from '../ProfileMenu'

type HeaderProps = {
  onOpenMenu: () => void
}

export function Header({ onOpenMenu }: HeaderProps) {
  const { setLanguage } = useLanguage()

  function handleCloseLangueSelector(language: LanguageProps) {
    setLanguage(language)
  }

  return (
    <header
      className="fixed h-24 w-full bg-amz3black-400 flex items-center justify-between px-4.5 z-50"
      id="page-top-header"
    >
      <button className="lg:hidden" onClick={onOpenMenu}>
        <HambergerMenu size="35" className="text-amz3white-50" />
      </button>
      <Link to={ROUTES_PATH.HOME.path}>
        <AM3Logo full className='px-4' />
      </Link>
      <div className="hidden lg:flex">
        <Navbar className="text-base" />
      </div>
      <div className="flex items-center gap-10">
        <AddWallet />
        <div className="hidden lg:flex lg:gap-8">
          <ProfileMenu />
          <LanguageSelector onClose={handleCloseLangueSelector} />
        </div>
      </div>
    </header>
  )
}
