import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Polygon } from '@react-google-maps/api'
import { AREA_OPTIONS, CustomPolygonProps, MOUSE_OVER } from './types'

export const CustomPolygon = memo(
  ({ area, status, onClick, onMouseMove, ref }: CustomPolygonProps) => {
    const [polygonOptions, setPolygonOptions] =
      useState<google.maps.PolygonOptions>()

    useEffect(() => {
      setPolygonOptions(AREA_OPTIONS[status])
    }, [status])

    const paths = useMemo(() => {
      const { pointTopLeft, pointTopRight, pointBottomRight, pointBottomLeft } =
        area

      return [
        { lat: pointTopLeft.latitude, lng: pointTopLeft.longitude },
        { lat: pointTopRight.latitude, lng: pointTopRight.longitude },
        { lat: pointBottomRight.latitude, lng: pointBottomRight.longitude },
        { lat: pointBottomLeft.latitude, lng: pointBottomLeft.longitude }
      ]
    }, [area])

    const handleMouseOver = useCallback(
      (event: MOUSE_OVER) => {
        if (event === MOUSE_OVER.OVER) {
          setPolygonOptions(prevState => ({
            ...prevState,
            fillOpacity: 1
          }))
          return
        }
        if (event === MOUSE_OVER.OUT) {
          setPolygonOptions(AREA_OPTIONS[status])
          return
        }
      },
      [status]
    )

    return (
      <Polygon
        paths={paths}
        onMouseOver={() => handleMouseOver(MOUSE_OVER.OVER)}
        onMouseOut={() => handleMouseOver(MOUSE_OVER.OUT)}
        // onMouseMove={onMouseMove}
        onClick={onClick}
        options={polygonOptions}
        draggable={false}
        ref={ref}
      ></Polygon>
    )
  }
)

CustomPolygon.displayName = 'CustomPolygon'
