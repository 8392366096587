export enum OrderStatus {
  CREATED = 'CREATED',
  DISCARDED = 'DISCARDED',
  VALIDATING_PAYMENT = 'VALIDATING_PAYMENT',
  PAYMENT_REFUSED = 'PAYMENT_REFUSED',
  PAYMENT_APPROVED = 'PAYMENT_APPROVED',
  CREATING_TOKEN = 'CREATING_TOKEN',
  TOKEN_MINTED = 'TOKEN_MINTED',
  TOKEN_MOVED_TO_USER = 'TOKEN_MOVED_TO_USER'
}
