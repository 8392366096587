import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react'
import { Field, Control } from '@radix-ui/react-form'
import { twMerge } from 'tailwind-merge'

type InputProps = {
  inputName: string
  errorMessage?: string
  className?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ inputName, errorMessage, className, ...props }: InputProps, ref) => {
    return (
      <div className="flex flex-col w-full">
        <Field asChild name={inputName}>
          <Control asChild>
            <>
              <input
                className={twMerge(
                  'rounded-2lg pl-7 border border-amz3green-50 h-[4.5rem] bg-transparent text-amz3white-50 text-lg placeholder:text-[rgba(255,255,255,.3)] placeholder:text-lg focus:outline-none',
                  className
                )}
                {...props}
                ref={ref}
              />
              {errorMessage && (
                <span className="text-red-500 font-normal text-base">
                  {errorMessage}
                </span>
              )}
            </>
          </Control>
        </Field>
      </div>
    )
  }
)
