import { ReactNode } from 'react'
import { Root, FormProps as RootProps } from '@radix-ui/react-form'
import { twMerge } from 'tailwind-merge'

type FormProps = {
  children: ReactNode
  className?: string
} & RootProps

export function Form({ children, className, ...props }: FormProps) {
  return (
    <Root
      className={twMerge(
        'flex flex-col gap-3.5 mt-6 w-full overflow-auto',
        className
      )}
      {...props}
    >
      {children}
    </Root>
  )
}
